import { StyledTitle } from './style';

export interface ITitleProps {
  className?: string;
  children?: React.ReactNode;
}

export const Title: React.FC<ITitleProps> = ({ className, children }) => {
  return (
    <StyledTitle className={className} variant='h3'>
      {children}
    </StyledTitle>
  );
};
