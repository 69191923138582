import { SignInMethod } from '../../__generated__/types';
import { useSignIn } from './useSignIn';

export const useGoogleSignIn = () => {
  const { signIn, data, loading, error } = useSignIn();

  const googleSignIn = async (
    idToken: string,
    prefilledCoupon?: string | null,
  ) => {
    const response = await signIn({
      method: SignInMethod.GOOGLE,
      socialToken: idToken,
      prefilledCoupon,
    });
    if (!response.data) {
      throw new Error();
    }
    return response.data.signIn;
  };

  return {
    googleSignIn,
    data,
    loading,
    error,
  };
};
