import amplitude, { AmplitudeClient, type LogReturn } from 'amplitude-js';
import type { IEventTracker } from './interfaces/IEventTracker';

export class AmplitudeEventTracker implements IEventTracker {
  private amplitude: AmplitudeClient = amplitude.getInstance();

  constructor(apiKey: string) {
    if (import.meta.env['NODE_ENV'] === 'development') {
      return;
    }
    this.amplitude.init(apiKey, undefined, {
      includeReferrer: true,
      includeUtm: true,
      includeFbclid: true,
      includeGclid: true,
    });
  }

  public setExperiment(experimentName: string) {
    this.amplitude.setUserProperties({
      experiment: experimentName,
    });
  }

  public trackEvent(eventName: string, data?: Record<string, any>): LogReturn {
    if (import.meta.env['NODE_ENV'] === 'development') {
      return;
    }
    return this.amplitude.logEvent(eventName, data);
  }

  public setUser(id: string, email: string) {
    this.amplitude.setUserId(id.toString());
    return this.amplitude.setUserProperties({ email: email });
  }

  public pageView(pageId: string) {
    this.amplitude.logEvent(`page_${pageId}`);
  }
}
