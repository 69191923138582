import { Icon, type IconifyIcon } from '@iconify/react';
import Box, { type BoxProps } from '@mui/material/Box';
import { forwardRef } from 'react';

export interface IconifyProps extends BoxProps {
  icon: IconifyIcon | string;
}

export const Iconify = forwardRef<SVGElement, IconifyProps>(
  ({ icon, width = 20, sx, ...other }, ref) => (
    <Box
      ref={ref}
      component={Icon as any}
      className='component-iconify'
      icon={icon}
      sx={{ width, height: width, ...sx }}
      {...other}
    />
  ),
);
