import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { head } from 'ramda';

import { ErrorCodes } from '../constants/ErrorCodes';

export const extractFirstError = (
  error: ApolloError,
): GraphQLError | null | undefined => {
  if (!error || !error.graphQLErrors || !error.graphQLErrors.length) {
    return null;
  }

  return head(error.graphQLErrors);
};

export const extractGraphQLErrorCode = (
  graphQLError: GraphQLError,
): ErrorCodes => {
  return graphQLError?.extensions?.['code'] as unknown as ErrorCodes;
};

export const matchesError =
  (errorCode: ErrorCodes) =>
  (error: ApolloError): boolean => {
    const firstError = extractFirstError(error);
    if (!firstError) {
      return false;
    }
    return extractGraphQLErrorCode(firstError) === errorCode;
  };

export const matchesNotFound = matchesError(ErrorCodes.NotFound);
export const matchesBadUserInput = matchesError(ErrorCodes.BadUserInput);
