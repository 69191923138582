import { ApolloError } from '@apollo/client';
import { type SxProps } from '@mui/material';
import React, { useEffect, type CSSProperties } from 'react';
import { useAppContext } from '../..';
import { useErrorTracker } from '../../bugsnag/useErrorTracker';
import { DisplayErrorMessage } from './DisplayErrorMessage';
import { ErrorCodes } from './constants/ErrorCodes';
import { getErrorMessage } from './logic/getErrorMessage';
import {
  extractFirstError,
  extractGraphQLErrorCode,
} from './logic/matchesError';

export const DEFAULT_IGNORED_ERRORS = [
  ErrorCodes.UNAUTHORIZED,
  ErrorCodes.INVALID_CREDENTIALS,
];

const shouldNotifyError = (ignoredErrors: string[]) => (error: Error) => {
  if (error instanceof ApolloError) {
    const firstError = extractFirstError(error);
    if (!firstError) {
      return;
    }
    const code = extractGraphQLErrorCode(firstError);

    if (ignoredErrors.includes(code)) {
      return false;
    }
    return true;
  }
};

export const DisplayError: React.FC<{
  error?: Error | null;
  style?: CSSProperties;
  className?: string;
  sx?: SxProps;
}> = ({ error, style, className, sx }) => {
  const errorTracker = useErrorTracker();
  const { ignoredErrorCodes } = useAppContext();
  if (!error) {
    return null;
  }
  useEffect(() => {
    if (shouldNotifyError(ignoredErrorCodes)(error)) {
      return;
    }
    errorTracker.notify(error);
  }, [error, ignoredErrorCodes, errorTracker]);
  const message = getErrorMessage(error);
  return (
    <DisplayErrorMessage
      sx={sx}
      className={className}
      message={message ?? ''}
      style={style}
    />
  );
};
