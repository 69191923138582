import {
  ForgotPassword,
  ForgotPasswordPage,
  Logout,
  ResetPassword,
  ResetPasswordPage,
  SignInLayout,
  SignInPage,
  SignUpLayout,
  SignUpPage,
  useAppContext,
} from '@invest-ai/animatrix';

export const useAuthRoutes = () => {
  const {
    defaultRouteNames: { auth },
  } = useAppContext();

  return [
    {
      element: <SignUpLayout />,
      children: [{ path: auth.signUp, element: <SignUpPage /> }],
    },
    {
      element: <SignUpLayout />,
      children: [{ path: '/signup', element: <SignUpPage /> }],
    },
    {
      element: <SignInLayout />,
      children: [{ path: auth.signIn, element: <SignInPage /> }],
    },
    {
      element: <SignInLayout />,
      children: [{ path: '/login', element: <SignInPage /> }],
    },
    {
      element: <ForgotPasswordPage />,
      children: [{ element: <ForgotPassword />, path: auth.forgotPassword }],
    },
    {
      element: <ResetPasswordPage />,
      children: [{ element: <ResetPassword />, path: auth.resetPassword }],
    },
    { path: auth.logout, element: <Logout /> },
  ];
};
