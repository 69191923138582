import { Box, Button, Container, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
import { MotionContainer, varBounceIn } from '../../animate';
import { PageNotFoundIllustration } from './illustration_404';
import { Root } from './style';

export const Page404: React.FC = () => {
  return (
    <Root title='InvestAI | Página não encontrada'>
      <Container>
        <MotionContainer initial='initial' open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant='h3' paragraph>
                Página não encontrada!
              </Typography>
            </motion.div>

            <Typography sx={{ color: 'text.secondary' }}>
              A página que você está procurando não foi encontrada.
            </Typography>
            <PageNotFoundIllustration
              sx={{ height: 260, my: { xs: 5, sm: 10 } }}
            />
            <Button
              to='/'
              size='large'
              variant='contained'
              color='primary'
              component={RouterLink}
            >
              Voltar para página inicial
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </Root>
  );
};
