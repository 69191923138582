import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import { Scrollbar } from '../../../components/Scrollbar';
import { useResponsive } from '../../../hooks/useResponsive';
import { NAV } from '../constants/config-layout';
import { NavBarContext } from '../contexts/CollapseDrawerContext';
import { NavSectionVertical, type SidebarConfig } from './NavSection';
import { NavToggleButton } from './NavToggleButton';

type Props = {
  logo: React.ReactNode;
  sidebarConfig: SidebarConfig;
};
export const NavVertical: React.FC<Props> = ({ logo, sidebarConfig }) => {
  const { isOpen, close } = NavBarContext.useContainer();
  const lgUp = useResponsive('up', 'md');

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ mt: 3, ml: 4, mb: 1 }}>{logo}</Box>

      <NavSectionVertical
        data={sidebarConfig}
        config={{
          currentRole: 'admin',
        }}
      />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component='nav'
      sx={{
        flexShrink: { md: 0 },
        width: { md: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={isOpen}
          onClose={close}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
};
