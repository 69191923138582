import { DisplayError, useAppContext } from '@invest-ai/animatrix';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCheckCoupon } from '../../hooks/useCheckCoupon';
import { DisplayCouponCode } from '../DisplayCouponCode';

export const CheckCoupon: React.FC<{
  onCouponActiveChange: (active: boolean) => void;
}> = ({ onCouponActiveChange }) => {
  const [searchParams] = useSearchParams();
  const couponCode = searchParams.get('r');
  const { buName } = useAppContext();

  const {
    checkCoupon,
    isCouponActive,
    loading: loadingCheckCoupon,
    error: errorCheckCoupon,
  } = useCheckCoupon();

  useEffect(() => {
    if (couponCode) {
      checkCoupon({
        variables: {
          couponCode,
          target: buName,
        },
      });
    }
  }, [couponCode, checkCoupon, buName]);

  useEffect(() => {
    onCouponActiveChange(isCouponActive);
  }, [isCouponActive, onCouponActiveChange]);

  if (errorCheckCoupon) {
    return <DisplayError error={errorCheckCoupon} />;
  }
  if (loadingCheckCoupon || !couponCode) {
    return null;
  }
  return (
    <DisplayCouponCode
      couponCode={couponCode}
      isCouponActive={isCouponActive}
    />
  );
};
