import type { IEventTracker } from '../../analytics/interfaces/IEventTracker';
import { AppEvents } from '../../auth/components/SignIn/AppEvents';
import type { IChatWidget } from '../interfaces/IChatWidget';

export class ChatWidget implements IChatWidget {
  private widget: HTMLElement | null | undefined;

  constructor(private readonly tracker?: IEventTracker) {
    this.searchDOMAndSetChatWidget();
    this.tracker = tracker;
  }

  public hide(): void {
    if (!this.widget) {
      return;
    }
    this.widget.style.display = 'none';
  }

  public show(): void {
    if (!this.widget) {
      return;
    }
    this.widget.style.display = 'flex';
  }

  private searchDOMAndSetChatWidget(): void {
    const findChat = setInterval(() => {
      const widget = document.getElementById('wa-widget-send-button');
      const poweredBy = document.querySelector<HTMLElement>(
        '.wa-chat-box-poweredby',
      );
      const openChatButton = document.querySelector<HTMLElement>(
        '.wa-chat-box-send a',
      );

      if (widget && poweredBy && openChatButton) {
        clearInterval(findChat);
        this.widget = widget;
        openChatButton.onclick = () => this.trackChatClick();
        poweredBy.style.display = 'none';
      }
    }, 1000);
  }

  private trackChatClick(): void {
    if (!this.tracker) {
      return;
    }
    this.tracker.trackEvent(AppEvents.ClickWhatsAppChat);
  }
}
