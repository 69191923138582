import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import { bgBlur } from '../../../helpers/bgBlur';
import { useOffSetTop } from '../../../hooks/useOffsetTop';
import { useResponsive } from '../../../hooks/useResponsive';
import icMenuItem from '../assets/ic_menu_item.svg';
import { HEADER, NAV } from '../constants/config-layout';
import { NavBarContext } from '../contexts/CollapseDrawerContext';
import AccountPopover from './AccountPopover';
import SvgColor from './SVGColor';

type Props = {
  onOpenNav?: VoidFunction;
  children?: React.ReactNode;
};

export function NavHeader({ onOpenNav, children }: Props) {
  const theme = useTheme();

  const { isOpen } = NavBarContext.useContainer();

  const isNavMini = !isOpen;

  const lgUp = useResponsive('up', 'md');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && true;

  const renderContent = (
    <>
      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src={icMenuItem} />
        </IconButton>
      )}

      <Stack
        flexGrow={1}
        direction='row'
        alignItems='center'
        justifyContent='flex-end'
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {children}
        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,

          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
