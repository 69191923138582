import { type IMask } from '../interface';

export const phoneMask: IMask[] = [
  {
    mask: '(00) 0000-0000',
    lazy: true,
  },
  {
    mask: '(00) 00000-0000',
    lazy: false,
  },
];
