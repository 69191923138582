import { useCallback, useState } from 'react';

import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { Iconify } from '../../../components/Iconify';
import { AccountBilling } from '../AccountBilling';
import AccountChangePassword from '../AccountChangePassword';
import AccountGeneral from '../AccountGeneral';
import AccountNotifications from '../AccountNotifications';

const TABS = [
  {
    value: 'general',
    label: 'Geral',
    icon: <Iconify icon='solar:user-id-bold' width={24} />,
  },
  {
    value: 'security',
    label: 'Segurança',
    icon: <Iconify icon='ic:round-vpn-key' width={24} />,
  },
];

export default function AccountView() {
  const [currentTab, setCurrentTab] = useState('general');

  const handleChangeTab = useCallback(
    (_: React.SyntheticEvent, newValue: string) => {
      setCurrentTab(newValue);
    },
    [],
  );

  return (
    <Container maxWidth='lg'>
      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {TABS.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>

      {currentTab === 'general' && <AccountGeneral />}

      {currentTab === 'billing' && (
        <AccountBilling plans={[]} cards={[]} invoices={[]} />
      )}

      {currentTab === 'notifications' && <AccountNotifications />}

      {currentTab === 'security' && <AccountChangePassword />}
    </Container>
  );
}
