import { keyframes } from '@emotion/react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { CircularProgress, Typography } from '@mui/material';
import { type Theme, styled } from '@mui/material/styles';
import { hide } from '../keyframes/hide';
import { show } from '../keyframes/show';

const hideElement = keyframes`
  90% { opacity: 0;  }
  100% { height: 0; width: 0; visibility: hidden; }
`;

const stepLoad = (theme: Theme) => keyframes`
  25% {
    opacity: 1;
    margin-top: 20px;
  }
  80% {
    max-height: 100px;
    transform: scale(1.2);
    color: ${theme.palette.text.primary};
    margin-top: 20px;
  }
  100% {
    opacity: 1;
    transform: scale(1.0);
    color: ${theme.palette.primary.main};
    max-height: 100px;
    margin-top: 20px;
  }
`;

export const Background: any = styled('div', {
  shouldForwardProp: (prop) => prop !== 'stepsCount',
})<{ stepsCount: number }>(({ theme, stepsCount }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 99,
  backgroundColor: theme.palette.grey[800],
  animation: `${hideElement} 0.5s forwards`,
  animationDelay: `${stepsCount}s`,
}));

export const Wrapper: any = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  width: 20,
  height: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '& > *': {
    position: 'absolute',
  },
}));

export const Loading: any = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'animationDelay',
})<{ animationDelay: number }>(({ animationDelay }) => ({
  '&&': {
    color: 'inherit',
    animation: `${hide} 0.4s forwards`,
    opacity: 1,
    animationDelay: `${animationDelay + 1}s`,
  },
}));

export const CheckIcon: any = styled(CheckCircleOutlineIcon, {
  shouldForwardProp: (prop) => prop !== 'animationDelay',
})<{ animationDelay: number }>(({ animationDelay }) => ({
  '&&': {
    animation: `${show} 0.4s forwards`,
    opacity: 0,
    animationDelay: `${animationDelay + 1}s`,
  },
}));

export const TextContainer: any = styled('div')(() => ({
  height: '55%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

export const Text: any = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'animationDelay',
})<{ animationDelay: number }>(({ animationDelay, theme }) => ({
  '&&': {
    transform: 'scale(1.2)',
    animation: `${stepLoad(theme)} 1.25s forwards`,
    maxHeight: '0px',
    opacity: 0,
    animationDelay: `${animationDelay}s`,
    display: 'flex',
    alignItems: 'center',
  },
}));
