import React from 'react';
import type { IChatWidget } from '../../interfaces/IChatWidget';

export const ChatWidgetContext = React.createContext<IChatWidget | null>(null);

interface IChatWidgetProviderProps {
  widget: IChatWidget;
  children?: React.ReactNode;
}

export const ChatWidgetProvider: React.FC<IChatWidgetProviderProps> = ({
  widget,
  children,
}) => {
  return (
    <ChatWidgetContext.Provider value={widget}>
      {children}
    </ChatWidgetContext.Provider>
  );
};
