import { SetExperimentData } from '@analytics/components/SetExperimentData';
import { TrackPixelPageView } from '@analytics/components/TrackPixelPageView';
import {
  AmplitudeEventTracker,
  AnimatrixApp,
  AnimatrixRouter,
  ChatWidget,
  Logo,
} from '@invest-ai/animatrix';
import React, { useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import 'react-toastify/dist/ReactToastify.min.css';
import 'simplebar-react/dist/simplebar.min.css';
import { bugsnagClient } from 'src/bugsnagClient';
import { InvestAIBU } from '../../__generated__/types';
import { NeoTracker } from '../../config/NeoTracker';
import { apolloClient } from '../../config/apollo/client';
import { layout } from '../../config/layout';
import { privateRoutes } from '../../config/privateRoutes';
import { product } from '../../config/product';
import { sidebarConfig } from '../config/SidebarConfig';

const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID as string;

export const NeoApp: React.FC = () => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID as string,
    });
  }, []);

  const amplitudeEventTracker = new AmplitudeEventTracker(
    import.meta.env.VITE_AMPLITUDE_API_KEY as string,
  );

  const trackerRef = useRef(new NeoTracker(amplitudeEventTracker));
  const chatWidgetRef = useRef(new ChatWidget(amplitudeEventTracker));

  return (
    <AnimatrixApp
      apolloClient={apolloClient}
      googleClientId={GOOGLE_CLIENT_ID}
      chatWidget={chatWidgetRef.current}
      tracker={trackerRef.current}
      logo={<Logo />}
      product={product}
      layout={layout}
      sidebarConfig={sidebarConfig}
      privateRoutes={privateRoutes}
      buName={InvestAIBU.BOLETADOR}
      errorTracker={bugsnagClient}
      // TODO: Dynamic
      mode='dark'
    >
      <SetExperimentData
        experimentName={import.meta.env.VITE_EXPERIMENT_NAME as string}
      />
      <TrackPixelPageView />
      <AnimatrixRouter />
    </AnimatrixApp>
  );
};

export default NeoApp;
