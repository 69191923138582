import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { FormTextField, Iconify } from '../..';
import { FormProvider } from '../../form/components/FormProvider';
import { useBoolean } from '../../hooks/useBoolean';
import { useUpdatePassword } from '../hooks/useUpdatePassword';

export interface IFormSchema {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}
const PasswordToggle: React.FC<{
  onToggle: () => void;
  value: boolean;
}> = ({ onToggle, value }) => {
  return (
    <InputAdornment position='end'>
      <IconButton onClick={onToggle} edge='end'>
        <Iconify icon={value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
      </IconButton>
    </InputAdornment>
  );
};

export default function AccountChangePassword() {
  const { updatePassword } = useUpdatePassword();

  const passwordToggle = useBoolean();

  const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('A senha antiga é necessária'),
    newPassword: Yup.string()
      .required('Nova senha é necessária')
      .min(5, 'A senha precisa ter no mínimo 6 caracateres')
      .test(
        'no-match',
        'A nova senha precisa ser diferente da senha antiga',
        (value, { parent }) => value !== parent.oldPassword,
      )
      .required(),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'As senhas precisam ser iguais')
      .required(),
  });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm<IFormSchema>({
    resolver: yupResolver(ChangePasswordSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await updatePassword(data.oldPassword, data.newPassword);
      reset();
      if (response === true) {
        toast.success('Senha atualizada com sucesso');
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        const code = error.graphQLErrors[0]?.extensions?.['code'];
        if (code === 'INVALID_CREDENTIALS') {
          toast.error('A senha fornecida está errada');
        }
        return;
      }
      if (error instanceof Error) {
        toast.error(`Houve um erro atualizando a senha: ${error.message}`);
      }
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack component={Card} spacing={3} sx={{ p: 3 }}>
        <FormTextField
          control={control}
          name='oldPassword'
          type={passwordToggle.value ? 'text' : 'password'}
          label='Senha antiga'
          InputProps={{
            endAdornment: (
              <PasswordToggle
                onToggle={passwordToggle.onToggle}
                value={passwordToggle.value}
              />
            ),
          }}
        />

        <FormTextField
          control={control}
          name='newPassword'
          label='Nova senha'
          type={passwordToggle.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <PasswordToggle
                onToggle={passwordToggle.onToggle}
                value={passwordToggle.value}
              />
            ),
          }}
          helperText={
            <Stack component='span' direction='row' alignItems='center'>
              <Iconify icon='eva:info-fill' width={16} sx={{ mr: 0.5 }} /> A
              senha precisa ter no mínimo 6 caracteres
            </Stack>
          }
        />

        <FormTextField
          control={control}
          name='confirmNewPassword'
          type={passwordToggle.value ? 'text' : 'password'}
          label='Confirmar nova senha'
          InputProps={{
            endAdornment: (
              <PasswordToggle
                onToggle={passwordToggle.onToggle}
                value={passwordToggle.value}
              />
            ),
          }}
        />

        <LoadingButton
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ ml: 'auto' }}
        >
          Salvar mudanças
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
