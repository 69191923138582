import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import type {
  SignUpEmailInput,
  SignUpMutation,
  SignUpMutationVariables,
} from '../../__generated__/types';

export const SIGN_UP_MUTATION = gql`
  mutation SignUp($input: SignUpEmailInput!) {
    signUpEmail(input: $input) {
      account {
        id
        email
      }
      tokens {
        accessToken
        refreshToken
      }
    }
  }
`;

export const useSignUp = () => {
  const [_signUp, { data, loading, error }] = useMutation<
    SignUpMutation,
    SignUpMutationVariables
  >(SIGN_UP_MUTATION);

  const signUp = (input: SignUpEmailInput) =>
    _signUp({
      variables: {
        input,
      },
    }).then((response) => {
      if (!response.data) {
        throw new Error();
      }
      return response.data.signUpEmail;
    });

  return {
    signUp,
    data,
    loading,
    error,
  };
};
