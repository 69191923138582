import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider as MuiThemeProvider,
  type ThemeOptions,
  createTheme,
} from '@mui/material/styles';
import merge from 'lodash.merge';
import { useMemo } from 'react';
import type { ICustomPalette } from '../app/context/IAppContext';
import { customShadows } from './custom-shadows';
import { contrast } from './options/contrast';
import { componentsOverrides } from './overrides';
import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';

type Props = {
  children: React.ReactNode;
  mode?: 'light' | 'dark';
  customPalette?: ICustomPalette;
};

export function ThemeProvider({
  children,
  mode = 'light',
  customPalette,
}: Props) {
  const contrastOption = contrast(false, mode);
  const baseOption = useMemo(
    () => ({
      palette: palette(mode, customPalette),
      shadows: shadows(mode),
      customShadows: customShadows(mode),

      typography,
      shape: { borderRadius: 8 },
    }),
    [mode],
  );

  const theme = useMemo(
    () => createTheme(baseOption as ThemeOptions),
    [baseOption],
  );

  theme.components = merge(
    componentsOverrides(theme),
    contrastOption.components,
  );

  const themeWithLocale = useMemo(() => createTheme(theme), [theme]);

  return (
    <MuiThemeProvider theme={themeWithLocale}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
