import { gql, useQuery } from '@apollo/client';
import type {
  GetEmailForRecoverPasswordTokenQuery,
  GetEmailForRecoverPasswordTokenQueryVariables,
} from '../../__generated__/types';

export const GET_EMAIL_FOR_RECOVER_PASSWORD_TOKEN = gql`
  query GetEmailForRecoverPasswordToken($token: String!) {
    getEmailForRecoverPasswordToken(token: $token)
  }
`;

export const useGetEmailForRecoverPasswordToken = (token: string) => {
  const { data, loading, error } = useQuery<
    GetEmailForRecoverPasswordTokenQuery,
    GetEmailForRecoverPasswordTokenQueryVariables
  >(GET_EMAIL_FOR_RECOVER_PASSWORD_TOKEN, {
    variables: {
      token,
    },
    skip: !token,
  });

  return {
    isValid: !!data?.getEmailForRecoverPasswordToken && !loading && !error,
    email: data?.getEmailForRecoverPasswordToken,
    loading,
    error,
  };
};
