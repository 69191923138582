import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  DialogTitle,
  type DialogTitleProps,
  IconButton,
  Icon as IconContainer,
  SvgIcon,
  Typography,
} from '@mui/material';
import React from 'react';
import { ModalContext } from '../../context/Modal.context';

export interface IHeaderProps {
  title?: string;
  hideCloseButton?: boolean;
  hideDivider?: boolean;
  description?: string;
  Icon?: typeof SvgIcon;
  onClose?: () => void;
}

export const Header: React.FC<IHeaderProps & DialogTitleProps> = ({
  title,
  description,
  Icon,
  sx,
  onClose,
  hideCloseButton,
  hideDivider,
  ...props
}) => {
  const { onClose: onCloseModal, variant } = ModalContext.useContainer();
  const onCloseClick = onClose || onCloseModal;

  return (
    <DialogTitle
      sx={{
        display: 'flex',
        p: 3,
        ...(variant !== 'dialog' &&
          !hideDivider && {
            borderBottom: 1,
            borderColor: 'divider',
          }),
        ...sx,
      }}
      {...props}
    >
      {Icon && (
        <Box pt={description ? 1 : 0} pr={2}>
          <IconContainer>
            <Icon fontSize='small' />
          </IconContainer>
        </Box>
      )}
      <Box
        flexGrow={1}
        display='flex'
        flexDirection='column'
        justifyContent='center'
      >
        <Typography variant='h6'>{title}</Typography>
        {description && (
          <Typography variant='caption' color='grey.600'>
            {description}
          </Typography>
        )}
      </Box>
      {!hideCloseButton && onCloseClick && (
        <Box pt={description ? 1 : 0} pl={2}>
          <IconButton
            aria-label='close'
            onClick={onCloseClick}
            data-cy='modal-close-button'
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </Box>
      )}
    </DialogTitle>
  );
};
