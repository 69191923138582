import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { Iconify } from '../../components/Iconify';
import { useBoolean } from '../../hooks/useBoolean';
import { PaymentCardItem } from './PaymentCardItem';
import { type IPaymentCard } from './interfaces/IPaymentCard';

type Props = {
  cards: IPaymentCard[];
};

export default function AccountBillingPayment({ cards }: Props) {
  const newCard = useBoolean();

  return (
    <>
      <Card sx={{ my: 3 }}>
        <CardHeader
          title='Método de pagametno'
          action={
            <Button
              size='small'
              color='primary'
              startIcon={<Iconify icon='mingcute:add-line' />}
              onClick={newCard.onTrue}
            >
              Novo cartão
            </Button>
          }
        />

        <Box
          rowGap={2.5}
          columnGap={2}
          display='grid'
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
          }}
          sx={{ p: 3 }}
        >
          {cards.map((card) => (
            <PaymentCardItem key={card.id} card={card} />
          ))}
        </Box>
      </Card>

      {/* <PaymentNewCardDialog open={newCard.value} onClose={newCard.onFalse} /> */}
    </>
  );
}
