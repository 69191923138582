import { SvgIconStyle } from '@common/components/SvgIconStyle';
import { AppEvents } from '@global/analytics/enum/AppEvents';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FunctionsIcon from '@mui/icons-material/Functions';
import HelpIcon from '@mui/icons-material/Help';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { PATH_DASHBOARD } from './paths';

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: '100%', height: '100%' }}
  />
);

const ICONS = {
  purchases: <AccountBalanceWalletIcon />,
  main: <TrendingUpIcon />,
  banking: <AccountBalanceIcon />,
  simulator: <FunctionsIcon />,
  invoices: <ReceiptIcon />,
  settings: <SettingsIcon />,
  logout: <ExitToAppIcon />,
  mgm: <LoyaltyIcon />,
  faq: <HelpIcon />,
};

export const sidebarConfig = [
  {
    subheader: 'Investimentos',
    items: [
      {
        title: 'Investir',
        path: PATH_DASHBOARD.app.main,
        icon: ICONS.main,
        event: AppEvents.ClickMenuMain,
      },
      {
        title: 'Investimentos',
        path: PATH_DASHBOARD.app.purchases,
        icon: ICONS.purchases,
        event: AppEvents.ClickMenuInvestments,
      },
      {
        title: 'FGC',
        path: PATH_DASHBOARD.app.fgc,
        icon: ICONS.banking,
        event: AppEvents.ClickMenuFGC,
        exactPath: false,
      },
      {
        title: 'Simulador',
        path: PATH_DASHBOARD.app.simulator,
        icon: ICONS.simulator,
        event: AppEvents.ClickMenuSimulator,
      },
      {
        title: 'Cupom e indicações',
        path: PATH_DASHBOARD.app.couponAndMgmRefer,
        icon: ICONS.mgm,
        event: AppEvents.ClickMenuMGM,
      },
      {
        title: 'Faturas',
        path: PATH_DASHBOARD.app.invoices,
        icon: ICONS.invoices,
        event: AppEvents.ClickMenuInvoices,
      },
    ],
  },
  {
    subheader: 'Conta',
    items: [
      {
        title: 'Configurações',
        path: PATH_DASHBOARD.app.settings,
        icon: ICONS.settings,
        event: AppEvents.ClickMenuSettings,
      },
      {
        title: 'Dúvidas',
        path: PATH_DASHBOARD.landingPage.faq,
        externalLink: true,
        icon: ICONS.faq,
        event: AppEvents.ClickMenuFAQ,
      },
      {
        title: 'Sair',
        path: '/logout',
        icon: ICONS.logout,
      },
    ],
  },
];
