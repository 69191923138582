import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { memo, useCallback, useState } from 'react';
import { navVerticalConfig } from '../constants/config';
import {
  type NavConfigProps,
  type NavListProps,
  type NavSectionProps,
} from '../interfaces/types';
import { StyledSubheader } from './styles';

import NavList from './NavList';

const _NavSectionVertical: React.FC<NavSectionProps> = ({
  data,
  config,
  sx,
  ...other
}) => {
  return (
    <Stack sx={sx} {...other}>
      {data.map((group, index) => (
        <Group
          key={group.subheader || index}
          subheader={group.subheader}
          items={group.items}
          config={navVerticalConfig(config)}
        />
      ))}
    </Stack>
  );
};

export const NavSectionVertical = memo(_NavSectionVertical);

type GroupProps = {
  subheader?: string;
  items: NavListProps[];
  config: NavConfigProps;
};

function Group({ subheader, items, config }: GroupProps) {
  const [open, setOpen] = useState(true);

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const renderContent = items.map((list) => (
    <NavList
      key={list.title + list.path}
      data={list}
      depth={1}
      hasChild={!!list.children}
      config={config}
    />
  ));

  return (
    <List disablePadding sx={{ px: 2 }}>
      {subheader ? (
        <>
          <StyledSubheader
            disableGutters
            disableSticky
            onClick={handleToggle}
            config={config}
          >
            {subheader}
          </StyledSubheader>

          <Collapse in={open}>{renderContent}</Collapse>
        </>
      ) : (
        renderContent
      )}
    </List>
  );
}
