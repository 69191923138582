import { IReactPixel } from '@global/analytics/interfaces/IReactPixel';
import {
  FacebookStandardEvents,
  FilteredEventStrategy,
  IEventTracker,
  MappedEventStrategy,
} from '@invest-ai/animatrix';
import reactPixel from 'react-facebook-pixel';

interface IFacebookPixelEventTrackerOptions {
  filterEvents?: string[];
  eventMapper?: Record<string, string>;
}

const DEFAULT_OPTIONS: IFacebookPixelEventTrackerOptions = {
  filterEvents: undefined,
  eventMapper: undefined,
};

export class FacebookPixelEventTracker implements IEventTracker {
  private reactPixel: IReactPixel | undefined;
  private readonly filteredEvent: FilteredEventStrategy;
  private readonly mappedEvent: MappedEventStrategy;

  constructor(
    pixelId: string,
    private readonly options: IFacebookPixelEventTrackerOptions = DEFAULT_OPTIONS,
  ) {
    this.filteredEvent = new FilteredEventStrategy(this.options.filterEvents);
    this.mappedEvent = new MappedEventStrategy(this.options.eventMapper);

    this.reactPixel = reactPixel;
    this.reactPixel.init(pixelId);
  }

  public setUser(id: string, email: string): void {
    // noop
  }

  public setExperiment(experimentName: string): void {
    //  noop
  }

  public setUserProperties(data: Record<string, any>): void {
    // noop
  }

  private isStandardEvent(eventName: FacebookStandardEvents) {
    return !!FacebookStandardEvents[eventName];
  }
  public trackEvent = (eventName: string, data: Record<string, any>): void => {
    if (this.filteredEvent.shouldTrack(eventName)) {
      const mappedEventName = this.mappedEvent.getEventName(eventName);
      if (this.isStandardEvent(mappedEventName as FacebookStandardEvents)) {
        this.reactPixel?.track(mappedEventName, data);
        return;
      }
      this.reactPixel?.trackCustom(mappedEventName, data);
    }
  };

  public pageView(pageId: string) {
    this.reactPixel?.pageView();
  }
}
