import { ApolloError } from '@apollo/client';
import {
  extractFirstError,
  matchesBadUserInput,
  matchesNotFound,
} from './matchesError';

export const getErrorMessage = (error?: ApolloError | Error): string | null => {
  if (!error) {
    return null;
  }

  if (typeof error === 'object' && ApolloError) {
    if (error instanceof ApolloError) {
      const firstError = extractFirstError(error);
      if (matchesNotFound(error)) {
        return `${firstError?.extensions['entity']} ${firstError?.extensions['entityId']} not found.`;
      }
      if (matchesBadUserInput(error)) {
        return `Wrong data sent to the server: ${firstError?.message}`;
      }
      if (firstError) {
        return `${firstError.message}`;
      }
    }
  }

  return `${error.message}`;
};
