import { styled } from '@mui/material/styles';

const PREFIX = 'ForgotPassword';

export const classes = {
  email: `${PREFIX}-email`,
  message: `${PREFIX}-message`,
  link: `${PREFIX}-link`,
};

export const Form = styled('form')(({ theme }) => ({
  [`& .${classes.email}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.message}`]: {
    marginBottom: theme.spacing(1),
  },
  [`& .${classes.link}`]: {
    display: 'block',
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
})) as unknown as 'form';
