import { useState } from 'react';
import { createContainer } from 'unstated-next';

interface ExternalLinkConfig {
  path: string;
  description: string;
  title?: string;
  confirmEventName?: string;
  cancelEventName?: string;
}

export const useExternalLinkDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [externalLinkConfig, setExternalLinkConfig] =
    useState<ExternalLinkConfig>({
      path: '',
      description: '',
    });

  return {
    isOpen,
    setIsOpen,
    externalLinkConfig,
    setExternalLinkConfig,
  };
};

export const ExternalLinkDialogContext = createContainer(useExternalLinkDialog);
