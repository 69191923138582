import { FixedIncomeProductKind } from '@invest-ai/common-fixed-income';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Currency: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  UUID: { input: string; output: string; }
};

export type AL_CAPONEAdvisor = {
  __typename?: 'AL_CAPONEAdvisor';
  advisoryOffice: AdvisoryOffice;
  brokerAccounts: Array<AL_CAPONEBrokerAccount>;
  externalId?: Maybe<Scalars['String']['output']>;
  financialInstitutionId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isAuthenticated: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  totalBrokerAccounts: Scalars['Float']['output'];
};

export type AL_CAPONEBrokerAccount = {
  __typename?: 'AL_CAPONEBrokerAccount';
  accountNumber: Scalars['String']['output'];
  advisor: AL_CAPONEAdvisor;
  advisorId: Scalars['String']['output'];
  customerName?: Maybe<Scalars['String']['output']>;
  financialInstitutionId: Scalars['String']['output'];
  fixedIncomePositions: Array<FixedIncomePosition>;
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  patrimony?: Maybe<Scalars['Float']['output']>;
};

export type AL_CAPONEFixedIncomeOffer = {
  __typename?: 'AL_CAPONEFixedIncomeOffer';
  amortizationPeriod?: Maybe<Scalars['Float']['output']>;
  availableQuantity: Scalars['Int']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Float']['output'];
  equivalentPreYieldRate?: Maybe<Scalars['Float']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  financialInstitutionId: Scalars['String']['output'];
  financials?: Maybe<CalculateDealFinancialsPayload>;
  firstAmortizationPaymentDate?: Maybe<Scalars['String']['output']>;
  firstInterestPaymentDate?: Maybe<Scalars['String']['output']>;
  freeOfIR: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  /** @deprecated Use yieldDescriptionMinimum.indexer or yieldDescriptionMaximum.indexer */
  indexer: Indexers;
  /** @deprecated Use yieldDescriptionMinimum.indexerPercentRate */
  indexerPercentRate: Scalars['Float']['output'];
  /** @deprecated Use yieldDescriptionMaximum.indexerPercentRate */
  indexerPercentRateMaximum: Scalars['Float']['output'];
  interestPeriod?: Maybe<Scalars['Float']['output']>;
  isActive: Scalars['Boolean']['output'];
  issueDate?: Maybe<Scalars['DateTime']['output']>;
  issueType: Scalars['String']['output'];
  issuerId: Scalars['String']['output'];
  marketType: Scalars['Int']['output'];
  maturityDate: Scalars['DateTime']['output'];
  nickname: Scalars['String']['output'];
  productKind: FixedIncomeProductKind;
  rating?: Maybe<Scalars['String']['output']>;
  referenceDate: Scalars['DateTime']['output'];
  referenceUnitPrice: Scalars['Float']['output'];
  roa: Scalars['Float']['output'];
  source: FixedIncomeOfferSource;
  target: FixedIncomeOfferTarget;
  yieldDescriptionMaximum: YieldDescription;
  yieldDescriptionMinimum: YieldDescription;
  yieldDescriptionOptions: Array<YieldDescription>;
  /** @deprecated Use yieldDescriptionMinimum.indexerPercentRate */
  yieldRate: Scalars['Float']['output'];
  /** @deprecated Use yieldDescriptionMaximum.yieldRate */
  yieldRateMaximum: Scalars['Float']['output'];
};


export type AL_CAPONEFixedIncomeOfferfinancialsArgs = {
  input: CalculateOfferFinancialsInput;
};

export type AL_CAPONE_FinancialInstitution = {
  __typename?: 'AL_CAPONE_FinancialInstitution';
  iconImgSrc: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type APIBot = {
  __typename?: 'APIBot';
  availabilityLevel?: Maybe<PurchaseOrderAvailabilityLevel>;
  balance?: Maybe<Scalars['Float']['output']>;
  banks?: Maybe<Array<Bank>>;
  brokerAccount: BrokerAccount;
  brokerAccountId?: Maybe<Scalars['Float']['output']>;
  chargeModelId: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  currentState: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  feeMax: Scalars['Float']['output'];
  feeMin: Scalars['Float']['output'];
  fgcLimitPerBankEnabled?: Maybe<Scalars['Boolean']['output']>;
  fgcLimitPerPersonEnabled?: Maybe<Scalars['Boolean']['output']>;
  funFacts: BotFunFacts;
  hasAcceptedTerms?: Maybe<Scalars['Boolean']['output']>;
  hasPassword: Scalars['Boolean']['output'];
  hasSuggestionAvailable: Scalars['Boolean']['output'];
  id: Scalars['Float']['output'];
  /** @deprecated use investmentStrategy */
  indexer: Indexers;
  insufficientLimitThreshold: Scalars['Float']['output'];
  interestingBanks?: Maybe<Array<Scalars['String']['output']>>;
  interestingProducts?: Maybe<Array<Scalars['String']['output']>>;
  investmentStrategy: Indexers;
  isAbleToPurchaseOnSecondaryMarket: Scalars['Boolean']['output'];
  isAuthenticated: Scalars['Boolean']['output'];
  isFeeAboveLimit: Scalars['Boolean']['output'];
  isLowLimit: Scalars['Boolean']['output'];
  isNowWithinAuthenticationWindowToday: Scalars['Boolean']['output'];
  /** @deprecated This field is not used anymore */
  kind?: Maybe<Scalars['String']['output']>;
  lastAuthenticationDate?: Maybe<Scalars['DateTime']['output']>;
  lowLimitThreshold: Scalars['Float']['output'];
  maturityDateMax: Scalars['DateTime']['output'];
  maturityDateMin: Scalars['DateTime']['output'];
  maturityDaysMax: Scalars['Float']['output'];
  maturityDaysMin: Scalars['Float']['output'];
  maximumPurchasesTotalCost?: Maybe<Scalars['Float']['output']>;
  messages: Array<BotMessage>;
  purchasePriority: Scalars['Float']['output'];
  shouldLimitPurchasesTotalCost: Scalars['Boolean']['output'];
  sortingIndex?: Maybe<Scalars['Float']['output']>;
  status: PurchaseOrderStatus;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export enum AccountKind {
  CHECKING_ACCOUNT = 'CHECKING_ACCOUNT',
  CREDIT_CARD = 'CREDIT_CARD',
  INVESTMENTS = 'INVESTMENTS',
  MONEY = 'MONEY',
  OTHERS = 'OTHERS',
  SAVINGS = 'SAVINGS'
}

export type AccountType = {
  __typename?: 'AccountType';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  roles: Array<Scalars['String']['output']>;
  showCouponCTA: Scalars['Boolean']['output'];
  user?: Maybe<User>;
};

export type AddCreditFailedType = {
  __typename?: 'AddCreditFailedType';
  email: Scalars['String']['output'];
  error: Scalars['String']['output'];
};

export type AddCreditSucceedType = {
  __typename?: 'AddCreditSucceedType';
  currentCredits: Scalars['Float']['output'];
  email: Scalars['String']['output'];
};

export type AddCreditsToUsersResponse = {
  __typename?: 'AddCreditsToUsersResponse';
  failed: Array<AddCreditFailedType>;
  succeed: Array<AddCreditSucceedType>;
};

export type Advisor = {
  __typename?: 'Advisor';
  brokerOffice: BrokerOffice;
  brokerOfficeId?: Maybe<Scalars['Float']['output']>;
  code: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['Float']['output']>;
  typeString?: Maybe<Scalars['String']['output']>;
  whatsapp?: Maybe<Scalars['String']['output']>;
};

export enum AdvisorAuthenticationMethod {
  EMAIL = 'EMAIL',
  PASSWORD = 'PASSWORD'
}

export type AdvisorBrokerAccountsFilter = {
  synchronizationStatus?: InputMaybe<SynchronizationStatus>;
};

export enum AdvisorPurchaseMethod {
  EMAIL = 'EMAIL',
  HTTP = 'HTTP'
}

export type AdvisoryOffice = {
  __typename?: 'AdvisoryOffice';
  financialInstitution: AL_CAPONE_FinancialInstitution;
  financialInstitutionId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  shouldHideCustomerNames: Scalars['Boolean']['output'];
};

export type AssetCalculatorFields = {
  __typename?: 'AssetCalculatorFields';
  grossIncome: Scalars['Float']['output'];
  iofAliquot: Scalars['Float']['output'];
  iofTotalValue: Scalars['Float']['output'];
  irAliquot: Scalars['Float']['output'];
  irTotalValue: Scalars['Float']['output'];
  netIncome: Scalars['Float']['output'];
};

export type AssetPosition = {
  __typename?: 'AssetPosition';
  applicationDate: Scalars['DateTime']['output'];
  bank: Bank;
  bankId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  fee: Scalars['Float']['output'];
  futurePosition: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  indexer: Indexers;
  maturityDate: Scalars['DateTime']['output'];
  productKind: Scalars['String']['output'];
  source: AssetPositionSource;
  totalApplied: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum AssetPositionSource {
  BROKER = 'BROKER',
  PURCHASE = 'PURCHASE',
  USER = 'USER'
}

export type AssistantVersionType = {
  __typename?: 'AssistantVersionType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  prompt: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  version: Scalars['String']['output'];
};

export type AttachCouponToMeInput = {
  couponCode: Scalars['String']['input'];
};

export type AttachCouponToUserInput = {
  couponCode: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type AttachCouponToUserPayload = {
  __typename?: 'AttachCouponToUserPayload';
  coupon: Coupon;
};

export enum AuthStatus {
  Authenticated = 'Authenticated',
  NotAuthenticated = 'NotAuthenticated'
}

export type AuthenticateButton = {
  __typename?: 'AuthenticateButton';
  disabled: Scalars['Boolean']['output'];
  highlighted: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  visible: Scalars['Boolean']['output'];
};

export type AuthenticateCPFInput = {
  brokerAccountId: Scalars['Float']['input'];
  otp: Scalars['String']['input'];
  password: Scalars['String']['input'];
  platform?: InputMaybe<Platform>;
};

export enum AuthenticationMode {
  KEYMAKER = 'KEYMAKER',
  OPEN_TECH = 'OPEN_TECH',
  TRAINMAN = 'TRAINMAN'
}

export type Bank = {
  __typename?: 'Bank';
  cnpj: Scalars['String']['output'];
  iconImgSrc: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type BlockUserEmailPayload = {
  __typename?: 'BlockUserEmailPayload';
  id: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  targetId: Scalars['String']['output'];
  targetType: Scalars['String']['output'];
};

export type BotFunFacts = {
  __typename?: 'BotFunFacts';
  averageFee?: Maybe<Scalars['Float']['output']>;
  averageFeeIPCA?: Maybe<Scalars['Float']['output']>;
  averageMaturityDate?: Maybe<Scalars['DateTime']['output']>;
  averageMaturityDateIPCA?: Maybe<Scalars['DateTime']['output']>;
  lastPurchase?: Maybe<Purchase>;
  numberOfPurchases: Scalars['Float']['output'];
  totalInvestments: Scalars['Float']['output'];
};

export type BotMessage = {
  __typename?: 'BotMessage';
  level: BotMessageLevel;
  message: Scalars['String']['output'];
};

export enum BotMessageLevel {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING'
}

export type BrokerAccount = {
  __typename?: 'BrokerAccount';
  advisor?: Maybe<Advisor>;
  /** @deprecated Use BrokerAccount.warnings.allPurchaseOrdersWithLowLimit */
  allPurchaseOrdersWithLowLimit: Scalars['Boolean']['output'];
  /** @deprecated use purchaseOrders */
  apiBots: Array<APIBot>;
  authStatus: AuthStatus;
  authenticatedAt: Scalars['DateTime']['output'];
  availableLimit: Scalars['Float']['output'];
  balance?: Maybe<Scalars['Float']['output']>;
  balancePrediction: Scalars['Float']['output'];
  /** @deprecated use purchaseOrders */
  bots: Array<APIBot>;
  broker: Scalars['String']['output'];
  brokerAuthenticationMode: BrokerAccountAuthenticationMode;
  cardDisplay: BrokerAccountCardDisplay;
  /** @deprecated Coupon is no longer attached to brokerAccount */
  coupon?: Maybe<Coupon>;
  createdAt: Scalars['DateTime']['output'];
  customerName?: Maybe<Scalars['String']['output']>;
  executedPurchaseOrders: Array<APIBot>;
  fgcLimitPerBankEnabledByDefault?: Maybe<Scalars['Boolean']['output']>;
  fgcLimitPerPersonEnabledByDefault?: Maybe<Scalars['Boolean']['output']>;
  financialInstitution: FinancialInstitution;
  hasAuthenticatedInThePast: Scalars['Boolean']['output'];
  hasAuthenticatedInsideWindow: Scalars['Boolean']['output'];
  id: Scalars['Float']['output'];
  insufficientBalanceThreshold: Scalars['Float']['output'];
  interestingBanks?: Maybe<Array<Scalars['String']['output']>>;
  interestingProducts?: Maybe<Array<Scalars['String']['output']>>;
  isAuthenticated: Scalars['Boolean']['output'];
  isBlockedPassword: Scalars['Boolean']['output'];
  isEnrolledToJFKPromotionOrPromotionRequest: Scalars['Boolean']['output'];
  isInsideAuthenticationTimeWindow: Scalars['Boolean']['output'];
  /** @deprecated Use BrokerAccount.warnings.isLowBalance */
  isLowBalance: Scalars['Boolean']['output'];
  isPendingAccount: Scalars['Boolean']['output'];
  isWrongPIN: Scalars['Boolean']['output'];
  lastUserAuthenticationAt?: Maybe<Scalars['DateTime']['output']>;
  lowBalanceThreshold: Scalars['Float']['output'];
  minimumPurchaseCost?: Maybe<Scalars['Float']['output']>;
  needsAdvisor: Scalars['Boolean']['output'];
  needsAuthentication: Scalars['Boolean']['output'];
  needsPin: Scalars['Boolean']['output'];
  number?: Maybe<Scalars['String']['output']>;
  personType: PersonType;
  pin?: Maybe<Scalars['String']['output']>;
  positionsByBank: Array<PositionsByBank>;
  purchaseOrders: Array<APIBot>;
  purchaseOrdersInsufficientLimitThreshold: Scalars['Float']['output'];
  purchaseOrdersLowLimitThreshold: Scalars['Float']['output'];
  shouldDisplayAuthenticationCTA: Scalars['Boolean']['output'];
  shouldDisplayAuthorizedPurchaseOrdersMessage: Scalars['Boolean']['output'];
  shouldHightlightAuthenticationCTA: Scalars['Boolean']['output'];
  shouldOfferJFKPromotionOrPromotionRequest: Scalars['Boolean']['output'];
  status: Array<BrokerAccountStatus>;
  sumGeneratedCredits: Scalars['Float']['output'];
  user: User;
};

export enum BrokerAccountAuthenticationMode {
  ACCOUNT_NUMBER = 'ACCOUNT_NUMBER',
  CPF = 'CPF'
}

export type BrokerAccountCardDisplay = {
  __typename?: 'BrokerAccountCardDisplay';
  authenticateButton: AuthenticateButton;
  bannerType: BrokerAccountCardDisplayBannerType;
  displayAuthenticationReminder: Scalars['Boolean']['output'];
  displayBalance: Scalars['Boolean']['output'];
  errors: BrokerAccountErrors;
  text: Scalars['String']['output'];
  warnings: BrokerAccountWarnings;
};

export enum BrokerAccountCardDisplayBannerType {
  ASSETS_SOLD_OFF = 'ASSETS_SOLD_OFF',
  AUTHORIZED = 'AUTHORIZED',
  MAINTENANCE = 'MAINTENANCE',
  NONE = 'NONE',
  PENDENCIES = 'PENDENCIES'
}

export type BrokerAccountEdge = {
  __typename?: 'BrokerAccountEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<BrokerAccount>;
};

export type BrokerAccountErrors = {
  __typename?: 'BrokerAccountErrors';
  allPurchaseOrdersPaused: Scalars['Boolean']['output'];
  blockedPassword: Scalars['Boolean']['output'];
  insufficientBalance: Scalars['Boolean']['output'];
  maintenanceMode: Scalars['Boolean']['output'];
  noPINSet: Scalars['Boolean']['output'];
  noPurchaseOrdersCreated: Scalars['Boolean']['output'];
  pendingAccount: Scalars['Boolean']['output'];
  wrongPIN: Scalars['Boolean']['output'];
};

export type BrokerAccountPageInfo = {
  __typename?: 'BrokerAccountPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type BrokerAccountProfile = {
  __typename?: 'BrokerAccountProfile';
  name?: Maybe<Scalars['String']['output']>;
  personType?: Maybe<PersonType>;
};

export enum BrokerAccountStatus {
  ALL_PURCHASE_ORDERS_LOW_LIMIT = 'ALL_PURCHASE_ORDERS_LOW_LIMIT',
  ALL_PURCHASE_ORDERS_PAUSED = 'ALL_PURCHASE_ORDERS_PAUSED',
  BLOCKED_PASSWORD = 'BLOCKED_PASSWORD',
  INVALID_SIGNATURE = 'INVALID_SIGNATURE',
  LOW_BALANCE = 'LOW_BALANCE'
}

export type BrokerAccountWarnings = {
  __typename?: 'BrokerAccountWarnings';
  allPurchaseOrdersWithLowLimit: Scalars['Boolean']['output'];
  hasPurchaseOrderSuggestion: Scalars['Boolean']['output'];
  hasSomePurchaseOrderAboveLimit: Scalars['Boolean']['output'];
  lowBalance: Scalars['Boolean']['output'];
};

export type BrokerAccountsFilter = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type BrokerAccountsResponse = {
  __typename?: 'BrokerAccountsResponse';
  count?: Maybe<Scalars['Float']['output']>;
  edges?: Maybe<Array<BrokerAccountEdge>>;
  pageInfo?: Maybe<BrokerAccountPageInfo>;
};

export type BrokerAccountsSort = {
  lastUserAuthenticationAt?: InputMaybe<SortDirection>;
};

export type BrokerAuthenticationInput = {
  authenticationMode?: InputMaybe<BrokerAccountAuthenticationMode>;
  authorizationToken?: InputMaybe<Scalars['String']['input']>;
  brokerName: BrokerName;
  otp?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Platform>;
  username?: InputMaybe<Scalars['String']['input']>;
  virtualKeyboardSolution?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BrokerAuthenticationPayload = {
  __typename?: 'BrokerAuthenticationPayload';
  accessToken: Scalars['String']['output'];
  accountOK: Scalars['Boolean']['output'];
  otpOK: Scalars['Boolean']['output'];
  passwordOK: Scalars['Boolean']['output'];
  profile?: Maybe<BrokerAccountProfile>;
  read: Scalars['Boolean']['output'];
  refreshToken: Scalars['String']['output'];
  responseTime: Scalars['Float']['output'];
  write: Scalars['Boolean']['output'];
};

export enum BrokerName {
  BTG = 'BTG',
  BTG_DIGITAL = 'BTG_DIGITAL',
  Easynvest = 'Easynvest',
  GENIAL = 'GENIAL',
  NU_INVEST = 'NU_INVEST',
  XP = 'XP',
  XP2 = 'XP2',
  XP3 = 'XP3'
}

export type BrokerOffice = {
  __typename?: 'BrokerOffice';
  financialInstitution: FinancialInstitution;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type BudgetType = {
  __typename?: 'BudgetType';
  amount: Scalars['Float']['output'];
  availableBudget: Scalars['Currency']['output'];
  category: CategoryType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  monthYear: Scalars['String']['output'];
  paidExpensesAmount: Scalars['Currency']['output'];
  paidExpensesPercentage: Scalars['Float']['output'];
  totalExpensesAmount: Scalars['Currency']['output'];
  totalExpensesPercentage: Scalars['Float']['output'];
  unpaidExpensesAmount: Scalars['Currency']['output'];
  unpaidExpensesPercentage: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CalculateDealFinancialsPayload = {
  __typename?: 'CalculateDealFinancialsPayload';
  advisorROAReais: Scalars['Float']['output'];
  currentNetPrice: Scalars['Float']['output'];
  currentPrice: Scalars['Float']['output'];
  customerLossOnOperationDate: Scalars['Float']['output'];
  customerMarkUp?: Maybe<Scalars['Float']['output']>;
  dealLink: Scalars['String']['output'];
  fixedIncomePositionProjectedPrice?: Maybe<Scalars['Float']['output']>;
  offerYearlyYieldRate: Scalars['Float']['output'];
  positionYearlyYieldRate: Scalars['Float']['output'];
  rebuyNetPrice: Scalars['Float']['output'];
  rebuyPrice: Scalars['Float']['output'];
  rebuyUnitPrice: Scalars['Float']['output'];
  remainingBalance: Scalars['Float']['output'];
  stretchingInMonths: Scalars['Float']['output'];
  substituteFixedIncomeOfferPriceApplied: Scalars['Float']['output'];
  substituteFixedIncomeOfferProjectedPrice: Scalars['Float']['output'];
  substituteFixedIncomeOfferQuantity: Scalars['Float']['output'];
};

export type CalculateDealInput = {
  fixedIncomeOfferId: Scalars['UUID']['input'];
  fixedIncomePositionId: Scalars['UUID']['input'];
  offerYieldDescription?: InputMaybe<YieldDescriptionInput>;
};

export type CalculateDealPayload = {
  __typename?: 'CalculateDealPayload';
  dealFinancials?: Maybe<CalculateDealFinancialsPayload>;
};

export type CalculateOfferFinancialsInput = {
  positionId: Scalars['String']['input'];
};

export type CalculatePositionFinancialsInput = {
  offerId: Scalars['String']['input'];
  offerYieldDescription?: InputMaybe<YieldDescriptionInput>;
  quantity: Scalars['Float']['input'];
};

export type CategoriesFilter = {
  kind?: InputMaybe<CategoryKind>;
};

export type CategoryExpenseType = {
  __typename?: 'CategoryExpenseType';
  amount: Scalars['Float']['output'];
  category: CategoryType;
};

export enum CategoryKind {
  EXPENSE = 'EXPENSE',
  INCOME = 'INCOME'
}

export type CategoryType = {
  __typename?: 'CategoryType';
  color: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isIgnoredInGraphs?: Maybe<Scalars['Boolean']['output']>;
  kind?: Maybe<CategoryKind>;
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Charge = {
  __typename?: 'Charge';
  creditsConsumed: Scalars['Float']['output'];
  discount: Scalars['Float']['output'];
  discountByCredits: Scalars['Float']['output'];
  discountByPercentage: Scalars['Float']['output'];
  generatedAt: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  purchase: Purchase;
  purchaseId: Scalars['Float']['output'];
  status: ChargeStatus;
  total: Scalars['Float']['output'];
  user: User;
  userId: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type ChargeInvoice = {
  __typename?: 'ChargeInvoice';
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  charges: Array<Charge>;
  dueDate: Scalars['DateTime']['output'];
  generatedAt: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  link?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  pathname?: Maybe<Scalars['String']['output']>;
  pix?: Maybe<Scalars['String']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  status: ChargeStatus;
  total: Scalars['Float']['output'];
  totalDiscount?: Maybe<Scalars['Float']['output']>;
  transportStatus: InvoiceTransportStatus;
  user: User;
  value: Scalars['Float']['output'];
};

export type ChargeModel = {
  __typename?: 'ChargeModel';
  description: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  params: Scalars['JSON']['output'];
};

export enum ChargeStatus {
  CANCELLED = 'CANCELLED',
  LOST = 'LOST',
  NOT_PAID = 'NOT_PAID',
  PAID = 'PAID'
}

export type Chart = {
  __typename?: 'Chart';
  dataset: Array<Point>;
};

export type ChatMessageType = {
  __typename?: 'ChatMessageType';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  feedback?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  sender: ParticipantType;
  senderId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum ChatParticipantStatus {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE'
}

export type ChatType = {
  __typename?: 'ChatType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  lastMessageTimestamp?: Maybe<Scalars['DateTime']['output']>;
  messages: Array<ChatMessageType>;
  participants: Array<ParticipantType>;
  type: Scalars['String']['output'];
  unreadCount: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ClassificationScore = {
  __typename?: 'ClassificationScore';
  score: ScoreData;
  status: FinancialHealthStatus;
};

export type CompanyType = {
  __typename?: 'CompanyType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ComparisonChartsInput = {
  fixedIncomeOfferId: Scalars['UUID']['input'];
  fixedIncomePositionId: Scalars['UUID']['input'];
  offerPriceApplied: Scalars['Float']['input'];
  offerYieldDescription?: InputMaybe<YieldDescriptionInput>;
  period?: InputMaybe<Period>;
  positionPriceApplied: Scalars['Float']['input'];
};

export type ComparisonChartsPayload = {
  __typename?: 'ComparisonChartsPayload';
  fixedIncomeOfferChart: Chart;
  fixedIncomePositionChart: Chart;
  intersectionPoint?: Maybe<Scalars['DateTime']['output']>;
};

export type ConsultancyObjectType = {
  __typename?: 'ConsultancyObjectType';
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type ConsultantType = {
  __typename?: 'ConsultantType';
  calendlyId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  numberOfRatings: Scalars['Int']['output'];
  pictureUrl: Scalars['String']['output'];
  rating: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  whatsappNumber: Scalars['String']['output'];
};

export type Coupon = {
  __typename?: 'Coupon';
  /** @deprecated MGM no longer generates credits over time */
  chargeCreditsLimit: Scalars['Float']['output'];
  code: Scalars['String']['output'];
  couponUsage: Array<CouponUsage>;
  id: Scalars['ID']['output'];
  kind: CouponKind;
  /** @deprecated Use CouponRules */
  newBrokerAccountPrize: Scalars['Float']['output'];
  owner?: Maybe<User>;
  /** @deprecated Use CouponRules */
  referralUserCreditsPrize: Scalars['Float']['output'];
  /** @deprecated Use CouponRules */
  referredUserChargeDiscountPercentage: Scalars['Float']['output'];
  /** @deprecated Use CouponRules */
  referredUserCreditsPrize: Scalars['Float']['output'];
  source?: Maybe<InvestAIBU>;
  target?: Maybe<InvestAIBU>;
};

export enum CouponKind {
  INTERNAL = 'INTERNAL',
  MGM = 'MGM',
  PROMO = 'PROMO'
}

export type CouponUsage = {
  __typename?: 'CouponUsage';
  coupon: Coupon;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  obtainedCreditsBalance: Scalars['Float']['output'];
  user?: Maybe<PublicUser>;
};

export type CourseType = {
  __typename?: 'CourseType';
  bannerUrl: Scalars['String']['output'];
  comments: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lectures: Array<LectureType>;
  name: Scalars['String']['output'];
  shares: Scalars['Int']['output'];
  thumbnailUrl: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  views: Scalars['Int']['output'];
};

export type CreateAccountInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  document?: InputMaybe<Scalars['String']['input']>;
  documentType?: InputMaybe<DocumentType>;
  institutionId?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<AccountKind>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAdvisorInput = {
  account?: InputMaybe<Scalars['String']['input']>;
  advisoryOfficeId: Scalars['String']['input'];
  financialInstitutionId: Scalars['String']['input'];
  ownerId: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAdvisorPayload = {
  __typename?: 'CreateAdvisorPayload';
  advisor: AL_CAPONEAdvisor;
};

export type CreateAdvisoryOfficeInput = {
  financialInstitutionId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  shouldHideCustomerNames: Scalars['Boolean']['input'];
};

export type CreateAdvisoryOfficeOwnershipInput = {
  advisoryOfficeId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CreateAssetPositionInput = {
  bankId: Scalars['ID']['input'];
  futurePosition: Scalars['Float']['input'];
  institutionId: Scalars['ID']['input'];
  maturityDate: Scalars['DateTime']['input'];
};

export type CreateBotInput = {
  availabilityLevel?: InputMaybe<PurchaseOrderAvailabilityLevel>;
  brokerAccountId?: InputMaybe<Scalars['Float']['input']>;
  brokerName?: InputMaybe<Scalars['String']['input']>;
  brokerOfficeId?: InputMaybe<Scalars['Float']['input']>;
  couponCode?: InputMaybe<Scalars['String']['input']>;
  customerName?: InputMaybe<Scalars['String']['input']>;
  feeMax?: InputMaybe<Scalars['Float']['input']>;
  feeMin: Scalars['Float']['input'];
  fgcLimitPerBankEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  fgcLimitPerPersonEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  indexer?: InputMaybe<Indexers>;
  initialBalance?: InputMaybe<Scalars['Float']['input']>;
  interestingBanks?: InputMaybe<Array<Scalars['String']['input']>>;
  interestingProducts?: Array<ProductKind>;
  investmentStrategy?: InputMaybe<Indexers>;
  maturityDaysMax: Scalars['Float']['input'];
  maturityDaysMin: Scalars['Float']['input'];
  maximumPurchasesTotalCost?: InputMaybe<Scalars['Float']['input']>;
  minimumPurchaseCost?: InputMaybe<Scalars['Float']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  shouldLimitPurchasesTotalCost: Scalars['Boolean']['input'];
  xpAccountNumber?: InputMaybe<Scalars['String']['input']>;
  xpAccountPIN?: InputMaybe<Scalars['String']['input']>;
  xpAccountPassword?: InputMaybe<Scalars['String']['input']>;
  xpCurrentAuthorizationToken?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBrokerAccountInput = {
  account?: InputMaybe<Scalars['String']['input']>;
  authorizationToken?: InputMaybe<Scalars['String']['input']>;
  brokerAccountId?: InputMaybe<Scalars['Float']['input']>;
  brokerOfficeId?: InputMaybe<Scalars['Float']['input']>;
  couponCode?: InputMaybe<Scalars['String']['input']>;
  customerName?: InputMaybe<Scalars['String']['input']>;
  fgcLimitPerBankEnabledByDefault?: InputMaybe<Scalars['Boolean']['input']>;
  fgcLimitPerPersonEnabledByDefault?: InputMaybe<Scalars['Boolean']['input']>;
  initialBalance?: InputMaybe<Scalars['Float']['input']>;
  institutionId: Scalars['String']['input'];
  minimumPurchaseCost?: InputMaybe<Scalars['Float']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  pin?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBudgetInput = {
  amount: Scalars['Float']['input'];
  categoryId: Scalars['UUID']['input'];
  monthYear: Scalars['String']['input'];
};

export type CreateCompanyInput = {
  name: Scalars['String']['input'];
};

export type CreateConsultancyInput = {
  userId: Scalars['String']['input'];
};

export type CreateConsultantInput = {
  calendlyId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pictureUrl: Scalars['String']['input'];
  whatsappNumber: Scalars['String']['input'];
};

export type CreateCouponInput = {
  code: Scalars['String']['input'];
  kind: CouponKind;
  referredUserChargeDiscountPercentage: Scalars['Float']['input'];
  referredUserCreditsPrize: Scalars['Int']['input'];
  source?: InputMaybe<InvestAIBU>;
  target?: InputMaybe<InvestAIBU>;
};

export type CreateCustomPitchInput = {
  customText: Scalars['String']['input'];
  fixedIncomeOfferId: Scalars['UUID']['input'];
  fixedIncomePositionId: Scalars['UUID']['input'];
};

/** Deprecated. */
export type CreateCustomerOrderInput = {
  availabilityLevel?: InputMaybe<PurchaseOrderAvailabilityLevel>;
  brokerAccountId: Scalars['ID']['input'];
  feeMin: Scalars['Float']['input'];
  fgcLimitPerBankEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  fgcLimitPerPersonEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  indexer: Indexers;
  interestingBanks?: InputMaybe<Array<Scalars['String']['input']>>;
  interestingProducts?: InputMaybe<Array<ProductKind>>;
  maturityDaysMax: Scalars['Int']['input'];
  maturityDaysMin: Scalars['Int']['input'];
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateCustomerOrderPayload = {
  __typename?: 'CreateCustomerOrderPayload';
  customerOrder: CustomerOrder;
};

export type CreateFixedIncomePositionInput = {
  applicationDate: Scalars['DateTime']['input'];
  appliedUnitPrice: Scalars['Float']['input'];
  brokerAccountId: Scalars['String']['input'];
  duration?: InputMaybe<Scalars['Float']['input']>;
  freeOfIR?: InputMaybe<Scalars['Boolean']['input']>;
  indexer: Indexers;
  indexerPercentRate?: InputMaybe<Scalars['Float']['input']>;
  investmentCategory: InvestmentCategory;
  issuerId: Scalars['String']['input'];
  maturityDate: Scalars['DateTime']['input'];
  productKind: FixedIncomeProductKind;
  rating?: InputMaybe<Scalars['String']['input']>;
  rebuyUnitPrice: Scalars['Float']['input'];
  referenceDate: Scalars['DateTime']['input'];
  referenceUnitPrice: Scalars['Float']['input'];
  target?: InputMaybe<FixedIncomeOfferTarget>;
  yieldRate?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateGoalDepositInput = {
  amount: Scalars['Currency']['input'];
  date: Scalars['DateTime']['input'];
  goalId: Scalars['String']['input'];
};

export type CreateGoalInput = {
  color: Scalars['String']['input'];
  deadlineDate: Scalars['DateTime']['input'];
  icon: Scalars['String']['input'];
  initialAmount: Scalars['Currency']['input'];
  name: Scalars['String']['input'];
  targetAmount: Scalars['Currency']['input'];
};

export type CreatePluggyConnectionInput = {
  pluggyConnectorId: Scalars['Float']['input'];
};

export type CreatePurchaseOrderInput = {
  amount: Scalars['Float']['input'];
  availabilityLevel?: InputMaybe<PurchaseOrderAvailabilityLevel>;
  brokerAccountId: Scalars['ID']['input'];
  feeMax?: InputMaybe<Scalars['Float']['input']>;
  feeMin: Scalars['Float']['input'];
  fgcLimitPerBankEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  fgcLimitPerPersonEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  interestingBanks?: InputMaybe<Array<Scalars['String']['input']>>;
  interestingProducts?: InputMaybe<Array<ProductKind>>;
  investmentStrategy: Indexers;
  maturityDaysMax: Scalars['Int']['input'];
  maturityDaysMin: Scalars['Int']['input'];
};

export type CreateTransactionInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  amount: Scalars['Float']['input'];
  categoryId: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  type: MovementType;
};

export type CustomFixedIncomeOfferInput = {
  amortizationPeriod?: InputMaybe<Scalars['Float']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  firstAmortizationPaymentDate?: InputMaybe<Scalars['String']['input']>;
  firstInterestPaymentDate?: InputMaybe<Scalars['String']['input']>;
  freeOfIR?: InputMaybe<Scalars['Boolean']['input']>;
  indexer?: InputMaybe<Indexers>;
  indexerPercentRate?: InputMaybe<Scalars['Float']['input']>;
  interestPeriod?: InputMaybe<Scalars['Float']['input']>;
  issuerId?: InputMaybe<Scalars['String']['input']>;
  maturityDate?: InputMaybe<Scalars['DateTime']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  productKind?: InputMaybe<FixedIncomeProductKind>;
  rating?: InputMaybe<Scalars['String']['input']>;
  roa?: InputMaybe<Scalars['Float']['input']>;
  target?: InputMaybe<FixedIncomeOfferTarget>;
  yieldRate?: InputMaybe<Scalars['Float']['input']>;
};

export type CustomPitchEntity = {
  __typename?: 'CustomPitchEntity';
  customText: Scalars['String']['output'];
  fixedIncomeOfferId: Scalars['UUID']['output'];
  fixedIncomePositionId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  userId: Scalars['String']['output'];
};

export type CustomerOrder = {
  __typename?: 'CustomerOrder';
  brokerAccountId: Scalars['ID']['output'];
  feeMin: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  indexer: Indexers;
  maturityDaysMax: Scalars['Int']['output'];
  maturityDaysMin: Scalars['Int']['output'];
  userId: Scalars['ID']['output'];
};

export type DateRangeInput = {
  max?: InputMaybe<Scalars['DateTime']['input']>;
  min?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Deal = {
  __typename?: 'Deal';
  createdAt: Scalars['DateTime']['output'];
  fixedIncomePosition: FixedIncomePosition;
  foresightYieldRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
  rebuyIndexerPercentRate: Scalars['Float']['output'];
  rebuyYieldRate: Scalars['Float']['output'];
  status: DealStatus;
  substituteFixedIncomeOffer?: Maybe<AL_CAPONEFixedIncomeOffer>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export enum DealStatus {
  DISCARDED = 'DISCARDED',
  OPEN = 'OPEN',
  SUCCESS = 'SUCCESS'
}

export type DeviceToken = {
  __typename?: 'DeviceToken';
  id: Scalars['Float']['output'];
  token: Scalars['String']['output'];
};

export enum DocumentType {
  CNPJ = 'CNPJ',
  CPF = 'CPF',
  DNI = 'DNI'
}

export type EmployeeInvitationType = {
  __typename?: 'EmployeeInvitationType';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  emailSentAt?: Maybe<Scalars['DateTime']['output']>;
  hasSentEmail: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isValid: Scalars['Boolean']['output'];
  link: Scalars['String']['output'];
};

export type EmployeeType = {
  __typename?: 'EmployeeType';
  company: CompanyType;
  consultant: ConsultantType;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ExpensesByCategoryFilter = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  includePending?: InputMaybe<Scalars['Boolean']['input']>;
  periodEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  periodStartDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FinancialHealth = {
  __typename?: 'FinancialHealth';
  categories: Array<NamedScore>;
  classification: ClassificationScore;
};

export enum FinancialHealthStatus {
  HEALTHY = 'HEALTHY',
  SURVIVOR = 'SURVIVOR',
  UNKNOWN = 'UNKNOWN',
  VULNERABLE = 'VULNERABLE'
}

export type FinancialInstitution = {
  __typename?: 'FinancialInstitution';
  advisorAuthenticationMethod?: Maybe<AdvisorAuthenticationMethod>;
  advisorIsAllowedToPurchase: Scalars['Boolean']['output'];
  advisorPurchaseMethod?: Maybe<AdvisorPurchaseMethod>;
  androidAuthenticationMode: MobileAuthenticationMode;
  authenticationTimeLowerBoundary: Scalars['String']['output'];
  authenticationTimeUpperBoundary: Scalars['String']['output'];
  customerIsAllowedToPurchase: Scalars['Boolean']['output'];
  customerNeedsPinToPurchase: Scalars['Boolean']['output'];
  customerNeedsToAcceptTerms: Scalars['Boolean']['output'];
  iconImgSrc: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  insufficientLimitThreshold: Scalars['Float']['output'];
  iosAuthenticationMode: MobileAuthenticationMode;
  isAuthenticationEnabled: Scalars['Boolean']['output'];
  isBeta: Scalars['Boolean']['output'];
  isInsideAuthenticationWindow: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  isReady: Scalars['Boolean']['output'];
  lowLimitThreshold: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Float']['output'];
  purchaseOrderBoundaries: Array<PurchaseOrderBoundaryType>;
  suggestedAuthenticationTimeMaxLimit: Scalars['String']['output'];
  webAuthenticationMode: AuthenticationMode;
};


export type FinancialInstitutionpurchaseOrderBoundariesArgs = {
  investmentStrategy?: InputMaybe<Indexers>;
  maturityDaysRange?: InputMaybe<Array<Scalars['Int']['input']>>;
  products?: InputMaybe<Array<ProductKind>>;
};

export type FindAllTransactionsFilter = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  includeIgnored?: InputMaybe<Scalars['Boolean']['input']>;
  insertionType?: InputMaybe<TransactionInsertionType>;
  movementType?: InputMaybe<MovementType>;
  periodEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  periodStartDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum FixedIncomeOfferSource {
  CREATED_BY_ADVISOR = 'CREATED_BY_ADVISOR',
  XP_PLATFORM = 'XP_PLATFORM',
  XP_SHEET_OFFERS = 'XP_SHEET_OFFERS'
}

export enum FixedIncomeOfferTarget {
  GENERAL = 'GENERAL',
  QUALIFIED = 'QUALIFIED',
  UNKNOWN = 'UNKNOWN'
}

export type FixedIncomePosition = {
  __typename?: 'FixedIncomePosition';
  applicationDate: Scalars['DateTime']['output'];
  appliedTotalPrice: Scalars['Float']['output'];
  appliedUnitPrice: Scalars['Float']['output'];
  averageAgio?: Maybe<Scalars['Float']['output']>;
  brokerAccount: AL_CAPONEBrokerAccount;
  createdBy?: Maybe<Scalars['String']['output']>;
  creationMode: FixedIncomePositionCreationMode;
  currentDeal?: Maybe<Deal>;
  currentPrice: Scalars['Float']['output'];
  duration?: Maybe<Scalars['Float']['output']>;
  financialInstitutionId: Scalars['String']['output'];
  financials?: Maybe<CalculateDealFinancialsPayload>;
  freeOfIR?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  issuerId: Scalars['String']['output'];
  maturityDate: Scalars['DateTime']['output'];
  nickname: Scalars['String']['output'];
  /** @deprecated use appliedTotalPrice */
  priceApplied: Scalars['Float']['output'];
  productKind: FixedIncomeProductKind;
  quantity: Scalars['Float']['output'];
  rebuyTotalPrice?: Maybe<Scalars['Float']['output']>;
  rebuyUnitPrice?: Maybe<Scalars['Float']['output']>;
  rebuyYieldDescription?: Maybe<YieldDescription>;
  referenceDate: Scalars['DateTime']['output'];
  referenceTotalPrice: Scalars['Float']['output'];
  referenceUnitPrice: Scalars['Float']['output'];
  ticker?: Maybe<TickerObjectType>;
  yieldDescription: YieldDescription;
};


export type FixedIncomePositionfinancialsArgs = {
  financialParams: CalculatePositionFinancialsInput;
};

export type FixedIncomePositionConnection = {
  __typename?: 'FixedIncomePositionConnection';
  edges: Array<FixedIncomePositionEdge>;
  pageInfo: PageInfo;
};

export enum FixedIncomePositionCreationMode {
  MANUAL = 'MANUAL',
  XLSX_IMPORT = 'XLSX_IMPORT'
}

export type FixedIncomePositionEdge = {
  __typename?: 'FixedIncomePositionEdge';
  /** An opaque cursor that can be used to retrieve further pages of edges before or after this one. */
  cursor: Scalars['String']['output'];
  /** The node object (belonging to type FixedIncomePosition) attached to the edge. */
  node: FixedIncomePosition;
};

export type FixedIncomePositionFilter = {
  advisorIds?: InputMaybe<Array<Scalars['String']['input']>>;
  applicationDateInterval?: InputMaybe<FloatIntervalInput>;
  averageAgioMinimum?: InputMaybe<Scalars['Float']['input']>;
  brokerAccountIds?: InputMaybe<Array<Scalars['String']['input']>>;
  creationModes?: InputMaybe<Array<FixedIncomePositionCreationMode>>;
  currentPriceInterval?: InputMaybe<FloatIntervalInput>;
  indexers?: InputMaybe<Array<Indexers>>;
  maturityDateInterval?: InputMaybe<FloatIntervalInput>;
  productKinds?: InputMaybe<Array<FixedIncomeProductKind>>;
};

export { FixedIncomeProductKind };

export type FloatIntervalInput = {
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
};

export type FloatRange = {
  __typename?: 'FloatRange';
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
};

export type GenerateAsaasInvoiceInput = {
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  invoiceId: Scalars['ID']['input'];
};

export type GenerateInvoicePayload = {
  __typename?: 'GenerateInvoicePayload';
  chargeInvoice: ChargeInvoice;
};

export type GetAvailablePurchaseOrdersFilters = {
  brokerOfficeId?: InputMaybe<Scalars['ID']['input']>;
  financialInstitutionId: Scalars['ID']['input'];
  indexer?: InputMaybe<Indexers>;
  interestingBanks?: InputMaybe<Array<Scalars['String']['input']>>;
  interestingProducts?: InputMaybe<Array<ProductKind>>;
  maturityDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GetBrokerAccountKeyboardInput = {
  authorizationToken: Scalars['String']['input'];
  brokerName: BrokerName;
  platform?: InputMaybe<Platform>;
};

export type GetBrokerAccountKeyboardPayload = {
  __typename?: 'GetBrokerAccountKeyboardPayload';
  authorizationToken: Scalars['String']['output'];
  virtualKeyboard: VirtualKeyboardType;
};

export type GetChargesInput = {
  generatedAt?: InputMaybe<RangeInput>;
  status?: InputMaybe<ChargeStatus>;
  userId?: InputMaybe<Scalars['Float']['input']>;
};

export type GetInvoicesInput = {
  dueDate?: InputMaybe<DateRangeInput>;
  generatedAt?: InputMaybe<DateRangeInput>;
  status?: InputMaybe<ChargeStatus>;
  transportStatus?: InputMaybe<InvoiceTransportStatus>;
  userId?: InputMaybe<Scalars['Float']['input']>;
};

export type GetPurchasesFilter = {
  date?: InputMaybe<RangeInput>;
  status?: InputMaybe<Array<PurchaseStatus>>;
  userId?: InputMaybe<Scalars['Float']['input']>;
};

export type GetPurchasesPayload = {
  __typename?: 'GetPurchasesPayload';
  purchases: Array<Purchase>;
};

export type GetUploadSignedUrlPayload = {
  __typename?: 'GetUploadSignedUrlPayload';
  filename: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type GoalDepositType = {
  __typename?: 'GoalDepositType';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  goal: GoalType;
  id: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GoalType = {
  __typename?: 'GoalType';
  color: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deadlineDate: Scalars['DateTime']['output'];
  deposits: Array<GoalDepositType>;
  icon: Scalars['String']['output'];
  id: Scalars['String']['output'];
  initialAmount: Scalars['Currency']['output'];
  isFinished: Scalars['Boolean']['output'];
  isPaused: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  progressAmount: Scalars['Currency']['output'];
  progressPercentage: Scalars['Float']['output'];
  targetAmount: Scalars['Currency']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId: Scalars['String']['output'];
};

export type INVEST_PASS_APIAccount = {
  __typename?: 'INVEST_PASS_APIAccount';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['String']['output']>;
  documentType: DocumentType;
  hasPluggyConnection: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  institution: INVEST_PASS_API_Pluggy_Institution;
  kind: AccountKind;
  lastPluggyUpdateDate?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  pluggyItem?: Maybe<INVEST_PASS_API_Pluggy_Item>;
  /** @deprecated use pluggyItem */
  pluggyItemId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type INVEST_PASS_API_Chart = {
  __typename?: 'INVEST_PASS_API_Chart';
  points: Array<INVEST_PASS_API_Point>;
};

export type INVEST_PASS_API_Pluggy_Credentials = {
  __typename?: 'INVEST_PASS_API_Pluggy_Credentials';
  connectToken: Scalars['String']['output'];
};

export type INVEST_PASS_API_Pluggy_Institution = {
  __typename?: 'INVEST_PASS_API_Pluggy_Institution';
  createdAt: Scalars['DateTime']['output'];
  iconImageSrc: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type INVEST_PASS_API_Pluggy_Item = {
  __typename?: 'INVEST_PASS_API_Pluggy_Item';
  createdAt: Scalars['DateTime']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  institution: INVEST_PASS_API_Pluggy_Institution;
  /** @deprecated use externalId */
  itemId?: Maybe<Scalars['String']['output']>;
  lastUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  pendingConnection: Scalars['Boolean']['output'];
  status: ItemStatusType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type INVEST_PASS_API_Point = {
  __typename?: 'INVEST_PASS_API_Point';
  x: Scalars['String']['output'];
  y: Scalars['Float']['output'];
};

export type INVEST_PASS_API_Summary = {
  __typename?: 'INVEST_PASS_API_Summary';
  expense: Scalars['Float']['output'];
  income: Scalars['Float']['output'];
};

export type ImportPayload = {
  __typename?: 'ImportPayload';
  advisorsSynchronization: SynchronizationMetrics;
  brokerAccountsSynchronization: SynchronizationMetrics;
  fixedIncomePositionsSynchronization: SynchronizationMetrics;
  referenceDate: Scalars['DateTime']['output'];
  sheetParsing: ParsingMetrics;
};

export enum Indexers {
  CDI = 'CDI',
  CDI_PLUS = 'CDI_PLUS',
  IGPM = 'IGPM',
  IGPM_PLUS = 'IGPM_PLUS',
  IPCA = 'IPCA',
  PREFIXADO = 'PREFIXADO',
  SELIC = 'SELIC',
  USD = 'USD'
}

export enum InvestAIBU {
  BOLETADOR = 'BOLETADOR',
  CARTEIRA = 'CARTEIRA',
  PASS = 'PASS'
}

export enum InvestmentCategory {
  CHECKING_ACCOUNT = 'CHECKING_ACCOUNT',
  COE = 'COE',
  FIXED_INCOME = 'FIXED_INCOME',
  REAL_ESTATE_FUND = 'REAL_ESTATE_FUND',
  STOCKS = 'STOCKS',
  TRUST_FUND = 'TRUST_FUND',
  YIELD_TREASURY = 'YIELD_TREASURY'
}

export enum InvoiceTransportStatus {
  NOT_SENT = 'NOT_SENT',
  SENT = 'SENT'
}

export enum ItemStatusType {
  LOGIN_ERROR = 'LOGIN_ERROR',
  OUTDATED = 'OUTDATED',
  PENDING = 'PENDING',
  UPDATED = 'UPDATED',
  UPDATING = 'UPDATING',
  WAITING_USER_INPUT = 'WAITING_USER_INPUT'
}

export type LectureType = {
  __typename?: 'LectureType';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  video: VideoType;
};

export type LegacyGenerateInvoicePayload = {
  __typename?: 'LegacyGenerateInvoicePayload';
  sentInvoice: ChargeInvoice;
};

export type MarkChargeAsPaidPayload = {
  __typename?: 'MarkChargeAsPaidPayload';
  charges: Array<Charge>;
};

export enum MobileAuthenticationMode {
  NATIVE = 'NATIVE',
  REMOTE = 'REMOTE'
}

export enum MovementType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT'
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitation: EmployeeInvitationType;
  addCreditsToUsers: AddCreditsToUsersResponse;
  attachCouponToMe: AttachCouponToUserPayload;
  attachCouponToUser: AttachCouponToUserPayload;
  authenticateBrokerAccount: BrokerAuthenticationPayload;
  authenticateXPBrokerAccountWithCPF: BrokerAuthenticationPayload;
  blockUserEmail: BlockUserEmailPayload;
  cancelInvoice: ChargeInvoice;
  cancelPurchase: Purchase;
  changePIN: BrokerAccount;
  createAccount: INVEST_PASS_APIAccount;
  createAdvisor: CreateAdvisorPayload;
  createAdvisoryOffice: AdvisoryOffice;
  createAdvisoryOfficeOwnership: UserAdvisoryOfficeOwnership;
  createAssetPosition: AssetPosition;
  createAssistantVersion: AssistantVersionType;
  /** @deprecated use createPurchaseOrder */
  createBot: APIBot;
  createBrokerAccount: BrokerAccount;
  createBudget: BudgetType;
  createCategory: CategoryType;
  createChat: ChatType;
  createCompany: CompanyType;
  createConnectToken: INVEST_PASS_API_Pluggy_Credentials;
  createConsultancy: ConsultancyObjectType;
  createConsultant: ConsultantType;
  createCoupon: Coupon;
  createCustomPitch: CustomPitchEntity;
  /** @deprecated Use createOnePurchaseOrder instead. */
  createCustomerOrder: CreateCustomerOrderPayload;
  createFixedIncomeOffer: AL_CAPONEFixedIncomeOffer;
  createFixedIncomePosition: FixedIncomePosition;
  createGoal: GoalType;
  createGoalDeposit: GoalDepositType;
  createInvitationsForCompanyId: Array<EmployeeInvitationType>;
  createOnePurchaseOrder: APIBot;
  createPluggyConnection: INVEST_PASS_API_Pluggy_Item;
  /** @deprecated use createOnePurchaseOrder */
  createPurchaseOrder: APIBot;
  createTransaction: TransactionObjectType;
  createUserConsultant?: Maybe<ConsultantType>;
  decreasePriority: APIBot;
  deleteAccount: INVEST_PASS_APIAccount;
  deleteBudget: BudgetType;
  deleteCategory: CategoryType;
  deleteFixedIncomeOffer: Scalars['Boolean']['output'];
  deleteFixedIncomePosition: Scalars['Boolean']['output'];
  deleteGoal: GoalType;
  deleteGoalDeposit: GoalDepositType;
  deleteTransaction: TransactionObjectType;
  deleteTransactions: Array<TransactionObjectType>;
  deleteUserAccount: User;
  /** @deprecated use disablePurchaseOrder */
  disableBot: APIBot;
  disablePurchaseOrder: APIBot;
  editInvoiceValue: ChargeInvoice;
  emitNFForInvoice: NFSe;
  /** @deprecated use enablePurchaseOrder */
  enableBot: APIBot;
  enablePurchaseOrder: APIBot;
  enrichFixedIncomeOffers: Scalars['Boolean']['output'];
  finishGoal: GoalType;
  fixPendingAccount: BrokerAccount;
  generateAsaasInvoice: ChargeInvoice;
  generateBrokerAccountKeyboard: GetBrokerAccountKeyboardPayload;
  /** @deprecated use generateWeeklyInvoice */
  generateInvoice: LegacyGenerateInvoicePayload;
  generateInvoiceDocument: ChargeInvoice;
  generateMonthlyInvoice: GenerateInvoicePayload;
  generateWeeklyInvoice: GenerateInvoicePayload;
  importEmployeeCSV: Array<EmployeeType>;
  importPositionsForUserId: ImportPayload;
  importTickerDataFromGCPUrl: Scalars['Boolean']['output'];
  increasePriority: APIBot;
  markAllAsSeen: Scalars['Int']['output'];
  markChargeAsCancelled: MarkChargeAsPaidPayload;
  markInvoiceAsPaid: ChargeInvoice;
  markTaskAsCompleted: OnboardingTaskCompletionType;
  notifyAuthenticationError: Scalars['Boolean']['output'];
  notifyAuthenticationStarted: Scalars['Boolean']['output'];
  pauseGoal: GoalType;
  pauseSystem: QuerySystemPayload;
  registerFirebaseToken: DeviceToken;
  reloadSystem: QuerySystemPayload;
  removeAssetPositionById: AssetPosition;
  removeBrokerAccountById: BrokerAccount;
  removeQuizAnswers: Array<QuizAnswerType>;
  resetPassword: Scalars['Boolean']['output'];
  resumeSystem: QuerySystemPayload;
  sendEmailInvitationsForCompanyId: Array<EmployeeInvitationType>;
  sendInvoice: ChargeInvoice;
  sendMessage: ChatMessageType;
  sendResetPasswordEmail: Scalars['Boolean']['output'];
  setAccountNumberToBrokerAccount: BrokerAccount;
  setBrokerAccountAuthentication: BrokerAccount;
  setBrokerAccountAuthorizationToken: BrokerAccount;
  setCustomerPriorities: Array<APIBot>;
  setMobileAuthenticationMode: FinancialInstitution;
  setUserRoles: User;
  setWebAuthenticationMode: FinancialInstitution;
  settlePendingPurchases: Scalars['Int']['output'];
  settlePurchase: Purchase;
  signInEmail: SignInEmailPayload;
  signUpEmail: SignUpEmailPayload;
  /** @deprecated use softDeletePurchaseOrder */
  softDeleteAPIBot: APIBot;
  softDeletePurchaseOrder: APIBot;
  startSystem: QuerySystemPayload;
  stopSystem: QuerySystemPayload;
  submitQuizAnswers: Array<QuizAnswerType>;
  testPurchase: TestPurchaseResult;
  throwUnknown: Scalars['Boolean']['output'];
  thumbsDownMessage: ChatMessageType;
  thumbsUpMessage: ChatMessageType;
  transformPurchaseOrderIntoWarrantyPurchaseOrder: APIBot;
  unauthenticate: BrokerAccount;
  unfinishGoal: GoalType;
  unpauseGoal: GoalType;
  unregisterFirebaseToken: DeviceToken;
  updateAccount: INVEST_PASS_APIAccount;
  updateAssetPositionById: AssetPosition;
  updateBrokerAccount: BrokerAccount;
  updateBrokerAccountBalance: BrokerAccount;
  updateBrokerAccountOTP: BrokerAccount;
  updateBudget: BudgetType;
  updateCategory: CategoryType;
  updateDeal: Deal;
  /** @deprecated use updatePurchaseOrder */
  updateFilters: APIBot;
  updateFixedIncomeOffer: AL_CAPONEFixedIncomeOffer;
  updateFixedIncomePosition: FixedIncomePosition;
  updateManyUsersPreferences: UpdatedUsersPreferencesPayload;
  updatePassword: Scalars['Boolean']['output'];
  updatePurchaseOrder: APIBot;
  updatePurchaseOrderBoundary: PurchaseOrderBoundaryType;
  updateTransaction: TransactionObjectType;
  updateUserChargeModel: UpdateUserChargeModelPayload;
  updateUserCpf: User;
  updateUserPreferences: User;
};


export type MutationacceptInvitationArgs = {
  invitationId: Scalars['UUID']['input'];
};


export type MutationaddCreditsToUsersArgs = {
  amount: Scalars['Float']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  emails: Array<Scalars['String']['input']>;
};


export type MutationattachCouponToMeArgs = {
  input: AttachCouponToMeInput;
  target?: InputMaybe<InvestAIBU>;
};


export type MutationattachCouponToUserArgs = {
  input: AttachCouponToUserInput;
  target?: InputMaybe<InvestAIBU>;
};


export type MutationauthenticateBrokerAccountArgs = {
  input: BrokerAuthenticationInput;
};


export type MutationauthenticateXPBrokerAccountWithCPFArgs = {
  input: AuthenticateCPFInput;
};


export type MutationblockUserEmailArgs = {
  email: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};


export type MutationcancelInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
};


export type MutationcancelPurchaseArgs = {
  purchaseId: Scalars['ID']['input'];
};


export type MutationchangePINArgs = {
  brokerAccountId: Scalars['ID']['input'];
  pin: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationcreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationcreateAdvisorArgs = {
  input: CreateAdvisorInput;
};


export type MutationcreateAdvisoryOfficeArgs = {
  input: CreateAdvisoryOfficeInput;
};


export type MutationcreateAdvisoryOfficeOwnershipArgs = {
  input: CreateAdvisoryOfficeOwnershipInput;
};


export type MutationcreateAssetPositionArgs = {
  input: CreateAssetPositionInput;
};


export type MutationcreateAssistantVersionArgs = {
  assistantId: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
  version: Scalars['String']['input'];
};


export type MutationcreateBotArgs = {
  input: CreateBotInput;
};


export type MutationcreateBrokerAccountArgs = {
  input: CreateBrokerAccountInput;
};


export type MutationcreateBudgetArgs = {
  input: CreateBudgetInput;
};


export type MutationcreateChatArgs = {
  participantId: Scalars['String']['input'];
};


export type MutationcreateCompanyArgs = {
  createCompanyInput: CreateCompanyInput;
};


export type MutationcreateConnectTokenArgs = {
  pluggyItemId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationcreateConsultancyArgs = {
  input: CreateConsultancyInput;
};


export type MutationcreateConsultantArgs = {
  createConsultantInput: CreateConsultantInput;
};


export type MutationcreateCouponArgs = {
  input: CreateCouponInput;
};


export type MutationcreateCustomPitchArgs = {
  input: CreateCustomPitchInput;
};


export type MutationcreateCustomerOrderArgs = {
  input: CreateCustomerOrderInput;
};


export type MutationcreateFixedIncomeOfferArgs = {
  input: CustomFixedIncomeOfferInput;
};


export type MutationcreateFixedIncomePositionArgs = {
  input: CreateFixedIncomePositionInput;
};


export type MutationcreateGoalArgs = {
  input: CreateGoalInput;
};


export type MutationcreateGoalDepositArgs = {
  input: CreateGoalDepositInput;
};


export type MutationcreateInvitationsForCompanyIdArgs = {
  companyId: Scalars['String']['input'];
};


export type MutationcreateOnePurchaseOrderArgs = {
  input: CreatePurchaseOrderInput;
};


export type MutationcreatePluggyConnectionArgs = {
  input: CreatePluggyConnectionInput;
};


export type MutationcreatePurchaseOrderArgs = {
  input: CreateBotInput;
};


export type MutationcreateTransactionArgs = {
  createTransactionInput: CreateTransactionInput;
};


export type MutationdecreasePriorityArgs = {
  botId: Scalars['ID']['input'];
};


export type MutationdeleteAccountArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationdeleteBudgetArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationdeleteCategoryArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationdeleteFixedIncomeOfferArgs = {
  id: Scalars['String']['input'];
};


export type MutationdeleteFixedIncomePositionArgs = {
  id: Scalars['String']['input'];
};


export type MutationdeleteGoalArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationdeleteGoalDepositArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationdeleteTransactionArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationdeleteTransactionsArgs = {
  ids: Array<Scalars['UUID']['input']>;
};


export type MutationdisableBotArgs = {
  id: Scalars['ID']['input'];
};


export type MutationdisablePurchaseOrderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationeditInvoiceValueArgs = {
  input: UpdateInvoiceInput;
};


export type MutationemitNFForInvoiceArgs = {
  emitForUserId?: InputMaybe<Scalars['Float']['input']>;
  invoiceId: Scalars['Float']['input'];
};


export type MutationenableBotArgs = {
  id: Scalars['ID']['input'];
};


export type MutationenablePurchaseOrderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationfinishGoalArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationfixPendingAccountArgs = {
  brokerAccountId: Scalars['Float']['input'];
};


export type MutationgenerateAsaasInvoiceArgs = {
  input: GenerateAsaasInvoiceInput;
};


export type MutationgenerateBrokerAccountKeyboardArgs = {
  input: GetBrokerAccountKeyboardInput;
};


export type MutationgenerateInvoiceArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationgenerateInvoiceDocumentArgs = {
  invoiceId: Scalars['ID']['input'];
};


export type MutationgenerateMonthlyInvoiceArgs = {
  userId: Scalars['ID']['input'];
  yearMonth: Scalars['String']['input'];
};


export type MutationgenerateWeeklyInvoiceArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationimportEmployeeCSVArgs = {
  companyId: Scalars['String']['input'];
  gcpFilename: Scalars['String']['input'];
};


export type MutationimportPositionsForUserIdArgs = {
  advisorId: Scalars['UUID']['input'];
  filename: Scalars['String']['input'];
};


export type MutationimportTickerDataFromGCPUrlArgs = {
  gcpUrl: Scalars['String']['input'];
  type: TickerDataSource;
};


export type MutationincreasePriorityArgs = {
  botId: Scalars['ID']['input'];
};


export type MutationmarkAllAsSeenArgs = {
  kind: UserEventKind;
};


export type MutationmarkChargeAsCancelledArgs = {
  chargeIds: Array<Scalars['ID']['input']>;
};


export type MutationmarkInvoiceAsPaidArgs = {
  companyId: Scalars['Int']['input'];
  invoiceId: Scalars['ID']['input'];
};


export type MutationmarkTaskAsCompletedArgs = {
  onboardingTaskId: OnboardingTaskId;
};


export type MutationnotifyAuthenticationErrorArgs = {
  brokerAccountNumber: Scalars['String']['input'];
  brokerName: BrokerName;
  errorMessage: Scalars['String']['input'];
  platform: Platform;
};


export type MutationnotifyAuthenticationStartedArgs = {
  brokerAccountNumber: Scalars['String']['input'];
  brokerName: BrokerName;
  platform: Platform;
};


export type MutationpauseGoalArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationregisterFirebaseTokenArgs = {
  firebaseToken: Scalars['String']['input'];
};


export type MutationremoveAssetPositionByIdArgs = {
  assetPositionId: Scalars['ID']['input'];
};


export type MutationremoveBrokerAccountByIdArgs = {
  brokerAccountId: Scalars['ID']['input'];
};


export type MutationresetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationsendEmailInvitationsForCompanyIdArgs = {
  companyId: Scalars['String']['input'];
};


export type MutationsendInvoiceArgs = {
  invoiceId: Scalars['ID']['input'];
};


export type MutationsendMessageArgs = {
  chatId: Scalars['String']['input'];
  content: Scalars['String']['input'];
};


export type MutationsendResetPasswordEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationsetAccountNumberToBrokerAccountArgs = {
  accountNumber: Scalars['String']['input'];
  brokerAccountId: Scalars['ID']['input'];
};


export type MutationsetBrokerAccountAuthenticationArgs = {
  otp?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationsetBrokerAccountAuthorizationTokenArgs = {
  authorizationToken: Scalars['String']['input'];
  balance?: InputMaybe<Scalars['Float']['input']>;
  brokerAccountId: Scalars['Int']['input'];
};


export type MutationsetCustomerPrioritiesArgs = {
  customerEmails: Array<Scalars['String']['input']>;
};


export type MutationsetMobileAuthenticationModeArgs = {
  authenticationMode: MobileAuthenticationMode;
  financialInstitutionId: BrokerName;
  platform: Platform;
};


export type MutationsetUserRolesArgs = {
  email: Scalars['String']['input'];
  roles: Array<Scalars['String']['input']>;
};


export type MutationsetWebAuthenticationModeArgs = {
  authenticationMode: AuthenticationMode;
  financialInstitutionId: BrokerName;
};


export type MutationsettlePendingPurchasesArgs = {
  brokerName: BrokerName;
  period: DateRangeInput;
};


export type MutationsettlePurchaseArgs = {
  purchaseId: Scalars['ID']['input'];
};


export type MutationsignInEmailArgs = {
  input: SignInEmailInput;
};


export type MutationsignUpEmailArgs = {
  input: SignUpEmailInput;
};


export type MutationsoftDeleteAPIBotArgs = {
  botId: Scalars['ID']['input'];
};


export type MutationsoftDeletePurchaseOrderArgs = {
  purchaseOrderId: Scalars['ID']['input'];
};


export type MutationsubmitQuizAnswersArgs = {
  input: SubmitQuizAnswersInput;
};


export type MutationtestPurchaseArgs = {
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  brokerAccountId?: InputMaybe<Scalars['Int']['input']>;
  brokerName?: InputMaybe<BrokerName>;
};


export type MutationthumbsDownMessageArgs = {
  messageId: Scalars['UUID']['input'];
};


export type MutationthumbsUpMessageArgs = {
  messageId: Scalars['UUID']['input'];
};


export type MutationtransformPurchaseOrderIntoWarrantyPurchaseOrderArgs = {
  input: TransformAPIBotIntoWarrantyAssetsAPIBotInput;
};


export type MutationunauthenticateArgs = {
  brokerAccountId: Scalars['Int']['input'];
};


export type MutationunfinishGoalArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationunpauseGoalArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationunregisterFirebaseTokenArgs = {
  firebaseToken: Scalars['String']['input'];
};


export type MutationupdateAccountArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateAccountInput;
};


export type MutationupdateAssetPositionByIdArgs = {
  assetPositionId: Scalars['ID']['input'];
  input: UpdateAssetPositionByIdInput;
};


export type MutationupdateBrokerAccountArgs = {
  brokerAccountNumber: Scalars['String']['input'];
  financialInstitutionId: BrokerName;
  input: UpdateBrokerAccountInput;
};


export type MutationupdateBrokerAccountBalanceArgs = {
  input: UpdateBrokerAccountBalanceInput;
};


export type MutationupdateBrokerAccountOTPArgs = {
  brokerAccountId: Scalars['ID']['input'];
  otp: Scalars['String']['input'];
};


export type MutationupdateBudgetArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateBudgetInputDTO;
};


export type MutationupdateCategoryArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateCategoryInputDTO;
};


export type MutationupdateDealArgs = {
  dealId: Scalars['UUID']['input'];
  input: UpdateDealInput;
};


export type MutationupdateFiltersArgs = {
  botId: Scalars['ID']['input'];
  input: UpdateAPIBotFiltersInput;
};


export type MutationupdateFixedIncomeOfferArgs = {
  id: Scalars['String']['input'];
  input: CustomFixedIncomeOfferInput;
};


export type MutationupdateFixedIncomePositionArgs = {
  id: Scalars['UUID']['input'];
  input: UpdateFixedIncomePositionInput;
};


export type MutationupdateManyUsersPreferencesArgs = {
  emails: Array<Scalars['String']['input']>;
  preferences: UpdateUserPreferencesInput;
};


export type MutationupdatePasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};


export type MutationupdatePurchaseOrderArgs = {
  input: UpdateAPIBotFiltersInput;
  purchaseOrderId: Scalars['ID']['input'];
};


export type MutationupdatePurchaseOrderBoundaryArgs = {
  financialInstitutionId: Scalars['ID']['input'];
  input: UpdatePurchaseOrderBoundaryInput;
  investmentStrategy: Indexers;
  product: ProductKind;
};


export type MutationupdateTransactionArgs = {
  input: UpdateTransactionInput;
};


export type MutationupdateUserChargeModelArgs = {
  chargeModelId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
};


export type MutationupdateUserCpfArgs = {
  cpf: Scalars['String']['input'];
};


export type MutationupdateUserPreferencesArgs = {
  input: UpdateUserPreferencesInput;
};

export type NFSe = {
  __typename?: 'NFSe';
  id: Scalars['Float']['output'];
  numberRPS: Scalars['String']['output'];
  partner: Scalars['String']['output'];
  partnerRef: Scalars['String']['output'];
  providersCnpj: Scalars['String']['output'];
  seriesRPS: Scalars['String']['output'];
  status: Scalars['String']['output'];
  typeRPS: Scalars['String']['output'];
};

export type NamedScore = {
  __typename?: 'NamedScore';
  name: Scalars['String']['output'];
  score: ScoreData;
};

export type OnboardingTaskCompletionType = {
  __typename?: 'OnboardingTaskCompletionType';
  completed: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum OnboardingTaskId {
  BUDGET_TASK = 'BUDGET_TASK',
  CHECKUP_TASK = 'CHECKUP_TASK',
  CONNECT_ACCOUNT_TASK = 'CONNECT_ACCOUNT_TASK',
  CONSULTANCY_TASK = 'CONSULTANCY_TASK',
  COURSES_TASK = 'COURSES_TASK',
  GOAL_TASK = 'GOAL_TASK'
}

export type Operation = {
  __typename?: 'Operation';
  createdAt: Scalars['DateTime']['output'];
  customerMarkUp: Scalars['Float']['output'];
  fixedIncomePosition: FixedIncomePosition;
  foresightYieldRate: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  newFixedIncomeOffer: AL_CAPONEFixedIncomeOffer;
  newFixedIncomeOfferIndexer: Indexers;
  newFixedIncomeOfferIndexerPercentRate: Scalars['Float']['output'];
  newFixedIncomeOfferPrice: Scalars['Float']['output'];
  newFixedIncomeOfferQuantity: Scalars['Float']['output'];
  newFixedIncomeOfferRemainingBalance: Scalars['Float']['output'];
  newFixedIncomeOfferUnitPrice: Scalars['Float']['output'];
  newFixedIncomeOfferUnitPriceReferenceDate: Scalars['DateTime']['output'];
  newFixedIncomeOfferYieldRate: Scalars['Float']['output'];
  operationDate: Scalars['DateTime']['output'];
  positionSnapshot: PositionSnapshot;
  quantity: Scalars['Float']['output'];
  rebuyNetPrice: Scalars['Float']['output'];
  rebuyPrice: Scalars['Float']['output'];
  rebuyYieldDescription: YieldDescription;
  roa: Scalars['Float']['output'];
  roaAmount: Scalars['Float']['output'];
  soldPositionLoss: Scalars['Float']['output'];
  soldPositionReferenceDate: Scalars['DateTime']['output'];
  soldPositionUnitPrice: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
  totalEdges?: Maybe<Scalars['Int']['output']>;
};

export type ParsingMetrics = {
  __typename?: 'ParsingMetrics';
  failed: Scalars['Float']['output'];
  ignored: Scalars['Float']['output'];
  parsed: Scalars['Float']['output'];
};

export type ParticipantType = {
  __typename?: 'ParticipantType';
  address?: Maybe<Scalars['String']['output']>;
  avatarUrl: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastActivity: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  status: ChatParticipantStatus;
};

export type Period = {
  finalDate: Scalars['DateTime']['input'];
  initialDate: Scalars['DateTime']['input'];
};

export type PeriodBudgetType = {
  __typename?: 'PeriodBudgetType';
  availableBudget: Scalars['Float']['output'];
  budgets: Array<BudgetType>;
  totalBudget: Scalars['Float']['output'];
  totalExpenses: Scalars['Float']['output'];
  totalExpensesPercentage: Scalars['Float']['output'];
};

export enum PersonType {
  PF = 'PF',
  PJ = 'PJ'
}

export type PersonalDebtType = {
  __typename?: 'PersonalDebtType';
  amount: Scalars['Currency']['output'];
  color: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum Platform {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB'
}

export type Point = {
  __typename?: 'Point';
  x: Scalars['DateTime']['output'];
  y: Scalars['Float']['output'];
};

export type PositionSnapshot = {
  __typename?: 'PositionSnapshot';
  currentNetPrice: Scalars['Float']['output'];
  currentPrice: Scalars['Float']['output'];
  rebuyUnitPrice: Scalars['Float']['output'];
  substituteFixedIncomeOfferPriceApplied: Scalars['Float']['output'];
  substituteFixedIncomeOfferProjectedPrice: Scalars['Float']['output'];
};

export type PositionsByBank = {
  __typename?: 'PositionsByBank';
  bankId: Scalars['String']['output'];
  currentPrice: Scalars['Float']['output'];
  fgcLimit: Scalars['Float']['output'];
  futurePosition: Scalars['Float']['output'];
  percentageOfFGC: Scalars['Float']['output'];
  sources: Array<PositionsByBankSource>;
  totalApplied: Scalars['Float']['output'];
  valueUntilFGCLimit: Scalars['Float']['output'];
};

export type PositionsByBankSource = {
  __typename?: 'PositionsByBankSource';
  currentPrice: Scalars['Float']['output'];
  futurePosition: Scalars['Float']['output'];
  name: AssetPositionSource;
  percentageOfFGC: Scalars['Float']['output'];
  totalApplied: Scalars['Float']['output'];
};

export type PositionsImporterHistoric = {
  __typename?: 'PositionsImporterHistoric';
  date: Scalars['DateTime']['output'];
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  parsingErrors: Array<RowProblemDescription>;
  parsingIgnored: Array<RowProblemDescription>;
  referenceDate?: Maybe<Scalars['DateTime']['output']>;
  successCount: Scalars['Float']['output'];
};

export enum ProductKind {
  CDB = 'CDB',
  COE = 'COE',
  CRA = 'CRA',
  CRI = 'CRI',
  DEBENTURE = 'DEBENTURE',
  FIDC = 'FIDC',
  FND = 'FND',
  LC = 'LC',
  LCA = 'LCA',
  LCI = 'LCI',
  LF = 'LF',
  LFSN = 'LFSN',
  LFT = 'LFT',
  LH = 'LH',
  LTN = 'LTN',
  NC = 'NC',
  NTNB = 'NTNB',
  NTNB_P = 'NTNB_P',
  NTNC = 'NTNC',
  NTNF = 'NTNF',
  NTN_B1 = 'NTN_B1',
  RDB = 'RDB'
}

export type ProductSuggestion = {
  __typename?: 'ProductSuggestion';
  investmentStrategy: Indexers;
  productKind: Array<ProductKind>;
  yieldRate: Scalars['Float']['output'];
};

export type PublicUser = {
  __typename?: 'PublicUser';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Purchase = {
  __typename?: 'Purchase';
  assetCalculator: AssetCalculatorFields;
  assetCode: Scalars['String']['output'];
  assetName: Scalars['String']['output'];
  bankId?: Maybe<Scalars['String']['output']>;
  /** @deprecated use purchaseOrder */
  bot: APIBot;
  /** @deprecated use purchaseId */
  botId: Scalars['Float']['output'];
  brokerAccountNumber?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTime']['output'];
  detailsPageLink?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  fee: Scalars['Float']['output'];
  grossIncomeAtMaturityDate: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  indexer: Indexers;
  iofAliquot: Scalars['Float']['output'];
  iofTotalValue: Scalars['Float']['output'];
  irAliquot: Scalars['Float']['output'];
  irTotalValue: Scalars['Float']['output'];
  isNew: Scalars['Boolean']['output'];
  maturityDate: Scalars['DateTime']['output'];
  monthEquivalentIncome: Scalars['Float']['output'];
  monthRate: Scalars['Float']['output'];
  netIncome: Scalars['Float']['output'];
  purchaseOrder: APIBot;
  quantityBought: Scalars['Float']['output'];
  seller: Scalars['String']['output'];
  status: PurchaseStatus;
  totalCost: Scalars['Float']['output'];
};

export type PurchaseCreatedEventType = UserEventInterface & {
  __typename?: 'PurchaseCreatedEventType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  kind: UserEventKind;
  purchase: Purchase;
  seenByUser: Scalars['Boolean']['output'];
};

export enum PurchaseOrderAvailabilityLevel {
  BROKER = 'BROKER',
  BROKER_OFFICE = 'BROKER_OFFICE',
  PRIVATE = 'PRIVATE'
}

export type PurchaseOrderBoundaryType = {
  __typename?: 'PurchaseOrderBoundaryType';
  fee: PurchaseOrderFeeBoundaries;
  investmentStrategy: Indexers;
  isDefault: Scalars['Boolean']['output'];
  maturityDays: PurchaseOrderMaturityDaysBoundaries;
};

export type PurchaseOrderFeeBoundaries = {
  __typename?: 'PurchaseOrderFeeBoundaries';
  highValueThreshold: Scalars['Float']['output'];
  range: FloatRange;
  step: Scalars['Float']['output'];
  suggested: Scalars['Float']['output'];
};

export type PurchaseOrderMaturityDaysBoundaries = {
  __typename?: 'PurchaseOrderMaturityDaysBoundaries';
  maxSuggested: Scalars['Float']['output'];
  minSuggested: Scalars['Float']['output'];
  range: FloatRange;
};

export enum PurchaseOrderStatus {
  ENABLED = 'ENABLED',
  FEE_ABOVE_LIMIT = 'FEE_ABOVE_LIMIT',
  LOW_LIMIT = 'LOW_LIMIT',
  PAUSED = 'PAUSED'
}

export enum PurchaseStatus {
  CANCELLED = 'CANCELLED',
  LOST = 'LOST',
  PENDING = 'PENDING',
  SETTLED = 'SETTLED'
}

export type PurchaserSystem = {
  __typename?: 'PurchaserSystem';
  advisors: Array<SystemAdvisor>;
  applicableBalance: Scalars['Float']['output'];
  averageFetchAssetsLatency?: Maybe<Scalars['Float']['output']>;
  brokerName: Scalars['String']['output'];
  customers: Array<SystemCustomer>;
  date?: Maybe<Scalars['DateTime']['output']>;
  executionId?: Maybe<Scalars['String']['output']>;
  host: Scalars['String']['output'];
  id: Scalars['String']['output'];
  marketAvailableAmountReais: Scalars['Float']['output'];
  numberOfAssets: Scalars['Float']['output'];
  numberOfPurchases: Scalars['Float']['output'];
  purchaseOrders: Array<SystemPurchaseOrder>;
  purchasedAmountReais: Scalars['Float']['output'];
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  state: PurchaserSystemState;
  totalBalance: Scalars['Float']['output'];
};

export type PurchaserSystemProxy = {
  __typename?: 'PurchaserSystemProxy';
  averageLatency: Scalars['Float']['output'];
  blocked: Scalars['Boolean']['output'];
  healthy: Scalars['Boolean']['output'];
  host: Scalars['String']['output'];
  id: Scalars['String']['output'];
  port: Scalars['Float']['output'];
  protocol: Scalars['String']['output'];
};

export enum PurchaserSystemState {
  IDLE = 'IDLE',
  PAUSED = 'PAUSED',
  RUNNING = 'RUNNING'
}

export type PurchasesFunFacts = {
  __typename?: 'PurchasesFunFacts';
  averageFeeIPCA?: Maybe<Scalars['Float']['output']>;
  averageFeePosFixado?: Maybe<Scalars['Float']['output']>;
  averageFeePrefixado?: Maybe<Scalars['Float']['output']>;
  averageMaturityDaysIPCA?: Maybe<Scalars['Float']['output']>;
  averageMaturityDaysPosFixado?: Maybe<Scalars['Float']['output']>;
  averageMaturityDaysPrefixado?: Maybe<Scalars['Float']['output']>;
  grossIncomeToBeReceived: Scalars['Float']['output'];
  grossIncomeTotal: Scalars['Float']['output'];
  netIncomeToBeReceived: Scalars['Float']['output'];
  netIncomeTotal: Scalars['Float']['output'];
  totalApplied?: Maybe<Scalars['Float']['output']>;
  totalAppliedNotMatured: Scalars['Float']['output'];
};

export type Query = {
  __typename?: 'Query';
  accountById: INVEST_PASS_APIAccount;
  allBanks: Array<Bank>;
  allFinancialInstitutions: Array<FinancialInstitution>;
  allInstitutions: Array<INVEST_PASS_API_Pluggy_Institution>;
  availableAdvisorsForMyUser: Array<AL_CAPONEAdvisor>;
  availableFixedIncomeOffers: Array<AL_CAPONEFixedIncomeOffer>;
  /** @deprecated use purchaseOrderById */
  botById: APIBot;
  /** @deprecated Use purchaseOrders */
  bots: Array<APIBot>;
  brokerAccountById?: Maybe<BrokerAccount>;
  brokerAccounts: BrokerAccountsResponse;
  calculateDeal: CalculateDealPayload;
  categoryById: CategoryType;
  chatById: ChatType;
  checkIfEmailIsUsed: Scalars['Boolean']['output'];
  comparisonCharts: ComparisonChartsPayload;
  courseById: CourseType;
  courses: Array<CourseType>;
  creditsBalance: Scalars['Float']['output'];
  customPitch?: Maybe<CustomPitchEntity>;
  employee: EmployeeType;
  employees: Array<EmployeeType>;
  financialHealth?: Maybe<FinancialHealth>;
  findAllBudgets: Array<BudgetType>;
  findAllCategories: Array<CategoryType>;
  findAllTransactions: Array<TransactionObjectType>;
  firstBrokerAccount?: Maybe<BrokerAccount>;
  fixedIncomeOfferById: AL_CAPONEFixedIncomeOffer;
  fixedIncomePositionById: FixedIncomePosition;
  getAdvisoryOffices: Array<AdvisoryOffice>;
  getAssetPositionById: AssetPosition;
  getAvailablePurchaseOrders: Array<APIBot>;
  /** @deprecated Use the authenticateBrokerAccount mutation instead. */
  getBrokerAccountAuthentication: BrokerAuthenticationPayload;
  /** @deprecated Use the generateBrokerAccountKeyboard mutation instead. */
  getBrokerAccountKeyboard: GetBrokerAccountKeyboardPayload;
  getCharges: Array<Charge>;
  getEmailForRecoverPasswordToken: Scalars['String']['output'];
  getExpensesByCategory: Array<CategoryExpenseType>;
  getFinancialInstitutionById?: Maybe<FinancialInstitution>;
  getInvoiceById: ChargeInvoice;
  getInvoices: Array<ChargeInvoice>;
  getMyOperations: Array<Operation>;
  getNFSeById: NFSe;
  getOnboardingTasks: Array<OnboardingTaskCompletionType>;
  getOperationById: Operation;
  getPluggyConnections: Array<INVEST_PASS_API_Pluggy_Item>;
  getPositionsImporterHistoric: Array<PositionsImporterHistoric>;
  getPurchases: GetPurchasesPayload;
  getQuiz: QuizType;
  getSummary: INVEST_PASS_API_Summary;
  getTransactionsChart: INVEST_PASS_API_Chart;
  getUploadSignedUrl: GetUploadSignedUrlPayload;
  getUserById?: Maybe<User>;
  getUsers: Array<User>;
  goalById: GoalType;
  hasDoneConsultancy: Scalars['Boolean']['output'];
  hasPendingTransactions: Scalars['Boolean']['output'];
  isCouponActive?: Maybe<Coupon>;
  isFirstBudget: Scalars['Boolean']['output'];
  me: AccountType;
  mgmCoupon: Coupon;
  myAccounts: Array<INVEST_PASS_APIAccount>;
  myAdvisoryOffice: AdvisoryOffice;
  /** @deprecated use myPurchaseOrders */
  myBots: Array<APIBot>;
  myCharges: Array<Charge>;
  myChat: ChatType;
  myConsultant?: Maybe<ConsultantType>;
  myEvents: Array<UserEventInterface>;
  myGoals: Array<GoalType>;
  myInvoices: Array<ChargeInvoice>;
  myPersonalDebts: Array<PersonalDebtType>;
  myPurchaseOrders: Array<APIBot>;
  myPurchases: Array<Purchase>;
  myPurchasesFunFacts: PurchasesFunFacts;
  nextInvoicePreview: ChargeInvoice;
  paginatedPositionsToDeal: FixedIncomePositionConnection;
  pendingTransactions: Array<TransactionObjectType>;
  periodBudget: PeriodBudgetType;
  previewMonthlyInvoice: GenerateInvoicePayload;
  previewWeeklyInvoice: ChargeInvoice;
  productSuggestion?: Maybe<ProductSuggestion>;
  purchaseOrderById: APIBot;
  purchaseOrders: Array<APIBot>;
  queryPurchaserSystem: QuerySystemPayload;
  /** @deprecated use recentePurchaseOrders */
  recentBots: Array<APIBot>;
  recentPurchaseOrders: Array<APIBot>;
  shouldAllowResetQuiz: Scalars['Boolean']['output'];
  shouldDisplayQuizOnboarding: Scalars['Boolean']['output'];
  shouldDisplayTransactionsOnboarding: Scalars['Boolean']['output'];
  simulate: SimulationPayload;
  simulatePublic: SimulationPayload;
  throwError: Scalars['Boolean']['output'];
  throwRequestError: Scalars['Boolean']['output'];
  transaction: TransactionObjectType;
  transactionById: TransactionObjectType;
  userAccounts: Array<INVEST_PASS_APIAccount>;
  userBrokerAccounts: Array<AL_CAPONEBrokerAccount>;
  userBudgets: PeriodBudgetType;
  userEventSummary: UserEventSummary;
  userFinancialHealth?: Maybe<FinancialHealth>;
  userGoals: Array<GoalType>;
  /** @deprecated use previewWeeklyInvoice */
  userNextInvoicePreview: ChargeInvoice;
  userOnboardingTasks: Array<OnboardingTaskCompletionType>;
  validateBrokerAccountNumber: ValidateBrokerAccountPayload;
  validateCoupon?: Maybe<Coupon>;
};


export type QueryaccountByIdArgs = {
  id: Scalars['UUID']['input'];
};


export type QuerybotByIdArgs = {
  botId: Scalars['ID']['input'];
};


export type QuerybrokerAccountByIdArgs = {
  brokerAccountId: Scalars['ID']['input'];
};


export type QuerybrokerAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<BrokerAccountsFilter>;
  first?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Float']['input']>;
  sort?: InputMaybe<BrokerAccountsSort>;
};


export type QuerycalculateDealArgs = {
  input: CalculateDealInput;
};


export type QuerycategoryByIdArgs = {
  id: Scalars['UUID']['input'];
};


export type QuerychatByIdArgs = {
  chatId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerycheckIfEmailIsUsedArgs = {
  email: Scalars['String']['input'];
};


export type QuerycomparisonChartsArgs = {
  input: ComparisonChartsInput;
};


export type QuerycourseByIdArgs = {
  id: Scalars['UUID']['input'];
};


export type QuerycustomPitchArgs = {
  fixedIncomeOfferId: Scalars['UUID']['input'];
  fixedIncomePositionId: Scalars['UUID']['input'];
};


export type QueryemployeeArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryfindAllCategoriesArgs = {
  filter?: InputMaybe<CategoriesFilter>;
};


export type QueryfindAllTransactionsArgs = {
  filters?: InputMaybe<FindAllTransactionsFilter>;
};


export type QueryfixedIncomeOfferByIdArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryfixedIncomePositionByIdArgs = {
  id: Scalars['UUID']['input'];
};


export type QuerygetAssetPositionByIdArgs = {
  assetPositionId: Scalars['ID']['input'];
};


export type QuerygetAvailablePurchaseOrdersArgs = {
  filters: GetAvailablePurchaseOrdersFilters;
};


export type QuerygetBrokerAccountAuthenticationArgs = {
  input: BrokerAuthenticationInput;
};


export type QuerygetBrokerAccountKeyboardArgs = {
  input: GetBrokerAccountKeyboardInput;
};


export type QuerygetChargesArgs = {
  input: GetChargesInput;
};


export type QuerygetEmailForRecoverPasswordTokenArgs = {
  token: Scalars['String']['input'];
};


export type QuerygetExpensesByCategoryArgs = {
  filters?: InputMaybe<ExpensesByCategoryFilter>;
};


export type QuerygetFinancialInstitutionByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QuerygetInvoiceByIdArgs = {
  invoiceId: Scalars['ID']['input'];
};


export type QuerygetInvoicesArgs = {
  input: GetInvoicesInput;
};


export type QuerygetNFSeByIdArgs = {
  id: Scalars['Float']['input'];
};


export type QuerygetOperationByIdArgs = {
  operationId: Scalars['UUID']['input'];
};


export type QuerygetPurchasesArgs = {
  filters: GetPurchasesFilter;
};


export type QuerygetSummaryArgs = {
  filters?: InputMaybe<SummaryFilter>;
};


export type QuerygetTransactionsChartArgs = {
  filters?: InputMaybe<FindAllTransactionsFilter>;
  movementType: MovementType;
};


export type QuerygetUploadSignedUrlArgs = {
  filename?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};


export type QuerygetUserByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QuerygoalByIdArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryisCouponActiveArgs = {
  couponCode: Scalars['String']['input'];
  target?: InputMaybe<InvestAIBU>;
};


export type QuerymgmCouponArgs = {
  source?: InputMaybe<InvestAIBU>;
  target?: InputMaybe<InvestAIBU>;
};


export type QuerymyBotsArgs = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerymyChargesArgs = {
  hasDiscount: Scalars['Boolean']['input'];
};


export type QuerymyEventsArgs = {
  seenByUser: Scalars['Boolean']['input'];
};


export type QuerymyPurchaseOrdersArgs = {
  availabilityLevel?: PurchaseOrderAvailabilityLevel;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerypaginatedPositionsToDealArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<FixedIncomePositionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: Scalars['String']['input'];
  sortDirection?: SortDirection;
};


export type QueryperiodBudgetArgs = {
  period?: InputMaybe<Scalars['String']['input']>;
};


export type QuerypreviewMonthlyInvoiceArgs = {
  userId: Scalars['ID']['input'];
  yearMonth: Scalars['String']['input'];
};


export type QuerypreviewWeeklyInvoiceArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryproductSuggestionArgs = {
  brokerAccountId: Scalars['Int']['input'];
  investmentStrategy: Indexers;
  maturityDaysRange: Array<Scalars['Int']['input']>;
  productKind: ProductKind;
  yieldRate: Scalars['Float']['input'];
};


export type QuerypurchaseOrderByIdArgs = {
  purchaseOrderId: Scalars['ID']['input'];
};


export type QuerysimulateArgs = {
  input: SimulationInput;
};


export type QuerysimulatePublicArgs = {
  input: SimulationInput;
};


export type QuerytransactionArgs = {
  id: Scalars['UUID']['input'];
};


export type QuerytransactionByIdArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryuserAccountsArgs = {
  userId: Scalars['String']['input'];
};


export type QueryuserBrokerAccountsArgs = {
  filter?: InputMaybe<AdvisorBrokerAccountsFilter>;
};


export type QueryuserBudgetsArgs = {
  id: Scalars['String']['input'];
  period?: InputMaybe<Scalars['String']['input']>;
};


export type QueryuserFinancialHealthArgs = {
  id: Scalars['String']['input'];
};


export type QueryuserGoalsArgs = {
  id: Scalars['String']['input'];
};


export type QueryuserNextInvoicePreviewArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryuserOnboardingTasksArgs = {
  userId: Scalars['String']['input'];
};


export type QueryvalidateBrokerAccountNumberArgs = {
  accountNumber: Scalars['String']['input'];
  broker: BrokerName;
};


export type QueryvalidateCouponArgs = {
  couponCode: Scalars['String']['input'];
  target?: InputMaybe<InvestAIBU>;
};

export type QuerySystemPayload = {
  __typename?: 'QuerySystemPayload';
  proxies: Array<PurchaserSystemProxy>;
  system: PurchaserSystem;
};

export type QuestionChoiceType = {
  __typename?: 'QuestionChoiceType';
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type QuestionType = {
  __typename?: 'QuestionType';
  choices: Array<QuestionChoiceType>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type QuizAnswerInput = {
  questionId: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type QuizAnswerType = {
  __typename?: 'QuizAnswerType';
  employee: EmployeeType;
  id: Scalars['String']['output'];
  question: QuestionType;
  value: Scalars['String']['output'];
};

export type QuizType = {
  __typename?: 'QuizType';
  id: Scalars['String']['output'];
  questions: Array<QuestionType>;
  title: Scalars['String']['output'];
};

export type RangeInput = {
  max?: InputMaybe<Scalars['DateTime']['input']>;
  min?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RowProblemDescription = {
  __typename?: 'RowProblemDescription';
  columnName?: Maybe<Scalars['String']['output']>;
  line?: Maybe<Scalars['Float']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ScoreData = {
  __typename?: 'ScoreData';
  max: Scalars['Float']['output'];
  percentage: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type SignInEmailInput = {
  appleId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  method?: InputMaybe<SignInMethod>;
  password?: InputMaybe<Scalars['String']['input']>;
  prefilledCoupon?: InputMaybe<Scalars['String']['input']>;
  socialToken?: InputMaybe<Scalars['String']['input']>;
};

export type SignInEmailPayload = {
  __typename?: 'SignInEmailPayload';
  account: AccountType;
  /** Indicates whether the user was created by this mutation or not. */
  newUser: Scalars['Boolean']['output'];
  tokens: TokenPairType;
};

export enum SignInMethod {
  APPLE = 'APPLE',
  EMAIL = 'EMAIL',
  GOOGLE = 'GOOGLE'
}

export type SignUpEmailInput = {
  cpf: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  prefilledCoupon?: InputMaybe<Scalars['String']['input']>;
  webAttribution?: InputMaybe<WebAttribution>;
};

export type SignUpEmailPayload = {
  __typename?: 'SignUpEmailPayload';
  account: AccountType;
  tokens: TokenPairType;
};

export type SimulationInput = {
  applicationDate: Scalars['DateTime']['input'];
  fee: Scalars['Float']['input'];
  indexer: Indexers;
  maturityDate: Scalars['DateTime']['input'];
  totalApplied: Scalars['Float']['input'];
};

export type SimulationPayload = {
  __typename?: 'SimulationPayload';
  businessDaysInPeriod: Scalars['Float']['output'];
  feeDescription: Scalars['String']['output'];
  grossIncome: Scalars['Float']['output'];
  iofAliquot: Scalars['Float']['output'];
  iofTotal: Scalars['Float']['output'];
  irAliquot: Scalars['Float']['output'];
  irTotal: Scalars['Float']['output'];
  netIncome: Scalars['Float']['output'];
  serviceAliquot: Scalars['Float']['output'];
  serviceTotal: Scalars['Float']['output'];
  totalDaysInPeriod: Scalars['Float']['output'];
};

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type SubmitQuizAnswersInput = {
  answers: Array<QuizAnswerInput>;
  quizId: Scalars['String']['input'];
};

export type SummaryFilter = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  includeIgnored?: InputMaybe<Scalars['Boolean']['input']>;
  insertionType?: InputMaybe<TransactionInsertionType>;
  periodEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  periodStartDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SynchronizationMetrics = {
  __typename?: 'SynchronizationMetrics';
  deleted: Scalars['Float']['output'];
  inserted: Scalars['Float']['output'];
  updated: Scalars['Float']['output'];
};

export enum SynchronizationStatus {
  CANCELLED = 'CANCELLED',
  ENQUEUED = 'ENQUEUED',
  ERROR = 'ERROR',
  IDLE = 'IDLE',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS'
}

export type SystemAdvisor = {
  __typename?: 'SystemAdvisor';
  id: Scalars['Float']['output'];
};

export type SystemCustomer = {
  __typename?: 'SystemCustomer';
  id: Scalars['Float']['output'];
  isAbleToPurchase: Scalars['Boolean']['output'];
};

export type SystemPurchaseOrder = {
  __typename?: 'SystemPurchaseOrder';
  id: Scalars['Float']['output'];
  isAbleToPurchase: Scalars['Boolean']['output'];
};

export enum TestPurchaseResult {
  InsufficientBalance = 'InsufficientBalance',
  InvalidBrokerAccountToken = 'InvalidBrokerAccountToken',
  InvalidDigitalSignature = 'InvalidDigitalSignature',
  PendingAccount = 'PendingAccount',
  PurchaseExecuted = 'PurchaseExecuted',
  Success = 'Success'
}

export enum TickerDataSource {
  AMBIMA = 'AMBIMA',
  AMBIMA_CRI_CRA_SHEET = 'AMBIMA_CRI_CRA_SHEET',
  ECONOMATICA = 'ECONOMATICA',
  ECONOMATICA_DEBENTURE_SHEET = 'ECONOMATICA_DEBENTURE_SHEET',
  ECONOMATICA_YIELD_TREASURY_SHEET = 'ECONOMATICA_YIELD_TREASURY_SHEET',
  POSITIONS_SHEET = 'POSITIONS_SHEET',
  USER = 'USER'
}

export type TickerObjectType = {
  __typename?: 'TickerObjectType';
  duration?: Maybe<Scalars['Float']['output']>;
  freeOfIR?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  maturityDate?: Maybe<Scalars['DateTime']['output']>;
  rebuyTotalNetPrice?: Maybe<Scalars['Float']['output']>;
  rebuyTotalPrice?: Maybe<Scalars['Float']['output']>;
  rebuyUnitPrice?: Maybe<Scalars['Float']['output']>;
  referenceDate: Scalars['DateTime']['output'];
};

export type TokenPairType = {
  __typename?: 'TokenPairType';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
};

export enum TransactionInsertionType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL'
}

export type TransactionObjectType = {
  __typename?: 'TransactionObjectType';
  account?: Maybe<INVEST_PASS_APIAccount>;
  amount: Scalars['Float']['output'];
  category: CategoryType;
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  employee: EmployeeType;
  id: Scalars['String']['output'];
  ignored: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  type: MovementType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TransformAPIBotIntoWarrantyAssetsAPIBotInput = {
  botId: Scalars['ID']['input'];
};

export type UpdateAPIBotFiltersInput = {
  feeMax?: InputMaybe<Scalars['Float']['input']>;
  feeMin?: InputMaybe<Scalars['Float']['input']>;
  indexer?: InputMaybe<Indexers>;
  interestingBanks?: InputMaybe<Array<Scalars['String']['input']>>;
  interestingProducts?: InputMaybe<Array<ProductKind>>;
  investmentStrategy?: InputMaybe<Indexers>;
  maturityDaysMax: Scalars['Float']['input'];
  maturityDaysMin: Scalars['Float']['input'];
  maximumPurchasesTotalCost?: InputMaybe<Scalars['Float']['input']>;
  shouldLimitPurchasesTotalCost: Scalars['Boolean']['input'];
};

export type UpdateAccountInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateAssetPositionByIdInput = {
  bankId?: InputMaybe<Scalars['ID']['input']>;
  futurePosition: Scalars['Float']['input'];
  maturityDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateBrokerAccountBalanceInput = {
  brokerAccountId: Scalars['Float']['input'];
  newBalance: Scalars['Float']['input'];
};

export type UpdateBrokerAccountInput = {
  advisorId?: InputMaybe<Scalars['Int']['input']>;
  fgcLimitPerBankEnabledByDefault?: InputMaybe<Scalars['Boolean']['input']>;
  fgcLimitPerPersonEnabledByDefault?: InputMaybe<Scalars['Boolean']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBudgetInputDTO = {
  amount: Scalars['Float']['input'];
};

export type UpdateCategoryInputDTO = {
  color: Scalars['String']['input'];
  description: Scalars['String']['input'];
  icon: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateDealInput = {
  foresightYieldRate: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  substituteFixedIncomeOfferId: Scalars['String']['input'];
};

export type UpdateFixedIncomePositionInput = {
  applicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  appliedUnitPrice?: InputMaybe<Scalars['Float']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  freeOfIR?: InputMaybe<Scalars['Boolean']['input']>;
  indexer?: InputMaybe<Indexers>;
  indexerPercentRate?: InputMaybe<Scalars['Float']['input']>;
  investmentCategory?: InputMaybe<InvestmentCategory>;
  issuerId?: InputMaybe<Scalars['String']['input']>;
  maturityDate?: InputMaybe<Scalars['DateTime']['input']>;
  productKind?: InputMaybe<FixedIncomeProductKind>;
  rating?: InputMaybe<Scalars['String']['input']>;
  rebuyUnitPrice?: InputMaybe<Scalars['Float']['input']>;
  referenceUnitPrice?: InputMaybe<Scalars['Float']['input']>;
  target?: InputMaybe<FixedIncomeOfferTarget>;
  yieldRate?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateInvoiceInput = {
  invoiceId: Scalars['Float']['input'];
  total: Scalars['Float']['input'];
};

export type UpdatePurchaseOrderBoundaryInput = {
  feeHighValueThreshold?: InputMaybe<Scalars['Float']['input']>;
  feeMinLowerBoundary?: InputMaybe<Scalars['Float']['input']>;
  feeMinSuggested?: InputMaybe<Scalars['Float']['input']>;
  feeMinUpperBoundary?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateTransactionInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['UUID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<MovementType>;
  updateCategoryForAllTransactions?: InputMaybe<Scalars['Boolean']['input']>;
  updateFutureTransactions?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateUserChargeModelPayload = {
  __typename?: 'UpdateUserChargeModelPayload';
  purchaseOrders: Array<APIBot>;
  user: User;
};

export type UpdateUserPreferencesInput = {
  botAuthEmailReminder?: InputMaybe<Scalars['Boolean']['input']>;
  botAuthPushNotificationReminder?: InputMaybe<Scalars['Boolean']['input']>;
  botAuthWhatsAppReminder?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatedPreferencesSummary = {
  __typename?: 'UpdatedPreferencesSummary';
  count: Scalars['Float']['output'];
  emails: Array<Scalars['String']['output']>;
};

export type UpdatedUsersPreferencesPayload = {
  __typename?: 'UpdatedUsersPreferencesPayload';
  failure: UpdatedPreferencesSummary;
  success: UpdatedPreferencesSummary;
};

export type User = {
  __typename?: 'User';
  appliedCoupons: Array<CouponUsage>;
  assetPositions: Array<AssetPosition>;
  averageFee?: Maybe<Scalars['Float']['output']>;
  balance?: Maybe<Scalars['Float']['output']>;
  botAuthEmailReminder: Scalars['Boolean']['output'];
  botAuthPushNotificationReminder: Scalars['Boolean']['output'];
  botAuthWhatsAppReminder: Scalars['Boolean']['output'];
  brokerAccounts: Array<BrokerAccount>;
  brokerOffice?: Maybe<BrokerOffice>;
  chargeModel: ChargeModel;
  charges: Array<Charge>;
  cpf?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  /** @deprecated use hasPurchaseOrder */
  hasBot: Scalars['Boolean']['output'];
  hasPurchaseOrder: Scalars['Boolean']['output'];
  id: Scalars['Float']['output'];
  invoices: Array<ChargeInvoice>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  positionsByBank: Array<PositionsByBank>;
  prefilledCoupon?: Maybe<Scalars['String']['output']>;
  snoozeJFKPromotion?: Maybe<Scalars['Boolean']['output']>;
  snoozeJFKPromotionDate?: Maybe<Scalars['DateTime']['output']>;
  totalInvestments: Scalars['Float']['output'];
};


export type UserassetPositionsArgs = {
  source?: InputMaybe<AssetPositionSource>;
};

export type UserAdvisoryOfficeOwnership = {
  __typename?: 'UserAdvisoryOfficeOwnership';
  advisoryOfficeId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type UserEventInterface = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  kind: UserEventKind;
  seenByUser: Scalars['Boolean']['output'];
};

export enum UserEventKind {
  ChargeCreated = 'ChargeCreated',
  InvoiceSent = 'InvoiceSent',
  MGMBrokerAccountLedgerEntryCreated = 'MGMBrokerAccountLedgerEntryCreated',
  MGMBrokerCouponUsageCreated = 'MGMBrokerCouponUsageCreated',
  PurchaseCancelled = 'PurchaseCancelled',
  PurchaseCreated = 'PurchaseCreated',
  PurchaseSettled = 'PurchaseSettled'
}

export type UserEventSummary = {
  __typename?: 'UserEventSummary';
  unseenInvoiceSentCount: Scalars['Float']['output'];
  unseenMGMCouponUsageCount: Scalars['Float']['output'];
  unseenMGMLedgerEntryCount: Scalars['Float']['output'];
  unseenPurchaseCancelledCount: Scalars['Float']['output'];
  unseenPurchaseCreatedCount: Scalars['Float']['output'];
  unseenPurchaseSettledCount: Scalars['Float']['output'];
};

export type ValidateBrokerAccountPayload = {
  __typename?: 'ValidateBrokerAccountPayload';
  isAvailable: Scalars['Boolean']['output'];
};

export type VideoType = {
  __typename?: 'VideoType';
  createdAt: Scalars['DateTime']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  thumbnailUrl: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type VirtualKeyboardKey = {
  __typename?: 'VirtualKeyboardKey';
  id: Scalars['String']['output'];
  options: Array<Scalars['Int']['output']>;
};

export type VirtualKeyboardType = {
  __typename?: 'VirtualKeyboardType';
  keys: Array<VirtualKeyboardKey>;
};

export type WebAttribution = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type YieldDescription = {
  __typename?: 'YieldDescription';
  indexer: Indexers;
  indexerPercentRate: Scalars['Float']['output'];
  yieldRate: Scalars['Float']['output'];
};

export type YieldDescriptionInput = {
  indexer: Indexers;
  indexerPercentRate: Scalars['Float']['input'];
  yieldRate: Scalars['Float']['input'];
};

export type UpdatePasswordMutationMutationVariables = Exact<{
  oldPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type UpdatePasswordMutationMutation = { __typename?: 'Mutation', updatePassword: boolean };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', creditsBalance: number, me: { __typename?: 'AccountType', user?: { __typename: 'User', id: number, email: string, name: string, phone?: string | null, cpf?: string | null, botAuthEmailReminder: boolean, botAuthWhatsAppReminder: boolean, botAuthPushNotificationReminder: boolean } | null } };

export type DeleteUserAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteUserAccountMutation = { __typename?: 'Mutation', deleteUserAccount: { __typename?: 'User', id: number } };

export type IsCouponActiveQueryVariables = Exact<{
  couponCode: Scalars['String']['input'];
  target: InvestAIBU;
}>;


export type IsCouponActiveQuery = { __typename?: 'Query', isCouponActive?: { __typename?: 'Coupon', code: string, newBrokerAccountPrize: number } | null };

export type GetEmailForRecoverPasswordTokenQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type GetEmailForRecoverPasswordTokenQuery = { __typename?: 'Query', getEmailForRecoverPasswordToken: string };

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type SendResetPasswordMutationMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type SendResetPasswordMutationMutation = { __typename?: 'Mutation', sendResetPasswordEmail: boolean };

export type SignInMutationVariables = Exact<{
  input: SignInEmailInput;
}>;


export type SignInMutation = { __typename?: 'Mutation', signIn: { __typename?: 'SignInEmailPayload', newUser: boolean, account: { __typename?: 'AccountType', id: string, email: string, user?: { __typename: 'User', id: number, hasBot: boolean } | null }, tokens: { __typename?: 'TokenPairType', accessToken: string, refreshToken: string } } };

export type SignUpMutationVariables = Exact<{
  input: SignUpEmailInput;
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUpEmail: { __typename?: 'SignUpEmailPayload', account: { __typename?: 'AccountType', id: string, email: string }, tokens: { __typename?: 'TokenPairType', accessToken: string, refreshToken: string } } };
