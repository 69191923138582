import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { type PropsWithChildren, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppContext } from '../../../app/context/useAppContext';
import { NavBarContext } from '../contexts/CollapseDrawerContext';
import { ExternalLinkDialogContext } from '../contexts/ExternalLinkDialogContext';
import { ExternalLinkDialog } from './ExternalLinkDialog';
import { NavHeader } from './NavHeader';
import { NavMini } from './NavMini';
import type { SidebarConfig } from './NavSection';
import { NavVertical } from './NavVertical';
import { OutletWrapper } from './OutletWrapper';
import { useGlobalDeps } from './useGlobalDeps';

export interface IMainLayoutProps {
  logo: React.ReactNode;
  logoMini: React.ReactNode;
  headerContent?: React.ReactNode;
}

const _MainLayout: React.FC<PropsWithChildren<IMainLayoutProps>> = ({
  logo,
  logoMini,
  headerContent,
  children,
}) => {
  const globalDeps = useGlobalDeps();
  const [sidebarConfig, setSidebarConfig] = useState<SidebarConfig>([]);
  const { isOpen, open } = globalDeps.navbarContext;
  const { sidebarConfig: sidebarConfigGetter } = useAppContext();

  useEffect(() => {
    if (typeof sidebarConfigGetter !== 'function') {
      setSidebarConfig(sidebarConfigGetter);
      return;
    }
    const sidebarConfig = sidebarConfigGetter(globalDeps);

    if (sidebarConfig instanceof Promise) {
      sidebarConfig.then((sidebarConfigResolveValue) => {
        setSidebarConfig(sidebarConfigResolveValue);
      });
      return;
    }

    setSidebarConfig(sidebarConfig);
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const shouldDisplayNavBarVertical = isOpen || isMobile;
  const navBar = shouldDisplayNavBarVertical ? (
    <NavVertical logo={logo} sidebarConfig={sidebarConfig} />
  ) : (
    <NavMini logo={logoMini} sidebarConfig={sidebarConfig} />
  );

  return (
    <Box data-cy='dashboard-layout'>
      <NavHeader onOpenNav={open}>{headerContent}</NavHeader>
      <ExternalLinkDialogContext.Provider>
        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {navBar}
          <OutletWrapper>{children ?? <Outlet />}</OutletWrapper>
        </Box>
        <ExternalLinkDialog />
      </ExternalLinkDialogContext.Provider>
    </Box>
  );
};

export const MainLayout: React.FC<PropsWithChildren<IMainLayoutProps>> = (
  props,
) => {
  return (
    <NavBarContext.Provider>
      <_MainLayout {...props} />
    </NavBarContext.Provider>
  );
};
