import { createContainer } from 'unstated-next';
import { type IContainerProps } from '../components/Container';

type IModalContext = Pick<IContainerProps, 'onClose' | 'loading' | 'variant'>;

interface IUseModalProps {
  initialState?: IModalContext;
}

const useModalProps = ({ initialState }: IUseModalProps = {}) => {
  return {
    loading: initialState?.loading,
    onClose: initialState?.onClose,
    variant: initialState?.variant,
  };
};

export const ModalContext = createContainer<IModalContext, IUseModalProps>(
  useModalProps,
);
