export class FilteredEventStrategy {
  constructor(private readonly filterEvents: string[] | undefined) {}
  public shouldTrack(eventName: string) {
    if (!this.filterEvents) {
      return true;
    }

    return this.filterEvents.includes(eventName);
  }
}
