import type { DefaultRouteNames } from '../context/IAppContext';

export const defaultRoutePaths: DefaultRouteNames = {
  myAccount: {
    root: '/minha-conta',
  },
  main: '/',
  auth: {
    signIn: '/entrar',
    signUp: '/cadastrar',
    forgotPassword: '/esqueci-a-senha',
    resetPassword: '/resetar-senha',
    logout: '/logout',
  },
  common: {
    notFound: '404',
    termsOfUse: '/termos-de-uso',
    privacyPolicy: '/politica-de-privacidade',
  },
};
