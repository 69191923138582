import { useAppContext } from '@invest-ai/animatrix';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  SignInMethod,
  type SignInMutation,
} from '../../../__generated__/types';
import { useGoogleSignIn } from '../../hooks/useGoogleSignIn';
import { useTokens } from '../../hooks/useTokens';
import { setCookieToken } from '../../logic/setCookieToken';
import { GoogleSignInButton } from '../GoogleSignInButton';
import { AppEvents } from '../SignIn/AppEvents';
import { gtmTrackSignUpConversion } from './gtmTrackSignUpConversion';

export const GoogleSignIn: React.FC<{
  onError: (error: Error) => void;
}> = ({ onError }) => {
  const { defaultRouteNames: routes, product, tracker } = useAppContext();
  const [searchParams] = useSearchParams();
  const { googleSignIn, error: errorGoogleSignIn } = useGoogleSignIn();
  const { setToken } = useTokens();

  const navigate = useNavigate();

  useEffect(() => {
    if (errorGoogleSignIn) {
      onError(errorGoogleSignIn);
    }
  }, [errorGoogleSignIn, onError]);

  const googleSignInTrack = (data: SignInMutation['signIn']) => {
    const { id, email } = data.account;
    const { newUser } = data;
    if (newUser) gtmTrackSignUpConversion();
    tracker?.setUser(id, email);
    tracker?.trackEvent(AppEvents.SignInComplete, {
      method: SignInMethod.GOOGLE,
      bu: product.name,
    });
  };
  const handleGoogleSignInResponse = (data: SignInMutation['signIn']) => {
    googleSignInTrack(data);
    setToken(data.tokens.accessToken);
    setCookieToken(data.tokens.accessToken);
    navigate(routes.main);
  };

  const performGoogleSignIn = async (tokenId: string) => {
    const prefilledCoupon = searchParams.get('r');
    const signInResponse = await googleSignIn(tokenId, prefilledCoupon);
    handleGoogleSignInResponse(signInResponse);
  };

  return (
    <GoogleSignInButton onSuccess={performGoogleSignIn} onError={onError} />
  );
};
