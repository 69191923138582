import { Box, Typography, useTheme } from '@mui/material';
import Image from 'mui-image';

interface ISideSectionProps {
  image?: string | undefined;
  imageDimensions?:
    | {
        width: number;
        height: number;
      }
    | undefined;
  title: React.ReactNode;
  subtitle: React.ReactNode;
}

export const SideSection: React.FC<ISideSectionProps> = ({
  image,
  imageDimensions,
  title,
  subtitle,
}) => {
  const theme = useTheme();
  return (
    <Box
      maxWidth={440}
      display='flex'
      flexDirection='column'
      alignItems='center'
    >
      {image && imageDimensions && (
        <Image
          width={imageDimensions.width}
          height={imageDimensions.height}
          src={image}
          duration={150}
          style={{ marginBottom: theme.spacing(5) }}
        />
      )}

      <Typography variant='h4' align='center' mb={3}>
        {title}
      </Typography>
      {typeof subtitle === 'string' ? (
        <Typography
          variant='subtitle1'
          align='center'
          color='grey.600'
          sx={{ textShadow: '0px 4px 20px rgba(0,0,0,0.25)' }}
        >
          {subtitle}
        </Typography>
      ) : (
        subtitle
      )}
    </Box>
  );
};
