import {
  Button,
  DisplayError,
  PasswordInput,
  TextField,
  useAppContext,
} from '@invest-ai/animatrix';
import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { type FormEventHandler, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  SignInMethod,
  type SignInMutation,
} from '../../../__generated__/types';
import { useEmailSignIn } from '../../hooks/useEmailSignIn';
import { useTokens } from '../../hooks/useTokens';
import { setCookieToken } from '../../logic/setCookieToken';

import { useErrorTracker } from '../../../bugsnag/useErrorTracker';
import { GoogleSignIn } from '../SignUp/GoogleSignIn';
import { AppEvents } from './AppEvents';
import { handleKnownErrors } from './handleKnownErrors';

export const SignIn: React.FC = () => {
  const { product, defaultRouteNames: routes, tracker } = useAppContext();

  const navigate = useNavigate();
  const { setToken } = useTokens();
  const {
    emailSignIn,
    loading: loadingEmailSignIn,
    error: errorEmailSignIn,
  } = useEmailSignIn();
  const [googleOAuthError, setGoogleOAuthError] = useState<Error | null>(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const errorTracker = useErrorTracker();

  const handleSignInResponse = (
    signInEmailPayload: SignInMutation['signIn'],
    method: SignInMethod,
  ) => {
    const id = signInEmailPayload.account.id;
    const accessToken = signInEmailPayload.tokens.accessToken;

    tracker?.setUser(id, email);
    errorTracker.setUser(id, email);

    tracker?.trackEvent(AppEvents.SignInComplete, { method, bu: product.name });

    setToken(accessToken);
    setCookieToken(accessToken);
    navigate(routes.main);
  };

  const performEmailSignIn: FormEventHandler = async (e) => {
    e.preventDefault();
    try {
      const signInResponse = await emailSignIn(email, password);
      handleSignInResponse(signInResponse, SignInMethod.EMAIL);
    } catch (e) {
      if (e instanceof Error) {
        handleKnownErrors(e);
      }
    }
  };

  const displayGoogleErrorMessage = (error: Error) => {
    setGoogleOAuthError(error);
  };

  const isValid = email && password;

  const error = errorEmailSignIn || googleOAuthError;

  const shouldDisplayError =
    error && error.message !== 'idpiframe_initialization_failed';

  useEffect(() => {
    if (!tracker) return;
    tracker.trackEvent(AppEvents.SignInView, { bu: product.name });
  }, []);

  return (
    <Stack
      onSubmit={performEmailSignIn}
      component='form'
      spacing={2.5}
      height='100%'
    >
      <Typography variant='h4' lineHeight={1.2} mb={2}>
        {/* Bem-vindo de volta! */}
      </Typography>
      <TextField
        data-cy='email'
        label='Seu email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        InputProps={{
          type: 'email',
          autoCapitalize: 'none',
        }}
      />
      <PasswordInput
        data-cy='password'
        label='Sua senha'
        value={password}
        onChange={setPassword}
      />
      <Box textAlign='right'>
        <Link href={routes.auth.forgotPassword}>Esqueci minha senha</Link>
      </Box>
      <Button
        data-cy='submit-login'
        fullWidth
        variant='contained'
        disabled={loadingEmailSignIn || !isValid}
        size='large'
        color='primary'
        type='submit'
      >
        Continuar
      </Button>
      <GoogleSignIn onError={displayGoogleErrorMessage} />

      {shouldDisplayError && <DisplayError error={error} />}

      <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-end'
        flexGrow={1}
      >
        <Divider sx={{ mb: { xs: 2, md: 4 } }} />
        <Typography variant='body2' align='center' mb={2}>
          Ainda não tem cadastro no {product.name}? Faça agora, é simples!
        </Typography>
        <Link href={routes.auth.signUp}>
          <Button variant='soft' color='primary' fullWidth>
            Fazer cadastro gratuito
          </Button>
        </Link>
      </Box>
    </Stack>
  );
};
