import { DialogContent, type SxProps } from '@mui/material';
import React, { type ReactNode } from 'react';

export interface IContentProps {
  sx?: SxProps;
  noVerticalPadding?: boolean;
  noHorizontalPadding?: boolean;
  children: ReactNode;
}

export const Content: React.FC<IContentProps> = ({
  sx,
  noVerticalPadding,
  noHorizontalPadding,
  children,
}) => {
  const verticalPadding = noVerticalPadding ? 0 : 2;
  const horizontalPadding = noHorizontalPadding ? 0 : 3;

  return (
    <DialogContent
      sx={{
        py: verticalPadding,
        px: horizontalPadding,
        '.MuiDialogTitle-root + &': {
          pt: verticalPadding,
        },
        scrollbarGutter: 'stable',
        ...sx,
      }}
    >
      {children}
    </DialogContent>
  );
};
