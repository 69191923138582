import { Alert, Typography } from '@mui/material';
import React from 'react';
import { type IDisplayCouponCodeProps } from './interface';

export const DisplayCouponCode: React.FC<IDisplayCouponCodeProps> = ({
  couponCode,
  isCouponActive,
  className,
}) => {
  const severity = isCouponActive ? 'success' : 'error';
  const message = isCouponActive
    ? `Cupom aplicado: ${couponCode}`
    : `Cupom ${couponCode} não disponível`;

  return (
    <Alert severity={severity} className={className} icon={false}>
      <Typography variant='body2'>{message}</Typography>
    </Alert>
  );
};
