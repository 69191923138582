import { gql, useLazyQuery } from '@apollo/client';
import {
  type IsCouponActiveQuery,
  type IsCouponActiveQueryVariables,
} from '../../__generated__/types';

export const useCheckCoupon = () => {
  const [checkCoupon, { data, loading, error }] = useLazyQuery<
    IsCouponActiveQuery,
    IsCouponActiveQueryVariables
  >(
    gql`
      query IsCouponActive($couponCode: String!, $target: InvestAIBU!) {
        isCouponActive(couponCode: $couponCode, target: $target) {
          code
          newBrokerAccountPrize
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    },
  );

  const coupon = data?.isCouponActive?.code;
  return {
    checkCoupon,
    isCouponActive: !!coupon,
    error,
    loading,
  };
};
