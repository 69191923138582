import { Box, Typography } from '@mui/material';
import { Modal } from '../../../components/Modal';
import { ExternalLinkDialogContext } from '../contexts/ExternalLinkDialogContext';

export const ExternalLinkDialog: React.FC = () => {
  const { isOpen, setIsOpen, externalLinkConfig } =
    ExternalLinkDialogContext.useContainer();

  const { path, description, title } = externalLinkConfig;

  const onConfirm = () => {
    window.open(path, '_blank');
    setIsOpen(false);
  };

  return (
    <Modal.Container open={isOpen} onClose={() => setIsOpen(false)} fullWidth>
      {title && <Modal.Header title={title} />}
      <Modal.Content>
        <Box minHeight={100} display='flex' alignItems='center'>
          <Typography variant='body1'>{description}</Typography>
        </Box>
      </Modal.Content>
      <Modal.Footer
        onConfirm={onConfirm}
        onCancel={() => setIsOpen(false)}
        cancelText='Cancelar'
      />
    </Modal.Container>
  );
};
