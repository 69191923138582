import logoImageSrc from './assets/logo.png';

export const product = {
  name: 'InvestAI',
  logo: {
    src: logoImageSrc,
    dimensions: {
      width: 97,
      height: 32,
    },
  },
};
