import { Typography } from '@mui/material';

export const Title: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Typography variant='h5' mt={4} mb={2}>
      {children}
    </Typography>
  );
};
