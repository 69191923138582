import React from 'react';
import type { IEventTracker } from '../../interfaces/IEventTracker';

export const EventTrackerContext = React.createContext<IEventTracker | null>(
  null,
);

interface IEventTrackerProviderProps {
  tracker: IEventTracker;
  children?: React.ReactNode;
}

export const EventTrackerProvider: React.FC<IEventTrackerProviderProps> = ({
  tracker,
  children,
}) => {
  return (
    <EventTrackerContext.Provider value={tracker}>
      {children}
    </EventTrackerContext.Provider>
  );
};
