import React from 'react';
import { RouteObject } from 'react-router-dom';
import { PATH_DASHBOARD } from '../modules/config/paths';

const MainPage = React.lazy(
  () => import('../modules/routes/pages/main/MainPage'),
);
const PurchasesPage = React.lazy(
  () => import('../modules/routes/pages/purchases/PurchasesPage'),
);
const FGCPage = React.lazy(() => import('../modules/routes/pages/fgc/FGCPage'));
const SimulatorPage = React.lazy(
  () => import('../modules/routes/pages/simulator/SimulatorPage'),
);
const InvoicesPage = React.lazy(
  () => import('../modules/routes/pages/invoices/InvoicesPage'),
);
const SettingsPage = React.lazy(
  () => import('../modules/routes/pages/settings/SettingsPage'),
);

const CouponAndMGMPage = React.lazy(
  () => import('../modules/routes/pages/mgm/CouponAndMGMPage'),
);

export const privateRoutes: RouteObject[] = [
  {
    path: PATH_DASHBOARD.app.main,
    element: (
      <React.Suspense>
        <MainPage />
      </React.Suspense>
    ),
  },
  {
    path: PATH_DASHBOARD.app.purchases,
    element: (
      <React.Suspense>
        <PurchasesPage />
      </React.Suspense>
    ),
  },
  {
    path: `${PATH_DASHBOARD.app.fgc}/*`,
    element: (
      <React.Suspense>
        <FGCPage />
      </React.Suspense>
    ),
  },
  {
    path: PATH_DASHBOARD.app.simulator,
    element: (
      <React.Suspense>
        <SimulatorPage />
      </React.Suspense>
    ),
  },
  {
    path: PATH_DASHBOARD.app.invoices,
    element: (
      <React.Suspense>
        <InvoicesPage />
      </React.Suspense>
    ),
  },
  {
    path: PATH_DASHBOARD.app.settings,
    element: (
      <React.Suspense>
        <SettingsPage />
      </React.Suspense>
    ),
  },
  {
    path: PATH_DASHBOARD.app.couponAndMgmRefer,
    element: (
      <React.Suspense>
        <CouponAndMGMPage />
      </React.Suspense>
    ),
  },
];
