import { Box, type BoxProps } from '@mui/material';
import { motion } from 'framer-motion';
//
import { varWrapEnter } from './variants';

interface MotionContainerProps extends BoxProps {
  initial?: boolean | string;
  open?: boolean;
}

export default function MotionContainer({
  open,
  children,
  ...other
}: MotionContainerProps) {
  const x: any = {
    component: motion.div,
    initial: false,
    animate: open ? 'animate' : 'exit',
    variants: varWrapEnter,
  };
  return (
    <Box {...x} {...other}>
      {children}
    </Box>
  );
}
