import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../..';
import { Logo } from '../../../components/Logo';
import { Paragraph } from '../Paragraph';
import { Title } from '../Title';

export const TermsOfService: React.FC = () => {
  const {
    product: { name: productName },
  } = useAppContext();
  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' minHeight={80} alignItems='center' mb={2}>
        <Link to='/'>
          <Logo width={120} />
        </Link>
      </Box>
      <Typography mb={3} variant='h2' fontWeight={600} alignSelf={'center'}>
        Termos de Uso
      </Typography>

      <Paragraph>
        Nós, do {productName}, consideramos que a relação com os nossos
        Usuários(as) deve ser baseada em confiança e respeito a fim de garantir
        a melhor experiência possível.
      </Paragraph>
      <Paragraph>
        Pensando nisso, criamos estes Termos de Uso, que determinarão os modos
        de se utilizar a plataforma do {productName}.
      </Paragraph>

      <Title>1. DEFINIÇÕES.</Title>
      <Paragraph>
        Para todos os fins e efeitos destes Termos de Uso, as seguintes
        expressões e termos definidos iniciados em letra maiúscula terão os
        significados indicados abaixo, no singular ou no plural:
      </Paragraph>

      <ul>
        <li>
          <Paragraph>
            "Cliente" significa qualquer pessoa natural que contrate e/ou
            utilize os serviços entregues pelo serviço;
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            "CheckUp Financeiro” significa um modelo criado e desenvolvido pelo
            {productName} para a realização da atividade de consultoria, modelo
            esse que inclui, além da demonstração gráfica, o acompanhamento das
            finanças desejadas e das finanças reais de uma pessoa natural;
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            "Informações de Clientes" significa, sem limitação, os dados
            pessoais de um cliente, suas informações de natureza financeira,
            patrimonial e familiar, bem como os planejamentos financeiros, os
            cálculos, relatórios, apresentações e demais serviços realizados
            para tal cliente;
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            "Informações do Usuário" tem o significado contido na Cláusula 3.1;
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            "Login": trata-se do e-mail, nome e CPF informados pelo usuário no
            ato do cadastro, e que será utilizado pelo usuário para acessar o
            aplicativo;
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            "Pessoa" significa qualquer pessoa, natural ou jurídica, de direito
            público ou privado, nacional ou estrangeira, bem como quaisquer
            entes desprovidos de personalidade jurídica, de natureza pública ou
            privada, incluindo qualquer autoridade, organizados de acordo com a
            legislação brasileira ou estrangeira;
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            "Consultoria" significa a atividade de formular estratégias para
            auxiliar pessoas naturais a gerenciarem seus assuntos financeiros
            para o fim de atingir seus objetivos de vida;
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            "Plano Contratado": significa o acesso à produtos e serviços
            {/* biome-ignore lint/suspicious/noSuspiciousSemicolonInJsx: <explanation> */}
            contratados com ao {productName};
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            "Aplicativo" significa uma plataforma digital, disponível na Apple
            Store e Google Play, plataforma web ou plataforma mobile e todas as
            funcionalidades a este relacionados, pertencente à AI INTERMEDIAÇÃO
            DE NEGÓCIOS SA., inscrita no CNPJ/MF sob nº CNPJ 39.800.252/0001-30;
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            "Propriedade Intelectual" significa todos e quaisquer bens de
            propriedade intelectual ou industrial, registrados ou não,
            inclusive, sem limitação: (a) direitos autorais (incluindo aqueles
            relacionados a software, aplicativos, programas de computador,
            código-fonte, manuais e etc.); (b) patentes, modelos de utilidade e
            invenções; (c) marcas de produto e serviço, nomes e denominações
            comerciais, nomes fantasia, marcas de certificação e coletivas,
            desenhos, logotipos, slogans; (d) domínios; (e) desenhos
            industriais; e (f) direitos sobre processos, knowhow, show-how,
            informações técnicas e comerciais, métodos, segredos de negócio e
            indústria;
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            "Propriedade Intelectual" significa todos e quaisquer bens de
            propriedade intelectual ou industrial, registrados ou não,
            inclusive, sem limitação: (a) direitos autorais (incluindo aqueles
            relacionados a software, aplicativos, programas de computador,
            código-fonte, manuais e etc.); (b) patentes, modelos de utilidade e
            invenções; (c) marcas de produto e serviço, nomes e denominações
            comerciais, nomes fantasia, marcas de certificação e coletivas,
            desenhos, logotipos, slogans; (d) domínios; (e) desenhos
            industriais; e (f) direitos sobre processos, knowhow, show-how,
            informações técnicas e comerciais, métodos, segredos de negócio e
            indústria;
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            "Senha": sequência de letras, números e caracteres escolhida pelo
            usuário, composta de no mínimo 6 caracteres, a qual deverá ser
            previamente informada pelo usuário quando do seu acesso ao
            aplicativo;
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            "Site": significa o site https://pass.invest-ai.com.br/;
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            "Usuário" significa qualquer pessoa natural ou jurídica, que utilize
            e/ou de qualquer forma acesse os serviços e ferramentas contidos no
            aplicativo, mediante o cadastro de um Login e Senha;
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            "Consultor" significa qualquer pessoa natural ou jurídica vinculada
            ao {productName} ou terceira, que utiliza a Prática e/ou dos
            serviços e ferramentas contidos no aplicativo para elaboração de
            trabalhos e prestação de serviços de Consultoria.
          </Paragraph>
        </li>
      </ul>

      <Title>2. SERVIÇO E ACEITAÇÃO.</Title>

      <Paragraph>
        2.1. Os presentes termos de uso contêm as regras para acesso e
        utilização do aplicativo pelos usuários ("Termos de Uso");
      </Paragraph>
      <Paragraph>
        2.2. Ao acessar o aplicativo, todos os usuários devem estar de acordo
        com os Termos de Uso e obedecer a todas as suas regras, de forma que a
        aceitação dos Termos de Uso é obrigatória para todos que pretenderem
        acessar o aplicativo e utilizar quaisquer de seus conteúdos e/ou
        serviços;
      </Paragraph>
      <Paragraph>
        2.3. Os Termos de Uso são a base jurídica e contratual para acesso e
        utilização, pelo usuário, do aplicativo, e dos produtos e serviços do
        {productName}, possuindo os termos e condições adicionais que se aplicam
        a cada Plano Contratado oferecido. Em caso de conflito entre qualquer
        disposição destes Termos de Uso e de um Plano Contratado, prevalecerá o
        quanto disposto no Plano Contratado somente naquilo que for contrário
        aos Termos de Uso, permanecendo as demais disposições aqui contidas em
        pleno vigor e efeito. 2.4. O acesso ao aplicativo, e/ou a utilização dos
        seus serviços e funcionalidades, poderá se dar por meio de dispositivos
        móveis, incluindo smartphones e tablets;
      </Paragraph>
      <Paragraph>
        2.4. Para que possa acessar determinados conteúdos ou serviços do
        aplicativo, o usuário poderá ser requisitado a fornecer informações em
        formulários ou cookies e a criar um cadastro conta, o que poderá se dar
        de forma gratuita ou onerosa, conforme informação disponível no
        aplicativo;
      </Paragraph>
      <Paragraph>
        2.5. Ao aceitar os Termos de Uso, o usuário reconhece e concorda que, em
        função do dinamismo que rege as relações na internet, o {productName}{' '}
        poderá, a qualquer momento e a seu exclusivo critério, modificar e/ou
        revisar os Termos de Uso, independentemente de comunicação, prévia ou
        posterior, ao usuário, de forma que, por meio da aceitação desta versão,
        o usuário manifesta sua aceitação também de novas versões atualizadas
        e/ou revisadas dos Termos de Uso, que estarão sempre disponíveis no
        aplicativo;
      </Paragraph>
      <Paragraph>
        2.5.1. Em vista do disposto no item 2.3 acima, o usuário concorda que os
        Termos de Uso permanecerão em plena força e vigor enquanto usuário do
        aplicativo, e que deverá acessar regularmente o aplicativo para
        verificar eventuais alterações implementadas nos Termos de Uso;
      </Paragraph>
      <Paragraph>
        2.6. Durante o processo de registro no aplicativo, o usuário criará uma
        Senha que habilitará seu acesso ao aplicativo. A senha deve ser pessoal
        e intransferível, concordando o usuário em manter a sua
        confidencialidade, ou seja, em não divulgar a senha a quaisquer outras
        pessoas, razão pela qual o usuário será o único responsável por qualquer
        ato, fato, infração, prejuízo e/ou dano resultante da não manutenção da
        confidencialidade, bem como de todos os acessos e atividades que
        ocorrerem por meio do uso da senha;
      </Paragraph>
      <Paragraph>
        2.6.1. O Usuário concorda em notificar imediatamente ao {productName}{' '}
        sobre qualquer acesso não autorizado de sua senha ou qualquer outra
        espécie de quebra de confidencialidade da senha, concordando que o{' '}
        {productName} não é responsável por qualquer ato, fato e dano que
        ocorram a partir do não cumprimento da cláusula 2.6 e seus subitens;
      </Paragraph>
      <Paragraph>
        2.7. Na Plataforma é feita oferta de produtos, serviços financeiros,
        serviços de orientação e educação financeira;
      </Paragraph>
      <Paragraph>
        2.8. Os conteúdos apresentados na Plataforma {productName} são meramente
        informativos, não podendo ser considerados como recomendações ou ofertas
        de qualquer natureza;
      </Paragraph>
      <Paragraph>
        2.9. Nenhum conteúdo provido pelo {productName} deve ser interpretado
        como conselho em qualquer estratégia de negócio, oferta, solicitação de
        compra ou venda de qualquer instrumento financeiro;
      </Paragraph>
      <Paragraph>
        2.10. O {productName} não se responsabiliza por qualquer tomada de
        decisão do Usuário a partir de sua utilização da Plataforma;
      </Paragraph>
      <Paragraph>
        2.11. A Plataforma tem como propósito oferecer produtos e serviços que
        fomentem a educação financeira, não possuindo o intuito de influenciar,
        de qualquer maneira, os Usuários(as) no processo de tomada de decisão de
        investimentos.
      </Paragraph>

      <Title>3. INFORMAÇÕES:</Title>

      <Paragraph>
        3.1. Durante a utilização do aplicativo, serão inseridas no aplicativo,
        pelo usuário, informações pertencentes ao usuário ("Informações do
        Usuário");
      </Paragraph>
      <Paragraph>
        3.1.1. O fornecimento de informações falsas, inexatas ou desatualizadas
        pelo usuário poderá ser causa para imediata interrupção do acesso ao
        {/* biome-ignore lint/suspicious/noSuspiciousSemicolonInJsx: <explanation> */}
        aplicativo, a critério exclusivo do {productName};
      </Paragraph>
      <Paragraph>
        3.2. O usuário é o único responsável pela veracidade e exatidão das
        informações que inserir no aplicativo, sendo o {productName} apenas
        receptora dessa informação;
      </Paragraph>
      <Paragraph>3.3. Os usuários têm ciência e estão de acordo que:</Paragraph>
      <Paragraph>
        (i) As Informações serão inseridas pelo próprio usuário; (ii) As
        Informações serão acessadas pelo cliente, pelo Consultor que estiver
        atendendo o cliente, bem como pelo {productName} e por seus sócios,
        administradores, empregados e/ou autorizados que somente tenham
        necessidade de conhecer as informações do cliente para os fins
        relacionados aos presentes Termos de Uso e/ou à contratação efetuada
        entre o Cliente e o Consultor e/ou ao {productName}.
      </Paragraph>
      <Paragraph>
        3.4. Para fins destes Termos de Uso, ficam definidas como confidenciais
        as Informações de clientes bem como aquelas informações criadas e
        desenvolvidas por Consultores e que estejam inseridas no aplicativo,
        exceto aquelas informações que (i) eram de domínio público no momento da
        sua inserção no aplicativo; (ii) no momento da sua inserção no
        aplicativo, já eram de conhecimento, por meios lícitos, do {productName}{' '}
        e/ou de seus sócios, administradores, empregados e/ou contratados; (iii)
        tenham sido obtidas de um terceiro não sujeito a dever de
        confidencialidade ("Informações Confidenciais");
      </Paragraph>
      <Paragraph>
        3.4.1. O {productName} assume a obrigação de manter sigilo e não
        divulgar, bem como a fazer com que seus respectivos sócios,
        administradores, empregados e contratados mantenham sigilo e não
        divulguem, as Informações Confidenciais;
      </Paragraph>
      <Paragraph>
        3.4.2. O usuário entende e concorda que o {productName} poderá divulgar
        as Informações Confidenciais se requerido por lei, por determinação
        judicial ou por autoridade competente;
      </Paragraph>
      <Paragraph>
        3.5. Ao considerar o uso do aplicativo, o usuário concorda que as
        informações de usuário e de cliente, ou quaisquer outras informações
        inseridas pelo usuário no aplicativo, não devem: (a) ser ilícitas; (b)
        infringir direitos de terceiros, inclusive de propriedade intelectual
        (seja direito autoral, de propriedade industrial, de softwares ou
        outros); (c) violar legislação, norma, contrato ou estatuto nacionais e
        internacionais; (d) ser abusivas, como textos, fotos ou imagens que
        tenham caráter discriminatório, difamatório, obsceno ou que contenha
        pornografia, e demais conteúdos congêneres; (e) violar a intimidade, a
        vida privada, a honra, a imagem, o nome ou quaisquer direitos de
        qualquer pessoa; (f) conter material publicitário, de propaganda ou
        promocional, de qualquer espécie; (g) provocar, por suas características
        (como extensões e formatos de arquivos) danos ao sistema que permita a
        utilização do aplicativo; (h) não devem ofender a moral ou a honra de
        qualquer pessoa, nem os bons costumes; (i) não devem conter vírus,
        cavalos de tróia, worms, time bombs, cancelbots, easter eggs ou outras
        rotinas de programação que possam danificar, interferir, interceptar ou
        desapropriar qualquer sistema, dado ou informação do {productName}, do
        aplicativo e de outros usuários; (j) não devem criar links direta ou
        indiretamente para qualquer material que o usuário não tenha direito de
        incluir ou linkar;
      </Paragraph>
      <Paragraph>
        3.5.1. O usuário está ciente de que a infração à Cláusula 3.5. acima,
        além de facultar ao {productName} a imediata interrupção do acesso ao
        aplicativo e término, sujeitará o usuário à indenização pelas perdas e
        danos havidos em consequência da infração;
      </Paragraph>
      <Paragraph>
        3.6. O acesso ao aplicativo, bem como estes Termos de Uso, está sujeito
        à Política de Privacidade do {productName} que pode ser encontrada no
        aplicativo;
      </Paragraph>
      <Paragraph>
        3.7. O {productName} se reserva o direito, a seu exclusivo critério, de
        suspender ou terminar o uso do aplicativo pelo usuário, a qualquer
        momento e sem aviso prévio, obedecidas eventuais disposições específicas
        contidas nos Termos de Uso.
      </Paragraph>

      <Title>4. CONDIÇÕES GERAIS DE USO DOS SERVIÇOS</Title>

      <Paragraph>
        4.1. O usuário concorda que o aplicativo se destina a uso pessoal, e que
        ninguém além do usuário usará o aplicativo utilizando o login e senha do
        usuário;
      </Paragraph>
      <Paragraph>
        4.1.1. O usuário não deve copiar, distribuir, modificar, vender,
        revender ou retransmitir qualquer parte do conteúdo do aplicativo, da
        Propriedade Intelectual ou dos serviços relacionados, incluindo, mas não
        se limitando, a qualquer texto, imagem ou áudio, para qualquer
        finalidade que não seja seu uso pessoal;
      </Paragraph>
      <Paragraph>
        4.2. A utilização do aplicativo pelo usuário, ainda que mediante
        pagamento, não constitui qualquer espécie de licença ou cessão, e o
        usuário não tem direito de copiar ou reproduzir, no todo ou em parte,
        qualquer parte do aplicativo, suas funcionalidades, ferramentas e/ou
        serviços;
      </Paragraph>
      <Paragraph>
        4.3. O {productName} poderá modificar o aplicativo, em qualquer aspecto,
        a qualquer momento, podendo, inclusive, excluir serviços e
        funcionalidades existentes no momento do registro do usuário, alterar
        layouts, configurações e quaisquer outros aspectos, sem necessidade de
        aprovações ou aviso prévio ou posterior dos usuários;
      </Paragraph>
      <Paragraph>
        4.4. O usuário é o único responsável pela obtenção, pagamento e
        manutenção de todos os serviços de telefonia, acesso à internet, plano
        de dados, tarifas, taxas, mensalidades e outros custos associados ao
        acesso e uso do aplicativo, bem como pelo software, hardware de
        computador e outros equipamentos necessários para tal uso e acesso;
      </Paragraph>
      <Paragraph>
        4.5. Para aderir à Plataforma você deve ter mais de 18 anos e ser
        empregado de uma empresa conveniada. Você é responsável pela veracidade,
        validade e precisão das informações fornecidas quando do seu cadastro e
        deve mantê-lo atualizado sempre que alguma informação mudar ou quando
        solicitado.
      </Paragraph>

      <Title>5. PAGAMENTO</Title>

      <Paragraph>
        5.1. As condições de pagamento de produtos e serviços disponibilizados
        pelo aplicativo, Plano Contratado, estão especificadas em cada plano.
      </Paragraph>

      <Title>6. USO DA PLATAFORMA</Title>

      <Paragraph>
        6.1. O usuário está ciente e concorda de que o {productName} poderá
        estabelecer práticas e limites gerais no que diz respeito ao uso do
        aplicativo a qualquer momento;
      </Paragraph>
      <Paragraph>
        6.2. O aplicativo é uma ferramenta para realização de Consultoria,
        educação financeira, automação de investimentos e não se constitui, de
        nenhuma forma, um servidor, ferramenta, software ou funcionalidade para
        armazenamento e arquivamento de dados e informações do e pelo usuário.
        Dessa forma, o usuário deverá manter seus próprios arquivos de
        informações e dados, seja das suas próprias Informações do Usuário, das
        Informações de clientes e/ou de quaisquer dados e informações que possam
        vir a ser fornecidos pelo {productName} e/ou pelo Consultor financeiro
        para o usuário, tais como esquemas, planejamentos realizados,
        relatórios, reports e outros;
      </Paragraph>
      <Paragraph>
        6.2.1. Em virtude das disposições contidas no item 6.2. acima, o usuário
        concorda que o {productName} não será responsável pelo armazenamento de
        quaisquer das espécies de informações mencionadas no item 6.2., bem como
        não será responsabilizada, de qualquer forma, pelo apagamento, perda ou
        falha de quaisquer dessas informações;
      </Paragraph>
      <Paragraph>
        6.2.2. O {productName} reitera seu direito de remover quaisquer
        informações ou outro material que viole esses Termos de Uso;
      </Paragraph>
      <Paragraph>
        6.3. O Usuário concorda que não irá: (a) fazer upload, enviar por e-mail
        ou transmitir de qualquer outra forma rotinas de programação, arquivos
        ou programas com a intenção de interromper, destruir ou limitar as
        funcionalidades de qualquer software ou hardware ou equipamento de
        telecomunicação; (b) interferir com ou perturbar o aplicativo ou as
        redes conectadas site, por meio do uso do aplicativo, ou descumprir
        quaisquer requerimentos, procedimentos, políticas ou regulamentos das
        redes conectadas ao aplicativo, ou de alguma outra maneira interferir
        com o aplicativo em qualquer sentido, incluindo por meio do uso de
        códigos de programação; (c) agir de qualquer maneira que imponha uma
        carga excessivamente pesada, que seja desproporcional ou não razoável,
        na infraestrutura do {productName} e do aplicativo; (d) criar trabalhos
        derivados do aplicativo; (e) compartilhar, transmitir, enviar,
        disponibilizar, exibir ou publicar no aplicativo qualquer conteúdo que
        consista em propriedade intelectual de terceiros ou que estejam
        protegido por direitos autorais, por segredo de negócio ou por qualquer
        outra forma de proteção de direitos de titularidade de terceiros; (f)
        compartilhar, transmitir, enviar, disponibilizar, exibir ou publicar no
        aplicativo qualquer conteúdo vedado; (g) não realizar o download de
        qualquer conteúdo; (h) não reproduzir, distribuir, transmitir, exibir,
        vender, licenciar ou explorar comercialmente qualquer conteúdo; (i) não
        fraudar, desativar ou de qualquer forma interferir em características
        relacionadas à segurança do aplicativo e dos conteúdos;
      </Paragraph>
      <Paragraph>
        6.4. O sistema da InvestAI de investimentos automatizados pode possuir
        outros termos de uso e política de privacidade, sendo apresentado para o
        usuário antes da utilização do serviço.
      </Paragraph>

      <Title>7. COMUNICADOS</Title>

      <Paragraph>
        7.1. O usuário concorda que o {productName} poderá lhe enviar
        comunicados e newsletters, bem como ofertas de bens e serviços do{' '}
        {productName} que possam beneficiar o usuário, e que esses comunicados
        são considerados parte de nosso aplicativo.
      </Paragraph>

      <Title>8. GARANTIAS E RESPONSABILIDADES</Title>

      <Paragraph>
        8.1. O {productName} empregará seus maiores esforços para assegurar que
        tudo o quanto disponibilizado no aplicativo seja tão atualizado, preciso
        e completo quanto possível. O {productName}, entretanto, não garante que
        o acesso ao aplicativo seja livre de erros ou problemas, internos ou
        externos, ou por casos não sujeitos ao controle do {productName}. Dessa
        forma, o usuário entende que o aplicativo é oferecido "como é" e "como
        está disponível", de forma que o {productName} não oferece garantias
        relacionadas à performance do aplicativo, e em nenhuma circunstância,
        poderá o {productName} ser responsabilizada quanto à integridade,
        atualidade e exatidão das informações e Conteúdo do aplicativo;
      </Paragraph>
      <Paragraph>
        8.1.1. Em virtude do disposto no item 8.1. acima, o {productName} não
        assume qualquer obrigação ou responsabilidade por quaisquer (a) danos
        materiais, patrimoniais ou morais, diretos ou indiretos, incluindo, sem
        limitação, lucros cessantes, resultantes do acesso e do uso do
        aplicativo; (b) qualquer interrupção de acesso ao aplicativo; (c)
        qualquer vírus ou dispositivo malicioso (e congêneres) que possa atacar
        o equipamento do usuário em decorrência do acesso, utilização ou
        navegação no aplicativo ou como consequência do download, utilização ou
        acesso a qualquer conteúdo do aplicativo;
      </Paragraph>
      <Paragraph>
        8.2. O {productName} não é responsável pelos, assim como não garante os
        serviços prestados por ou recebidos de terceiros, incluindo Consultores,
        por meio do aplicativo, bem como por qualquer informação ou conselho
        recebido por meio do aplicativo;
      </Paragraph>
      <Paragraph>
        8.3. O usuário concorda que, em nenhuma circunstância, o {productName}{' '}
        será responsável por qualquer dano, direto ou indireto, lucros
        cessantes, perda de uma chance e outras perdas e danos intangíveis,
        relacionado ao uso do aplicativo e de seus produtos, serviços e
        ferramentas, nem em relação à incapacidade e/ou impossibilidade de
        utilizá-los.
      </Paragraph>

      <Title>9. INTERRUPÇÃO, RESCISÃO, TÉRMINO</Title>

      <Paragraph>
        9.1. O usuário concorda que o {productName}, a seu exclusivo critério,
        poderá interromper temporariamente, indefinidamente ou definitivamente,
        o acesso ao aplicativo, seja por razão fundamentada (tais como por falta
        de uso, violação dos Termos de Uso, impossibilidade de verificar ou
        autenticar informação fornecida pelo usuário), ou mesmo sem necessidade
        de causa ou fundamentação;
      </Paragraph>
      <Paragraph>
        9.2. O usuário declara-se ciente de que o encerramento da sua conta ou
        do acesso total ou parcial ao aplicativo poderá ser efetuado sem aviso
        prévio, concordando que o {productName} não poderá ser responsabilizada
        por perda de informações decorrentes do encerramento. Dessa forma, o
        usuário reitera seu conhecimento e obrigação de manter seus próprios
        arquivos de informações e dados, seja das suas próprias Informações do
        usuário, das informações de clientes e/ou de quaisquer dados e
        informações que possam vir a ser fornecidos pelo {productName} e/ou pelo
        Consultor para o usuário, tais como esquemas, planejamentos realizados,
        relatórios, reports e outros.
      </Paragraph>

      <Title>10. PROPRIEDADE INTELECTUAL</Title>

      <Paragraph>
        10.1. O conteúdo do aplicativo, assim como toda a Propriedade
        Intelectual do {productName}, contida ou não no aplicativo, bem como
        outros itens - incluindo, sem limitação, como imagens, fotografias,
        animações, vídeos, áudios, sons, músicas, textos, layouts, "look and
        feel", gráficos, combinações audiovisuais, recursos interativos,
        comentários, propagandas, anúncios publicitários, constantes do
        aplicativo ("Conteúdo"), são protegidos por leis e tratados de direitos
        autorais e de propriedade industrial e intelectual nacionais e
        internacionais, de forma que é vedado ao usuário fazer cópias do
        Conteúdo, bem como utilizar ou exibir o Conteúdo (exceto para as
        finalidades do Plano Contratado) sem a expressa e específica autorização
        por escrito do
        {/* biome-ignore lint/suspicious/noSuspiciousSemicolonInJsx: <explanation> */}
        {productName};
      </Paragraph>
      <Paragraph>
        10.1.1. O usuário concorda em cumprir com todas as leis de proteção de
        direitos autorais e de propriedade industrial e intelectual,
        reservando-se ao {productName} o direito de tomar as medidas que julgar
        apropriadas, a seu exclusivo critério, a fim de proteger os direitos de
        propriedade intelectual sobre o Conteúdo, sem prejuízo de outras
        disposições contidas nos Termos de Uso;
      </Paragraph>
      <Paragraph>
        10.1.2. É vedado ainda, ao usuário, reconfigurar, desmontar ou fazer
        engenharia reversa do aplicativo, do Conteúdo e de quaisquer outras
        ferramentas e serviços constantes no aplicativo.
      </Paragraph>

      <Title>11. PROIBIÇÃO DE REVENDA</Title>

      <Paragraph>
        11.1. O usuário concorda em não reproduzir, duplicar, copiar, vender,
        revender ou explorar com quaisquer fins comerciais, qualquer porção do
        aplicativo, da Propriedade Intelectual e do Conteúdo para o fim de
        revenda de bens ou serviços, ou a promoção de uma empresa, bem ou
        serviço.
      </Paragraph>

      <Title>12. DISPOSIÇÕES GERAIS</Title>

      <Paragraph>
        12.1. Os Termos de Uso, juntamente com a Política de Privacidade,
        publicados no aplicativo, constituem o acordo integral entre o
        {productName} e os usuários em relação ao objeto em questão, e
        substituem todos os acordos anteriores, escritos ou verbais;
      </Paragraph>
      <Paragraph>
        12.2. O {productName} e os usuários são partes contratantes
        independentes, não resultando da utilização do aplicativo qualquer
        sociedade, franquia, representação ou relação que não as expressamente
        previstas nos Termos de Uso;
      </Paragraph>
      <Paragraph>
        12.3. Caso qualquer uma das cláusulas e condições dos Termos de Uso
        venha a ser declarada nula, no todo ou em parte, por qualquer motivo
        legal ou contratual, as demais cláusulas continuarão em pleno vigor e
        efeito;
      </Paragraph>
      <Paragraph>
        12.4. A tolerância ou o não exercício, por qualquer das partes, de
        quaisquer direitos assegurados nos Termos de Uso ou na lei não importará
        em novação ou em renúncia, mantendo a referida parte o direito de
        exercê-los;
      </Paragraph>
      <Paragraph>
        12.5. As estimativas presentes neste aplicativo são intrinsecamente
        sujeitas a incertezas e diversos eventos ou fatores que estão além do
        controle do {productName}, especialmente aqueles cuja ocorrência depende
        de eventos futuros e incertos. Os cálculos do CheckUp Financeiro e dos
        Objetivos são estimativas que não representam nenhum compromisso com a
        realidade e não devem ser considerados como uma oferta, convite ou
        recomendação para participação em oferta de valores mobiliários ou
        ativos;
      </Paragraph>
      <Paragraph>
        12.6. Fica eleito o foro da Comarca de São Paulo, Estado de São Paulo,
        com exclusão de qualquer outro, por mais privilegiado que seja, para
        dirimir quaisquer litígios ou controvérsias oriundas dos presentes
        Termos de Uso.
      </Paragraph>
    </Box>
  );
};
