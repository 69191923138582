import { Page } from '@invest-ai/animatrix';
import { SignUpForm } from '../../components/SignUp';

export const SignUpPage: React.FC = () => {
  return (
    <Page title={'InvestAI | Cadastrar'}>
      <SignUpForm />
    </Page>
  );
};
