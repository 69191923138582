import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPhoneWithoutCountryCode } from '../../logic/getPhoneWithoutCountryCode';
import { parseBase64ToJson } from '../../logic/parseBase64ToJson';

export const useSignUpFormState = () => {
  const [searchParams] = useSearchParams();
  const formDataFromQuery = searchParams.get('form_data');
  const formData = parseBase64ToJson(formDataFromQuery);
  const [email, setEmail] = useState(formData?.email || '');
  const [cpf, setCpf] = useState(formData?.cpf || '');

  const [name, setName] = useState(formData?.name || '');
  const [phone, setPhone] = useState(
    getPhoneWithoutCountryCode(formData?.phone) || '',
  );
  const [password, setPassword] = useState('');
  const [isCouponActive, setIsCouponActive] = useState<boolean>(false);

  return {
    email,
    setEmail,
    name,
    setName,
    phone,
    setPhone,
    cpf,
    setCpf,
    password,
    setPassword,
    isCouponActive,
    setIsCouponActive,
    prefilledFormData: formData,
  };
};
