import { Page, useAppContext } from '@invest-ai/animatrix';
import { Container } from '@mui/system';
import { TermsOfService } from '../../components/TermsOfService';

export const TermsOfUsePage: React.FC = () => {
  const { product } = useAppContext();

  return (
    <Page title={`${product.name} | Termos de Uso`}>
      <Container maxWidth={'lg'}>
        <TermsOfService />
      </Container>
    </Page>
  );
};
