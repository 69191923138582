import { Box, type BoxProps } from '@mui/material';
import { type ReactNode, forwardRef } from 'react';
import { Helmet } from 'react-helmet';

interface PageProps extends BoxProps {
  children: ReactNode;
  title?: string;
}

type HelmetProps = React.ComponentProps<typeof Helmet>;

const HelmetWithChildren: React.FC<
  HelmetProps & { children?: React.ReactNode }
> = ({ children, ...rest }) => <Helmet {...rest}>{children}</Helmet>;

export const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title = '', ...other }: PageProps, ref) => (
    <Box ref={ref} {...other}>
      <HelmetWithChildren>
        <title>{title}</title>
      </HelmetWithChildren>
      {children}
    </Box>
  ),
);
