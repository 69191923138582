import { gql, useMutation } from '@apollo/client';
import type {
  SendResetPasswordMutationMutation,
  SendResetPasswordMutationMutationVariables,
} from '../../__generated__/types';

export const SEND_RESET_PASSWORD_MUTATION = gql`
  mutation SendResetPasswordMutation($email: String!) {
    sendResetPasswordEmail(email: $email)
  }
`;

export const useSendResetPassword = () => {
  const [_sendResetPassword, { data, loading, error }] = useMutation<
    SendResetPasswordMutationMutation,
    SendResetPasswordMutationMutationVariables
  >(SEND_RESET_PASSWORD_MUTATION);

  const sendResetPassword = (email: string) =>
    _sendResetPassword({
      variables: {
        email,
      },
    });
  return {
    sendResetPassword,
    loading,
    error,
    data,
  };
};
