import { IAppContextLayout } from '@invest-ai/animatrix/src/app/context/IAppContext';
import signInBackgroundImage from './assets/signin_bg.png';
import signUpBackgroundImage from './assets/signup_bg.png';

export const layout: IAppContextLayout = {
  signUp: {
    image: {
      src: signUpBackgroundImage,
      dimensions: { width: 442, height: 377 },
    },
    header: {
      text: 'Você está a poucos passos de elevar',
      highlight: 'o patamar dos seus investimentos',
    },
    title: 'Plataforma 100% segura',
    subtitle:
      'A plataforma conta com tecnologia e criptografia de ponta. A investAI também segue todos os protocolos e requisitos da LGPD e é auditada frequentemente.',
  },
  signIn: {
    image: {
      src: signInBackgroundImage,
      dimensions: {
        width: 423,
        height: 361,
      },
    },
    title: 'Simples, prático e rápido',
    subtitle:
      'Invista nos melhores CDBs, LCIs e LCAs que rendem até 300% da poupança e totalmente segurados pelo FGC.',
  },
};
