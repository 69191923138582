import { useAppContext } from '../../..';
import { LoginLayoutV2 } from '../LoginLayoutV2';
import { SideSection } from '../SideSection';

export const SignUpLayout = () => {
  const {
    layout: {
      signUp: { image, subtitle, title },
    },
  } = useAppContext();
  return (
    <LoginLayoutV2
      section={
        <SideSection
          image={image?.src}
          imageDimensions={image?.dimensions}
          title={title}
          subtitle={subtitle}
        />
      }
    />
  );
};
