import { AccountView, useAppContext } from '@invest-ai/animatrix';

export const AccountPage: React.FC = () => {
  return <AccountView />;
};

export const useAccountRoutes = () => {
  const { defaultRouteNames: routes } = useAppContext();

  return [
    {
      path: routes.myAccount.root,
      element: <AccountPage />,
    },
  ];
};
