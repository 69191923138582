import {
  Button,
  DisplayError,
  TextField,
  useAppContext,
} from '@invest-ai/animatrix';
import { Link } from '@mui/material';
import Alert from '@mui/material/Alert';
import { type FormEventHandler, useState } from 'react';
import { useSendResetPassword } from '../../hooks/useSendResetPassword';
import { Title } from '../Title';
import { Subtitle } from './Subtitle';
import { Form, classes } from './styles';

export const ForgotPassword = () => {
  const { defaultRouteNames: routes } = useAppContext();
  const { sendResetPassword, data, loading, error } = useSendResetPassword();
  const [email, setEmail] = useState('');
  const valid = !!email;

  const performResetPassword: FormEventHandler = async (e) => {
    e.preventDefault();
    await sendResetPassword(email);
    setEmail('');
  };

  return (
    <Form onSubmit={performResetPassword}>
      <Title>Recuperar senha</Title>
      <Subtitle>
        Digite o seu email e enviaremos um link para recuperar a senha do email.
      </Subtitle>
      <TextField
        label='E-mail'
        variant='outlined'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        InputProps={{ type: 'email', autoCapitalize: 'none' }}
        InputLabelProps={{ shrink: true }}
        className={classes.email}
      />
      <DisplayError error={error} className={classes.message} />
      {data && (
        <Alert severity='success' className={classes.message}>
          Enviamos um e-mail para você com as instruções para resetar a senha.
        </Alert>
      )}
      <Button
        variant='contained'
        color='primary'
        fullWidth
        disabled={loading || !valid}
        size='large'
        type='submit'
      >
        Enviar
      </Button>
      <Link href={routes.auth.signIn} className={classes.link}>
        Voltar ao Login
      </Link>
    </Form>
  );
};
