import merge from 'lodash.merge';
import { useContext } from 'react';
import { FakeChatWidget } from '../../chat/services/FakeChatWidget';
import { DEFAULT_IGNORED_ERRORS } from '../../components/DisplayError/DisplayError';
import { defaultRoutePaths } from '../constants/defaultRoutePaths';
import { AppContext } from './AppContext';
import type { IAppContext } from './IAppContext';

const legalInfo = {
  companyName: 'InvestAI',
  cnpj: '39.800.252/0001-30',
};

const DEFAULTS: Pick<
  IAppContext,
  'defaultRouteNames' | 'legalInfo' | 'chatWidget' | 'ignoredErrorCodes'
> = {
  defaultRouteNames: defaultRoutePaths,
  legalInfo,
  chatWidget: new FakeChatWidget(),
  ignoredErrorCodes: DEFAULT_IGNORED_ERRORS,
};

export const useAppContext = (): IAppContext => {
  const ctx = useContext(AppContext);
  if (!ctx) {
    throw new Error('no context found for App');
  }
  return merge(DEFAULTS, ctx);
};
