import { createContainer } from 'unstated-next';
import useLocalStorageState from 'use-local-storage-state';

const NAVBAR_OPEN = 'NAVBAR_OPEN';

export const useNavbar = () => {
  const [isOpen, setIsOpen] = useLocalStorageState<boolean>(NAVBAR_OPEN, {
    defaultValue: true,
  });
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);
  const toggle = () => setIsOpen((s) => !s);

  return {
    isOpen,
    close,
    open,
    toggle,
  };
};

export const NavBarContext = createContainer(useNavbar);
