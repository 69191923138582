import makeStyles from '@mui/styles/makeStyles';
import { Color } from '../../../theme/Color';

export const useStyles = makeStyles(() => ({
  slider: {
    transition: 'color 1s ease-in',
  },
  markLabel: {
    fontSize: 12,
    color: '#FFF',
  },
  markLabelActive: {
    color: '#FFF',
  },
  thumb: ({ borderColor }: { borderColor?: string }) => ({
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: borderColor ?? Color.PRIMARY,
    backgroundColor: '#000',
    transition: 'border-color 1s ease-in',
  }),
}));
