import Box, { type BoxProps } from '@mui/material/Box';
import { useResponsive } from '../../../hooks/useResponsive';
import { HEADER, NAV } from '../constants/config-layout';

const SPACING = 8;

export function OutletWrapper({ children, sx, ...other }: BoxProps) {
  const lgUp = useResponsive('up', 'md');

  const isNavMini = false;

  return (
    <Box
      component='main'
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini
            ? {
                width: `calc(100% - ${NAV.W_MINI}px)`,
              }
            : null),
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}
