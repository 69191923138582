import Bugsnag from '@bugsnag/js';
import { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './global.css';
import NeoApp from './modules/app/NeoApp';

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(
  React as any,
) as BugsnagErrorBoundary;

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <NeoApp />
    </ErrorBoundary>
  </React.StrictMode>,
);
