import React, { type PropsWithChildren } from 'react';
import { AppContext, type AppContextProps } from './AppContext';

export type IAppContextProviderProps = PropsWithChildren<AppContextProps>;
export const AppContextProvider: React.FC<IAppContextProviderProps> = ({
  children,
  ...value
}) => {
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
