import { useAppContext } from '@invest-ai/animatrix';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export const TermsAgreement: React.FC = () => {
  const { defaultRouteNames: routes } = useAppContext();

  return (
    <Typography
      component='div'
      sx={{
        typography: 'caption',
        color: 'text.secondary',
      }}
    >
      {'Ao cadastrar você está de acordo com os '}
      <Link
        href={routes.common.termsOfUse}
        underline='always'
        color='primary.main'
        sx={{ cursor: 'pointer' }}
      >
        termos de uso
      </Link>
      {' e '}
      <Link
        href={routes.common.privacyPolicy}
        underline='always'
        color='primary.main'
        sx={{ cursor: 'pointer' }}
      >
        políticas de privacidade
      </Link>
      .
    </Typography>
  );
};
