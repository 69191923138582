import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../..';
import { useTokens } from '../../hooks/useTokens';

export const Logout: React.FC = () => {
  const {
    defaultRouteNames: { auth },
  } = useAppContext();
  const { deleteToken } = useTokens();
  const client = useApolloClient();
  const navigate = useNavigate();
  const goToLoginPage = () => navigate(auth.signIn);

  useEffect(() => {
    deleteToken();
    client
      .resetStore()
      .then(() => {
        goToLoginPage();
      })
      .catch(() => {
        goToLoginPage();
      });
  }, []);

  return null;
};
