import {
  Dialog,
  type DialogProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { type CSSProperties, type ReactNode, useEffect } from 'react';
import { ModalContext } from '../../context/Modal.context';

export interface IContainerProps extends Pick<DialogProps, 'sx'> {
  onClose?: () => void;
  open?: boolean;
  loading?: boolean;
  variant?: 'dialog';
  fullHeight?: boolean;
  fullWidth?: boolean;
  unmountChildren?: boolean;
  children: ReactNode;
}

export const Container: React.FC<IContainerProps> = ({
  open = false,
  onClose,
  unmountChildren,
  fullHeight,
  fullWidth,
  variant,
  loading,
  sx,
  children,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const fullScreen = isMobile && variant !== 'dialog';
  const desktopStyleProps: CSSProperties = {
    maxWidth: 560,
    maxHeight: 720,
  };

  useEffect(() => {
    if (!isMobile || !open) {
      return;
    }
    const element = document.getElementById('wa-widget-send-button');
    if (element) {
      element.style.transform = 'translateY(-60px)';
    }
    return () => {
      if (element) {
        element.style.transform = '';
      }
    };
  }, [open]);

  const contextState = {
    initialState: {
      variant,
      onClose,
      loading,
    },
  };

  if (!open && unmountChildren) {
    return null;
  }

  return (
    <ModalContext.Provider initialState={contextState}>
      <Dialog
        {...props}
        open={open}
        onClose={onClose}
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        PaperProps={{
          sx: {
            ...(fullHeight && { height: '100%' }),
            ...(!isMobile && desktopStyleProps),
            ...sx,
          },
        }}
      >
        {children}
      </Dialog>
    </ModalContext.Provider>
  );
};
