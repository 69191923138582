import { Page, useAppContext } from '@invest-ai/animatrix';
import { Container } from '@mui/system';
import { PrivacyPolicy } from '../../components/PrivacyPolicy';

export const PrivacyPolicyPage: React.FC = () => {
  const { product } = useAppContext();
  return (
    <Page title={`${product.name} | Política de Privacidade`}>
      <Container maxWidth={'lg'}>
        <PrivacyPolicy />
      </Container>
    </Page>
  );
};
