export const useTokens = () => {
  const setToken = (token: string) => {
    localStorage.setItem('token', token);
  };
  const deleteToken = () => {
    localStorage.removeItem('token');
  };
  const getToken = () => {
    return localStorage.getItem('token');
  };

  return {
    setToken,
    deleteToken,
    getToken,
  };
};
