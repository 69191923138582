import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePasswordMutation($oldPassword: String!, $newPassword: String!) {
  updatePassword(oldPassword: $oldPassword, newPassword: $newPassword)
}
`;

export const useUpdatePassword = () => {
  const [_updatePassword, { data, loading }] = useMutation<any>(
    UPDATE_PASSWORD_MUTATION,
  );

  const updatePassword = (oldPassword: string, newPassword: string) =>
    _updatePassword({
      variables: {
        oldPassword,
        newPassword,
      },
    }).then((response) => response.data.updatePassword);

  return {
    updatePassword,
    data,
    loading,
  };
};
