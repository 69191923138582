import { ApolloProvider } from '@apollo/client';
import {
  AppContextProvider,
  ThemeProvider,
  useAppContext,
} from '@invest-ai/animatrix';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { type ConfirmOptions, ConfirmProvider } from 'material-ui-confirm';
import type { PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ErrorTracker } from '../../bugsnag/ErrorTracker';
import { ChatWidgetProvider } from '../../chat/components/ChatWidgetProvider';
import type { IAppContextProviderProps } from '../context/AppContextProvider';
import { AnimatrixRouter } from './AnimatrixRouter';
import { RenderTrackerOrFakeTracker } from './RenderTrackerOrFakeTracker';

export const CONFIRM_PROVIDER_DEFAULT_OPTIONS: ConfirmOptions = {
  title: '',
  confirmationText: 'Sim',
  cancellationText: 'Cancelar',
};

const AnimatrixAppInner: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    chatWidget,
    tracker,
    googleClientId,
    apolloClient,
    mode,
    errorTracker,
    customPalette,
  } = useAppContext();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ErrorTracker.Provider initialState={{ client: errorTracker }}>
        <GoogleOAuthProvider clientId={googleClientId}>
          <ApolloProvider client={apolloClient}>
            <ChatWidgetProvider widget={chatWidget}>
              <RenderTrackerOrFakeTracker tracker={tracker}>
                <BrowserRouter>
                  <ThemeProvider mode={mode} customPalette={customPalette}>
                    <ConfirmProvider
                      defaultOptions={CONFIRM_PROVIDER_DEFAULT_OPTIONS}
                    >
                      {children ?? <AnimatrixRouter />}
                    </ConfirmProvider>
                    <ToastContainer position='bottom-left' pauseOnHover />
                  </ThemeProvider>
                </BrowserRouter>
              </RenderTrackerOrFakeTracker>
            </ChatWidgetProvider>
          </ApolloProvider>
        </GoogleOAuthProvider>
      </ErrorTracker.Provider>
    </LocalizationProvider>
  );
};

export const AnimatrixApp: React.FC<IAppContextProviderProps> = ({
  children,
  ...props
}) => {
  return (
    <AppContextProvider {...props}>
      <AnimatrixAppInner>{children}</AnimatrixAppInner>
    </AppContextProvider>
  );
};
