import { RouterLink, useAppContext } from '@invest-ai/animatrix';
import { useTheme } from '@mui/material';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useIsUrlFromTC } from '../../hooks/useIsUrlFromTC';
import { PartnerSection } from './PartnerSection';

export const SignUpHeader: React.FC = () => {
  const theme = useTheme();
  const {
    defaultRouteNames,
    layout: {
      signUp: { header },
    },
  } = useAppContext();
  const isTcUrl = useIsUrlFromTC();

  // TODO: Turn header definition mandatory on app usage
  const text = header?.text ?? 'Você está a poucos passos de uma';
  const highlight =
    header?.highlight ?? 'nova experiência em gestão de carteira';

  return (
    <Stack spacing={2} sx={{ mb: { xs: 3, md: 4 }, position: 'relative' }}>
      <Typography variant='h4' lineHeight={1.2}>
        {text}{' '}
        <span style={{ color: theme.palette.primary.main }}>{highlight}</span>
      </Typography>

      <Stack direction='row' spacing={0.5}>
        <Typography variant='body2'>Já possui uma conta?</Typography>

        <Link
          href={defaultRouteNames.auth.signIn}
          component={RouterLink}
          variant='subtitle2'
          color='primary.dark'
          sx={{ cursor: 'pointer' }}
        >
          Entre aqui.
        </Link>
      </Stack>
      {isTcUrl && <PartnerSection mb={3} />}
    </Stack>
  );
};
