import type { IChatWidget } from '../interfaces/IChatWidget';

export class FakeChatWidget implements IChatWidget {
  hide(): void {
    // noop
  }
  show(): void {
    // noop
  }
}
