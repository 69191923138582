import {
  LogoOnlyLayout,
  Page404,
  PrivacyPolicyPage,
  TermsOfUsePage,
  useAppContext,
} from '@invest-ai/animatrix';
import { Navigate } from 'react-router-dom';

export const useCommonRoutes = () => {
  const { defaultRouteNames: routes } = useAppContext();
  return [
    {
      element: <LogoOnlyLayout />,
      children: [{ path: routes.common.notFound, element: <Page404 /> }],
    },
    { path: routes.common.termsOfUse, element: <TermsOfUsePage /> },
    { path: routes.common.privacyPolicy, element: <PrivacyPolicyPage /> },

    { path: '*', element: <Navigate to={routes.common.notFound} replace /> },
  ];
};
