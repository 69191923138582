import { yupResolver } from '@hookform/resolvers/yup';
import { sleep } from '@invest-ai/common';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import { useConfirm } from 'material-ui-confirm';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { FormTextField, Image, Typography } from '../..';
import { FormProvider } from '../../form/components/FormProvider';
import { useRouter } from '../../hooks/useRouter';
import { useUser } from '../hooks/useUser';
import { useDeleteUserAccount } from '../hooks/useUserDeleteAccount';
import { getAvatarURL } from './logic/getAvatarURL';

type UserType = {
  name: string;
  email: string;
  phone: string;
  cpf: string;
};

const UpdateUserSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Email must be a valid email address'),
  phone: Yup.string().required('Phone number is required'),
  cpf: Yup.string().required('Phone number is required'),
});

export default function AccountGeneral() {
  const { user } = useUser();
  const confirm = useConfirm();
  const { deleteUserAccount, loading } = useDeleteUserAccount();
  const { push } = useRouter();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const defaultValues: UserType = {
    name: user?.name || '',
    email: user?.email || '',
    phone: user?.phone || '',
    cpf: user?.cpf || '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const handleDeleteAccount = async () => {
    try {
      await confirm({
        title: 'Atenção!',
        description: 'Tem certeza? esta ação é irreversível.',
      });
      await deleteUserAccount();
      toast.success('Sua conta foi deletada. Deslogando em 3 segundos ...');
      setIsLoggingOut(true);
      await sleep(3 * 1000);
      setIsLoggingOut(false);
      push('/logout');
    } catch (err) {
      if (err instanceof Error) {
        toast.error(
          `Ocorreu um problema durante sua solicitação, tente novamente mais tarde. ${err.message}`,
        );
      }
    }
  };
  const {
    setValue,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (!user) {
      return;
    }
    setValue('name', user.name);
    setValue('email', user.email);
    setValue('phone', user.phone);
    setValue('cpf', user.cpf);
  }, [user]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      // enqueueSnackbar('Update success!');
      console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={4}>
          <Card sx={{ pt: 3, pb: 5, px: 3, textAlign: 'center' }}>
            <Image
              src={getAvatarURL(user?.name)}
              width={128}
              height={128}
              sx={{ borderRadius: '50%' }}
            />
            <Typography
              variant='caption'
              sx={{
                mt: 3,
                mx: 'auto',
                display: 'block',
                textAlign: 'center',
                color: 'text.disabled',
              }}
            >
              {user?.name}
            </Typography>

            <Button
              variant='soft'
              color='error'
              sx={{ mt: 3 }}
              onClick={handleDeleteAccount}
              disabled={loading || isLoggingOut}
            >
              {isLoggingOut
                ? 'Vamos sentir sua falta... 😢'
                : 'Deletar minha conta'}
            </Button>
          </Card>
        </Grid>

        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display='grid'
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <FormTextField control={control} name='name' label='Nome' />
              <FormTextField control={control} name='email' label='E-mail' />
              <FormTextField control={control} name='phone' label='Telefone' />
              <FormTextField control={control} name='cpf' label='CPF' />
            </Box>

            <Stack alignItems='flex-end' sx={{ mt: 3 }}>
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
                disabled
              >
                Salvar mudanças
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
