import {
  DisplayError,
  PasswordInput,
  useAppContext,
} from '@invest-ai/animatrix';
import { Button } from '@invest-ai/animatrix';
import Alert from '@mui/lab/Alert';
import { CircularProgress, Link, Typography } from '@mui/material';
import { type FormEventHandler, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Color } from '../../../theme/Color';
import { useGetEmailForRecoverPasswordToken } from '../../hooks/useGetEmailForRecoverPasswordToken';
import { useResetPassword } from '../../hooks/useResetPassword';
import { Title } from '../Title';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const ResetPassword: React.FC = () => {
  const { defaultRouteNames: routes } = useAppContext();
  const navigate = useNavigate();
  const { resetPassword, data, loading, error } = useResetPassword();
  const [password, setPassword] = useState('');

  const query = useQuery();
  const token = query.get('t') ?? '';

  const valid = !!token && !!password;

  const {
    isValid,
    email,
    loading: loadingGetEmail,
  } = useGetEmailForRecoverPasswordToken(token as string);

  const performResetPassword: FormEventHandler = async (e) => {
    e.preventDefault();
    await resetPassword({
      variables: {
        newPassword: password,
        token: token as string,
      },
    });
    setPassword('');
  };

  useEffect(() => {
    if (!isValid && !loadingGetEmail) {
      navigate(routes.auth.signIn);
    }
  }, [isValid, loadingGetEmail]);

  return (
    <form onSubmit={performResetPassword}>
      <Title>Confirmar nova senha</Title>
      <Typography
        align='center'
        style={{ marginBottom: 20, color: Color.PRIMARY }}
      >
        {email}
      </Typography>
      <PasswordInput
        label='Nova Senha'
        onChange={setPassword}
        value={password}
        fullWidth
        style={{ marginBottom: 16 }}
      />
      {data && (
        <Alert severity='success' style={{ marginBottom: 32 }}>
          Sua senha foi alterada. Clique{' '}
          <Link href={routes.auth.signIn}>aqui</Link> para fazer login
        </Alert>
      )}
      <DisplayError error={error} />
      <Button
        variant='contained'
        color='primary'
        fullWidth
        disabled={loading || !valid}
        size='large'
        type='submit'
      >
        {loading ? <CircularProgress size={28} /> : 'Confirmar'}
      </Button>
    </form>
  );
};
