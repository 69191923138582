import { Page } from '@invest-ai/animatrix';
import { ForgotPassword } from '../../../components/ForgotPassword';
import { LoginLayout } from '../../../components/LoginLayout';

export const ForgotPasswordPage: React.FC = () => {
  return (
    <Page title='InvestAI | Recuperar Senha'>
      <LoginLayout>
        <ForgotPassword />
      </LoginLayout>
    </Page>
  );
};
