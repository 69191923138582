import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { NAV } from '../constants/config-layout';
import { NavSectionMini, type SidebarConfig } from './NavSection';
import { hideScroll } from './NavSection/styles/hideScroll';
import { NavToggleButton } from './NavToggleButton';

export const NavMini: React.FC<{
  logo: React.ReactNode;
  sidebarConfig: SidebarConfig;
}> = ({ logo, sidebarConfig }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      component='nav'
      sx={{
        flexShrink: { md: 0 },
        width: { md: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      {!isMobile && (
        <Stack
          sx={{
            pb: 2,
            height: 1,
            position: 'fixed',
            width: NAV.W_MINI,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
            ...hideScroll.x,
          }}
        >
          <Box sx={{ mx: 'auto', my: 2, width: 40, height: 40 }}>{logo}</Box>

          <NavSectionMini
            data={sidebarConfig}
            config={{
              currentRole: 'admin',
            }}
          />
        </Stack>
      )}
    </Box>
  );
};
