import { Page, ResetPassword } from '@invest-ai/animatrix';
import { LoginLayout } from '../../../components/LoginLayout';

export const ResetPasswordPage: React.FC = () => {
  return (
    <Page title='InvestAI | Nova Senha'>
      <LoginLayout>
        <ResetPassword />
      </LoginLayout>
    </Page>
  );
};
