import { Container, type IContainerProps } from './components/Container';
import { Content, type IContentProps } from './components/Content';
import { Footer, type IFooterProps } from './components/Footer';
import { Header, type IHeaderProps } from './components/Header';

export const Modal = {
  Container,
  Header,
  Content,
  Footer,
};

export type {
  IContainerProps as ModalContainerProps,
  IHeaderProps as ModalHeaderProps,
  IContentProps as ModalContentProps,
  IFooterProps as ModalFooterProps,
};
