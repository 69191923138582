export enum AppEvents {
  ViewSignIn = 'ViewSignIn',
  SignInComplete = 'SignInComplete',
  ViewEmailSignUpForm = 'ViewEmailSignUpForm',
  SignUpComplete = 'SignUpComplete',
  ClickSignUpComplete = 'ClickSignUpComplete',
  ViewPlatformHome = 'ViewPlatformHome',
  ClickOnboardingStart = 'ClickOnboardingStart',
  ViewOnboardingIntro = 'ViewOnboardingIntro',
  ClickOnboardingIntroNext = 'ClickOnboardingIntroNext',
  ViewOnboardingBroker = 'ViewOnboardingBroker',
  ClickOnboardingBrokerNext = 'ClickOnboardingBrokerNext',
  ViewOnboardingPurchaseOrder = 'ViewOnboardingPurchaseOrder',
  ClickOnboardingOrderPurchaseNext = 'ClickOnboardingOrderPurchaseNext',
  ViewOnboardingInsertCoupon = 'ViewOnboardingInsertCoupon',
  ClickOnboardingInsertCouponFinish = 'ClickOnboardingInsertCouponFinish',
  ViewOnboardingAuthenticateReminder = 'ViewOnboardingAuthenticateReminder',
  ClickOnboardingAuthenticateReminder = 'ClickOnboardingAuthenticateReminderFinish',
  ClickClaimBrokerAccountNumber = 'ClickClaimBrokerAccountNumber',
  BrokerAccountNumberValidated = 'BrokerAccountNumberValidated',
  SuccessClaimAccountNumber = 'SuccessClaimAccountNumber',
  ClickBrokerAccountAuthentication = 'ClickBrokerAccountAuthentication',
  ViewBrokerAccountAuthentication = 'ViewBrokerAccountAuthentication',
  BrokerAccountPasswordValidated = 'BrokerAccountPasswordValidated',
  BrokerAccountPasswordFail = 'BrokerAccountPasswordFail',
  BrokerAccountTokenValidated = 'BrokerAccountTokenValidated',
  BrokerAccountTokenNotValidated = 'BrokerAccountTokenNotValidated',
  BrokerAccountAuthenticated = 'BrokerAccountAuthenticated',
  BrokerAccountNotAuthenticated = 'BrokerAccountNotAuthenticated',
  ClickCreateFirstBrokerAccount = 'ClickCreateFirstBrokerAccount',
  CreateFirstBrokerAccountSuccess = 'CreateFirstBrokerAccountSuccess',
  ViewNoBrokerAccountInstructions = 'ViewNoBrokerAccountInstructions',
  OpenBrokerAccountFinish = 'OpenBrokerAccountFinish',
  ViewNotImplementedBroker = 'ViewNotImplementedBroker',
  BrokerNotReadyFinish = 'BrokerNotReadyFinish',
  BrokerAccountPinSetupSuccess = 'BrokerAccountPinSetupSuccess',
  ViewBrokerAccountPINModal = 'ViewBrokerAccountPINModal',
  PlatformPageView = 'PlatformPageView',
  ViewBrokerOfficeTransfer = 'ViewBrokerOfficeTransfer',
  ConfirmBrokerOfficeTransfer = 'ConfirmBrokerOfficeTransfer',
  ClickOnBoardingVideo = 'ClickOnBoardingVideo',
  ClickOnAuthenticationVideo = 'ClickOnAuthenticationVideo',
  ClickOnCopyCoupon = 'ClickOnCopyCoupon',
  ViewOnboardingHowParametersWorks = 'ViewOnboardingHowParametersWorks',
  ClickOnboardingHowParametersWorksNext = 'ClickOnboardingHowParametersWorksNext',
  ViewOnboardingHowAuthorizationWorks = 'ViewOnboardingHowAuthorizationWorks',
  ClickOnboardingHowAuthorizationWorksNext = 'ClickOnboardingHowAuthorizationWorksNext',
  ViewChangeBrokerAccountModal = 'ViewChangeBrokerAccountModal',
  ClickChangeBrokerAccount = 'ClickChangeBrokerAccount',
  ViewBrokerAccountAuthenticationConnectYourAccount = 'ViewBrokerAccountAuthenticationConnectYourAccount',
  ViewBrokerAccountAuthenticationDisclaimer = 'ViewBrokerAccountAuthenticationDisclaimer',
  ViewBrokerAccountAuthenticationHowMarketWorks = 'ViewBrokerAccountAuthenticationHowMarketWorks',
  ViewBrokerAccountPinSetup = 'ViewBrokerAccountPinSetup',
  InvalidDigitalSignature = 'InvalidDigitalSignature',
  BrokerAccountKeyboardPasswordAttempt = 'BrokerAccountKeyboardPasswordAttempt',
  ViewPlatformHomePurchaseOrderOutdated = 'ViewPlatformHomePurchaseOrderOutdated',
  ViewPurchaseOrderEditScreen = 'ViewPurchaseOrderEditScreen',
  ViewPurchaseOrderOutdatedEditScreen = 'ViewPurchaseOrderOutdatedEditScreen',
  ClickDecreaseFee = 'ClickDecreaseFee',
  ConfirmPurchaseOrderEdit = 'ConfirmPurchaseOrderEdit',
  ClickMenuMain = 'ClickMenuMain',
  ClickMenuInvestments = 'ClickMenuInvestments',
  ClickMenuFGC = 'ClickMenuFGC',
  ClickMenuSimulator = 'ClickMenuSimulator',
  ClickMenuMGM = 'ClickMenuMGM',
  ClickMenuInvoices = 'ClickMenuInvoices',
  ClickMenuSettings = 'ClickMenuSettings',
  ClickMenuFAQ = 'ClickMenuFAQ',
  ViewFGCBanksPositions = 'ViewFGCBanksPositions',
  ViewFGCExternalPositions = 'ViewFGCExternalPositions',
  ClickCreateExternalPosition = 'ClickCreateExternalPosition',
  ConfirmCreateExternalPosition = 'ConfirmCreateExternalPosition',
  ClickEditExternalPosition = 'ClickEditExternalPosition',
  ConfirmEditExternalPosition = 'ConfirmEditExternalPosition',
  ClickRemoveExternalPosition = 'ClickRemoveExternalPosition',
  ConfirmRemoveExternalPosition = 'ConfirmRemoveExternalPosition',

  ViewPurchaseOrderSuggestionScreen = 'ViewPurchaseOrderSuggestionScreen',
  ConfirmPurchaseOrderSuggestion = 'ConfirmPurchaseOrderSuggestion',
  CancelPurchaseOrderSuggestion = 'CancelPurchaseOrderSuggestion',
  ClickMoreInformationOnPurchaseOrderSuggestion = 'ClickMoreInformationOnPurchaseOrderSuggestion',
  ConfirmPurchaseOrderCreation = 'ConfirmPurchaseOrderCreation',

  ViewInviteFriendsScreen = 'ViewInviteFriendsScreen',
  ClickInviteFriends = 'ClickInviteFriends',

  ClickWhatsAppChat = 'ClickWhatsAppChat',

  ClickBannerRecommendation = 'ClickBannerRecommendation',
}
