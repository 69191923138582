import { useCallback, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';

// import {
//   PlanFreeIcon,
//   PlanStarterIcon,
//   PlanPremiumIcon,
// } from 'src/assets/icons';

import { Iconify } from '../../components/Iconify';
import { Label } from '../../components/Label';
import { useBoolean } from '../../hooks/useBoolean';
import { PaymentCardListDialog } from './PaymentCardListDialog';
import type { IPaymentCard } from './interfaces/IPaymentCard';

type Props = {
  cardList: IPaymentCard[];
  plans: {
    subscription: string;
    price: number;
    primary: boolean;
  }[];
};

export default function AccountBillingPlan({ cardList, plans }: Props) {
  const openAddress = useBoolean();
  const openCards = useBoolean();

  const primaryCard = cardList.filter((card) => card.primary)[0];

  const [selectedPlan, setSelectedPlan] = useState('');

  const [selectedCard, setSelectedCard] = useState<IPaymentCard | null>(
    primaryCard,
  );

  const handleSelectPlan = useCallback(
    (newValue: string) => {
      const currentPlan = plans.filter((plan) => plan.primary)[0].subscription;
      if (currentPlan !== newValue) {
        setSelectedPlan(newValue);
      }
    },
    [plans],
  );

  const handleSelectCard = useCallback((newValue: IPaymentCard | null) => {
    setSelectedCard(newValue);
  }, []);

  const renderPlans = plans.map((plan) => (
    <Grid xs={12} md={4} key={plan.subscription}>
      <Stack
        component={Paper}
        variant='outlined'
        onClick={() => handleSelectPlan(plan.subscription)}
        sx={{
          p: 2.5,
          position: 'relative',
          cursor: 'pointer',
          ...(plan.primary && {
            opacity: 0.48,
            cursor: 'default',
          }),
          ...(plan.subscription === selectedPlan && {
            boxShadow: (theme) => `0 0 0 2px ${theme.palette.text.primary}`,
          }),
        }}
      >
        {plan.primary && (
          <Label
            color='info'
            startIcon={<Iconify icon='eva:star-fill' />}
            sx={{ position: 'absolute', top: 8, right: 8 }}
          >
            Current
          </Label>
        )}

        <Box sx={{ width: 48, height: 48 }}>
          {plan.subscription === 'basic' && (
            <img src={'https://placehold.it/300x300'} alt='plan' />
          )}
          {plan.subscription === 'starter' && (
            <img src={'https://placehold.it/300x300'} alt='plan' />
          )}
          {plan.subscription === 'premium' && (
            <img src={'https://placehold.it/300x300'} alt='plan' />
          )}
        </Box>

        <Box
          sx={{
            typography: 'subtitle2',
            mt: 2,
            mb: 0.5,
            textTransform: 'capitalize',
          }}
        >
          {plan.subscription}
        </Box>

        <Stack direction='row' alignItems='center' sx={{ typography: 'h4' }}>
          {plan.price || 'Free'}

          {!!plan.price && (
            <Box
              component='span'
              sx={{ typography: 'body2', color: 'text.disabled', ml: 0.5 }}
            >
              /mo
            </Box>
          )}
        </Stack>
      </Stack>
    </Grid>
  ));

  return (
    <>
      <Card>
        <CardHeader title='Plano' />

        <Grid container spacing={2} sx={{ p: 3 }}>
          {renderPlans}
        </Grid>

        <Stack spacing={2} sx={{ p: 3, pt: 0, typography: 'body2' }}>
          <Grid container spacing={{ xs: 0.5, md: 2 }}>
            <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
              Plano
            </Grid>
            <Grid
              xs={12}
              md={8}
              sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}
            >
              {selectedPlan || '-'}
            </Grid>
          </Grid>

          <Grid container spacing={{ xs: 0.5, md: 2 }}>
            <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
              Nome
            </Grid>
            <Grid xs={12} md={8}>
              <Button
                onClick={openAddress.onTrue}
                endIcon={
                  <Iconify width={16} icon='eva:arrow-ios-downward-fill' />
                }
                sx={{ typography: 'subtitle2', p: 0, borderRadius: 0 }}
              >
                {/* {selectedAddress?.name} */}
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={{ xs: 0.5, md: 2 }}>
            <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
              Endereço
            </Grid>
            {/* <Grid xs={12} md={8} sx={{ color: 'text.secondary' }}>
              {selectedAddress?.fullAddress}
            </Grid> */}
          </Grid>

          <Grid container spacing={{ xs: 0.5, md: 2 }}>
            <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
              Telefone
            </Grid>
            {/* <Grid xs={12} md={8} sx={{ color: 'text.secondary' }}>
              {selectedAddress?.phoneNumber}
            </Grid> */}
          </Grid>

          <Grid container spacing={{ xs: 0.5, md: 2 }}>
            <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
              Método de pagamento
            </Grid>
            <Grid xs={12} md={8}>
              <Button
                onClick={openCards.onTrue}
                endIcon={
                  <Iconify width={16} icon='eva:arrow-ios-downward-fill' />
                }
                sx={{ typography: 'subtitle2', p: 0, borderRadius: 0 }}
              >
                {selectedCard?.cardNumber}
              </Button>
            </Grid>
          </Grid>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack
          spacing={1.5}
          direction='row'
          justifyContent='flex-end'
          sx={{ p: 3 }}
        >
          <Button variant='outlined'>Cancelar Plano</Button>
          <Button variant='contained'>Mudar Plano</Button>
        </Stack>
      </Card>

      <PaymentCardListDialog
        list={cardList}
        open={openCards.value}
        onClose={openCards.onFalse}
        selected={(selectedId: string) => selectedCard?.id === selectedId}
        onSelect={handleSelectCard}
      />
    </>
  );
}
