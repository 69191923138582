import { OnErrorCallback } from '@bugsnag/js';
import { GraphQLError } from 'graphql';

const GRAPHQL_ERRORS_CODE_TO_SKIP = ['INVALID_CREDENTIALS', 'ACCOUNT_MIGRATED'];

const shouldSkipErrorNotification = (errors: GraphQLError[]): boolean => {
  for (const error of errors) {
    if (
      GRAPHQL_ERRORS_CODE_TO_SKIP.includes(error.extensions?.code as string)
    ) {
      return true;
    }
  }

  return false;
};

export const errorNotificationFilter: OnErrorCallback = (event, onError) => {
  const graphqlErrors = event.originalError?.graphQLErrors;
  const shouldSkipGraphqlError =
    graphqlErrors?.length && shouldSkipErrorNotification(graphqlErrors);

  if (shouldSkipGraphqlError) {
    return false;
  }

  // Let bugsnag keep with the work
  onError(null);
};
