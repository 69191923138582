import { styled } from '@mui/material/styles';
import { Page } from '../../components/Page';

export const Root: any = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));
