import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { errorNotificationFilter } from '@global/bugsnag/logic/errorNotificationFilter';

export const bugsnagClient = Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY as string,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
  onError: errorNotificationFilter,
});
