import { type SxProps } from '@mui/material';
import Alert from '@mui/material/Alert';
import React, { type CSSProperties } from 'react';

export const DisplayErrorMessage: React.FC<{
  message: string;
  style?: CSSProperties;
  className?: string;
  sx?: SxProps;
}> = ({ sx, className, message, style }) => {
  return (
    <Alert sx={sx} className={className} severity='error' style={style}>
      {message}
    </Alert>
  );
};
