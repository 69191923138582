import { styled } from '@mui/material';

export const Table = styled('table')(({ theme }) => ({
  '&': {
    marginBottom: theme.spacing(4),
  },
  '&, th, td': {
    border: '1px solid black',
    borderCollapse: 'collapse',
    padding: theme.spacing(1),
  },
})) as unknown as 'table';
