import { useSearchParams } from 'react-router-dom';

export const TC_SALES_CHANNEL = 'tc';
export const TC_COUPON = 'PARCERIATC';

export function useIsUrlFromTC() {
  const [searchParams] = useSearchParams();
  return (
    searchParams.get('sales_channel') === TC_SALES_CHANNEL ||
    searchParams.get('r') === TC_COUPON
  );
}
