import { Buffer } from 'buffer';

export const parseBase64ToJson = (data: string | null) => {
  try {
    if (data) {
      const dataBuffer = Buffer.from(data, 'base64');
      const dataString = dataBuffer.toString('utf8');
      return JSON.parse(dataString);
    }
  } catch (e) {
    console.error(e);
  }

  return {};
};
