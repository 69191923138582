import { Page } from '@invest-ai/animatrix';
import { SignIn } from '../../components/SignIn';

export const SignInPage: React.FC = () => {
  return (
    <Page title={'InvestAI | Entrar'}>
      <SignIn />
    </Page>
  );
};
