import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../..';
import { Logo } from '../../../components/Logo';
import { OrderedList } from '../OrderedList';
import { Paragraph } from '../Paragraph';
import { Table } from '../Table';
import { Title } from '../Title';

export const PrivacyPolicy: React.FC = () => {
  const { product, legalInfo } = useAppContext();
  const PRODUCT_NAME_UPPER = product.name.toUpperCase();
  const INVEST_AI_CNPJ = legalInfo.cnpj;
  const productName = product.name;
  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' minHeight={80} alignItems='center' mb={2}>
        <Link to='/'>
          <Logo width={120} />
        </Link>
      </Box>
      <Typography mb={3} variant='h2' fontWeight={600} alignSelf={'center'}>
        Política de Privacidade
      </Typography>

      <Paragraph>
        Nós, da AI INTERMEDIAÇÃO DE NEGÓCIOS S.A., pessoa jurídica, inscrita no
        CNPJ sob o nº {INVEST_AI_CNPJ}, levamos a sua privacidade e a proteção
        dos seus dados a sério.
      </Paragraph>
      <Paragraph>
        Por entendermos que a privacidade de suas informações é importante e
        estarmos comprometidos em cumprir as diretrizes da Lei Geral de Proteção
        de Dados (LGPD) quanto ao uso dos dados pessoais, desenvolvemos o
        presente Aviso de Privacidade, o qual tem como propósito informar a
        vocês, usuários e parceiros do {productName}, como tratamos seus dados
        pessoais, quais são os seus direitos e como você pode exercê-los.
      </Paragraph>
      <Paragraph>
        Esperamos que as informações auxiliem na compreensão do tratamento de
        seus dados e que demonstrem o compromisso do {productName} com a
        privacidade, proteção de dados e segurança da informação.
      </Paragraph>
      <Paragraph>
        E, por fim, informamos que a ciência deste Aviso de Privacidade é
        indispensável para que você, usuário, acesse o nosso site, aplicativo,
        entre em contato, realize o cadastro em nossa plataforma, quando for o
        caso, e usufrua de nossos serviços. Caso não concorde com os termos do
        presente, pedimos que não seja dado prosseguimento nas ações acima
        mencionadas.
      </Paragraph>

      <Title>
        1. CONCEITOS BÁSICOS: O QUE VOCÊ PRECISA SABER PARA COMPREENDER ESTA
        POLÍTICA?
      </Title>

      <Table>
        <tr>
          <th>{''}</th>
          <th>Conceito</th>
        </tr>
        <tr>
          <td>Dado Pessoal</td>
          <td>
            É uma informação que permite identificar você. É qualquer atividade
            que possamos realizar com os seus dados pessoais. Cita-se como
            exemplo: coleta, armazenamento, consulta, compartilhamento,
            exclusão, etc.
          </td>
        </tr>
        <tr>
          <td>Tratamento</td>
          <td>
            É qualquer atividade que possamos realizar com os seus dados
            pessoais. Cita-se como exemplo: coleta, armazenamento, consulta,
            compartilhamento, exclusão, etc.
          </td>
        </tr>
        <tr>
          <td>Titular</td>
          <td>É você, a pessoa física a quem os dados pessoais se referem.</td>
        </tr>
        <tr>
          <td>Empresa</td>
          <td>Somos nós, o {productName}</td>
        </tr>
      </Table>

      <Title>2. QUAIS OS DADOS PESSOAIS QUE TRATAMOS?</Title>

      <Paragraph>
        Os dados pessoais que o {productName} tem acesso depende de como você se
        relaciona, podendo ser coletados os seguintes dados pessoais:
      </Paragraph>
      <OrderedList>
        <li>
          <Paragraph>Nome completo;</Paragraph>
        </li>
        <li>
          <Paragraph>Email;</Paragraph>
        </li>
        <li>
          <Paragraph>Número do CPF;</Paragraph>
        </li>
        <li>
          <Paragraph>Número do RG;</Paragraph>
        </li>
        <li>
          <Paragraph>Endereço completo (pessoal e profissional);</Paragraph>
        </li>
        <li>
          <Paragraph>Data de nascimento;</Paragraph>
        </li>
        <li>
          <Paragraph>Cidade/Estado;</Paragraph>
        </li>
        <li>
          <Paragraph>Salário;</Paragraph>
        </li>
        <li>
          <Paragraph>
            Perfis em redes sociais (a exemplo do Linkedin);
          </Paragraph>
        </li>
        <li>
          <Paragraph>Cargo;</Paragraph>
        </li>
        <li>
          <Paragraph>Empresa;</Paragraph>
        </li>
        <li>
          <Paragraph>
            Dados financeiros (a exemplo de banco, agência e conta corrente);
          </Paragraph>
        </li>
        <li>
          <Paragraph>Posição de investimentos;</Paragraph>
        </li>
        <li>
          <Paragraph>Dados referente a dívidas e financiamentos;</Paragraph>
        </li>
        <li>
          <Paragraph>Produtos de seguros contratados;</Paragraph>
        </li>
        <li>
          <Paragraph>Receitas e despesas no mês;</Paragraph>
        </li>
        <li>
          <Paragraph>Endereço de IP;</Paragraph>
        </li>
        <li>
          <Paragraph>
            Informações relacionadas aos registros de data e horário de acesso
            ao ambiente do {productName}, bem como quais as telas você acessou
            no ambiente online;
          </Paragraph>
        </li>
        <li>
          <Paragraph>Geolocalização.</Paragraph>
        </li>
      </OrderedList>

      <Title>3. COMO A EMPRESA PODERÁ COLETAR OS SEUS DADOS PESSOAIS</Title>

      <Paragraph>
        Os dados pessoais poderão ser coletados a partir das seguintes ações, a
        saber:
      </Paragraph>
      <OrderedList>
        <li>
          <Paragraph>
            {/* biome-ignore lint/suspicious/noSuspiciousSemicolonInJsx: <explanation> */}
            Cadastro de conta junto ao {PRODUCT_NAME_UPPER};
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            {/* biome-ignore lint/suspicious/noSuspiciousSemicolonInJsx: <explanation> */}
            Contratação dos serviços do {PRODUCT_NAME_UPPER};
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            Utilização do suporte ao usuário, canais de atendimento, pesquisa e
            treinamentos;
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            {/* biome-ignore lint/suspicious/noSuspiciousSemicolonInJsx: <explanation> */}
            Utilização da plataforma do {PRODUCT_NAME_UPPER};
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            Divulgação de conteúdo fornecido pelo usuário ao 
            {/* biome-ignore lint/suspicious/noSuspiciousSemicolonInJsx: <explanation> */}
            {PRODUCT_NAME_UPPER};
          </Paragraph>
        </li>
        <li>
          <Paragraph>Realização de transação envolvendo o usuário;</Paragraph>
        </li>
        <li>
          <Paragraph>Coleta automática ou semiautomática de dados;</Paragraph>
        </li>
        <li>
          <Paragraph>Informações sobre produtos e serviços;</Paragraph>
        </li>
        <li>
          <Paragraph>
            Participação do usuário em campanha de marketing do 
            {PRODUCT_NAME_UPPER}.
          </Paragraph>
        </li>
      </OrderedList>

      <Paragraph>
        Informamos, ainda, que a coleta de dados poderá ser realizada via
        terceiros quando permitido pela legislação.
      </Paragraph>
      <Paragraph>
        Eventualmente, outros tipos de dados não previstos expressamente nesta
        Política de Privacidade poderão ser coletados, desde que sejam
        fornecidos com o consentimento do usuário, ou, ainda, que a coleta seja
        permitida ou imposta por lei.
      </Paragraph>

      <Title>4. COMO OS DADOS PESSOAIS PODERÃO SER UTILIZADOS?</Title>

      <Paragraph>
        O {productName} poderá tratar da seguinte forma os dados pessoais
        coletados:
      </Paragraph>

      <OrderedList>
        <li>
          <Paragraph>
            Identificar e autenticar o usuário quando do acesso na plataforma
            {/* biome-ignore lint/suspicious/noSuspiciousSemicolonInJsx: <explanation> */}
            do {productName};
          </Paragraph>
        </li>
        <li>
          <Paragraph>Processar as transações;</Paragraph>
        </li>
        <li>
          <Paragraph>
            Prevenir fraudes e outros riscos que comprometam a segurança da
            informação;
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            Fornecer produtos e serviços oferecidos pelo {productName} ou
            terceiros;
          </Paragraph>
        </li>
        <li>
          <Paragraph>Manter atualizado o cadastro do usuário;</Paragraph>
        </li>
        <li>
          <Paragraph>Aperfeiçoar o uso e a experiência dos serviços;</Paragraph>
        </li>
        <li>
          <Paragraph>Atender solicitações e dúvidas;</Paragraph>
        </li>
        <li>
          <Paragraph>Suporte técnico;</Paragraph>
        </li>
        <li>
          <Paragraph>Recomendar serviços da plataforma;</Paragraph>
        </li>
        <li>
          <Paragraph>
            Informar sobre produtos, funcionalidades, conteúdos, notícias e
            {/* biome-ignore lint/suspicious/noSuspiciousSemicolonInJsx: <explanation> */}
            demais eventos relacionados aos serviços do {productName};
          </Paragraph>
        </li>
        <li>
          <Paragraph>Para ações de marketing;</Paragraph>
        </li>
        <li>
          <Paragraph>Análise de crédito;</Paragraph>
        </li>
        <li>
          <Paragraph>Outras finalidades previstas na legislação.</Paragraph>
        </li>
      </OrderedList>

      <Paragraph>
        Importante ressaltar que o tratamento de dados será realizado de acordo
        com as bases legais previstas na Lei nº 13.709/18 (LGPD).
      </Paragraph>

      <Title>5. COMPARTILHAMENTO DOS DADOS</Title>

      <Paragraph>
        O {productName} opera em parceria com uma série de outras organizações
        para viabilizar as suas atividades, sendo necessário o compartilhamento
        de alguns dados com terceiros. Porém, não se preocupe, os dados pessoais
        serão utilizados somente para a finalidade na qual foram coletados,
        sendo garantida a mais estrita confidencialidade.
      </Paragraph>
      <Paragraph>
        Descrevemos, a seguir, algumas situações em que o {productName} poderá
        compartilhar os dados pessoais coletados:
      </Paragraph>

      <OrderedList>
        <li>
          <Paragraph>
            {/* biome-ignore lint/suspicious/noSuspiciousSemicolonInJsx: <explanation> */}
            Prestadores de serviços que atuem em favor do {productName};
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            Parceiros de negócios com o intuito de oferecer melhores condições
            de negócio aos usuários, como por exemplo, crédito ou seguros mais
            baratos;
          </Paragraph>
        </li>
        <li>
          <Paragraph>Campanhas de publicidade;</Paragraph>
        </li>
        <li>
          <Paragraph>Agências de crédito;</Paragraph>
        </li>
        <li>
          <Paragraph>Correspondentes bancários;</Paragraph>
        </li>
        <li>
          <Paragraph>Entidades e órgãos públicos;</Paragraph>
        </li>
        <li>
          <Paragraph>
            Terceiros que estejam fornecendo em conjunto iniciativas de vendas,
            eventos ou promoções, mediante consentimento;
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            Autoridades em geral, para cumprimento de determinação legal ou
            regulatória;
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            Terceiros, para fins de controle e prevenção de fraude.
          </Paragraph>
        </li>
      </OrderedList>

      <Title>6. ARMAZENAMENTO DE DADOS</Title>

      <Paragraph>
        O {productName} possui uma política de retenção de dados pessoais
        alinhada com a lei aplicável. Os seus dados serão armazenados somente
        pelo período necessário para o atendimento da finalidade para a qual
        foram coletados, salvo se houver qualquer outra razão para sua
        manutenção, como, por exemplo, cumprimento de obrigações legais,
        regulatórias, contratuais e exercício regular de direito.
      </Paragraph>
      <Paragraph>
        Informa-se que você, titular, pode exercer o seu direito de oposição
        solicitando a exclusão do dado pessoal, bem como revogando o seu
        consentimento. Nestes casos, o {productName} irá avaliar a solicitação e
        excluir os dados em até 7 dias úteis caso não sejam mais necessários
        para àquela finalidade para os quais foram tratados, exceto se houver
        razão para a manutenção do armazenamento, conforme acima informado.
      </Paragraph>

      <Title>7. COMO PROTEGEMOS OS SEUS DADOS?</Title>

      <Paragraph>
        O {productName} utiliza das melhores práticas de mercado no quesito
        segurança de informação e dados.
      </Paragraph>
      <Paragraph>
        Nossa responsabilidade é cuidar dos seus dados pessoais e utilizá-los
        somente para as finalidades descritas nesta Política. Para garantir a
        sua privacidade e a proteção dos seus dados pessoais, o {productName}
         adota todas as medidas técnicas e organizacionais para a proteção de
        seus dados com acesso restrito e emprego de logins e senhas nominais
        para acesso ao servidor, antivírus, firewall, acordo de
        confidencialidade firmado com os colaboradores e prestadores de
        serviços, dentre outras medidas que assegurem a integridade e sigilo das
        informações.
      </Paragraph>
      <Paragraph>
        Importante mencionar que, como medida de proteção adotada, não será
        solicitada a sua senha, exceto quando você fizer login nos canais
        oficiais do {productName}. Além disso, você é responsável por manter em
        sigilo a senha de acesso na plataforma, usar antivírus e firewall
        atualizados, atualizar o sistema operacional, não realizar o
        compartilhamento de senhas com terceiros, além das demais medidas aptas
        a proteger as informações.
      </Paragraph>

      <Title>8. DIREITOS DO TITULAR</Title>

      <Paragraph>
        Os dados pessoais são seus e a lei brasileira, através da LGPD e demais
        legislações, garante a você uma série de direitos. Nós estamos
        comprometidos com o cumprimento desses direitos e vamos, na sequência,
        explicar quais são esses direitos e como você pode exercê-los:
      </Paragraph>

      <Table>
        <tr>
          <td>Confirmação e Acesso</td>
          <td>
            Você pode solicitar ao {productName} a confirmação sobre a
            existência de tratamento dos seus dados pessoais para que, em caso
            positivo, você possa solicitar cópias dos registros que temos sobre
            você
          </td>
        </tr>
        <tr>
          <td>Correção</td>
          <td>
            Você pode solicitar a correção dos seus dados pessoais caso estes
            estejam incompletos, inexatos ou desatualizados
          </td>
        </tr>
        <tr>
          <td>Anonimização, bloqueio ou eliminação</td>
          <td>
            Você pode solicitar a anonimização dos seus dados pessoais, de forma
            que eles não possam mais ser relacionados a você e, portanto, deixem
            de ser dados pessoais; (b) o bloqueio dos seus dados pessoais,
            suspendendo temporariamente a possibilidade de os tratarmos para
            certas finalidades; e (c) a eliminação dos seus dados pessoais, caso
            em que deveremos apagar todos os seus dados pessoais sem
            possibilidade de reversão. Informa-se que a solicitação pode não ser
            atendida pelo {productName} caso necessário para resguardar os seus
            direitos e/ou cumprir com obrigações legais ou regulatórias.
          </td>
        </tr>
        <tr>
          <td>Portabilidade</td>
          <td>
            Você pode solicitar que o {productName} forneça os seus dados
            pessoais em formato estruturado para um terceiro, desde que essa
            transferência não viole a propriedade intelectual ou segredo de
            negócios do {productName}.
          </td>
        </tr>
        <tr>
          <td>Informações a respeito do compartilhamento</td>
          <td>
            Você tem o direito de saber quais são as entidades públicas e
            privadas com as quais o {productName} realiza uso compartilhado dos
            seus dados pessoais. Manteremos, no item 5 dessa Política, uma
            indicação das nossas relações com terceiros que podem envolver o
            compartilhamento de dados pessoais, sendo que a depender do caso,
            podemos limitar as informações fornecidas caso a sua divulgação
            possa violar a propriedade intelectual ou segredo de negócios da
            Empresa
          </td>
        </tr>
        <tr>
          <td>Informação sobre a possibilidade de não consentir</td>
          <td>
            Você tem o direito de receber informações claras, completas e
            inequívoca sobre a possibilidade e as consequências de não fornecer
            consentimento, ficando ciente, desde já, que é possível que fiquemos
            impossibilitados de prestar certos serviços, mas iremos avisá-lo
            quando isso ocorrer
          </td>
        </tr>
        <tr>
          <td>Revogação do consentimento</td>
          <td>
            Caso você tenha consentido com alguma finalidade de tratamento dos
            seus dados pessoais, você pode sempre optar por retirar o seu
            consentimento. No entanto, isso não afetará a legalidade de qualquer
            tratamento realizado anteriormente à revogação. Se você retirar o
            seu consentimento, é possível que fiquemos impossibilitados de
            prestar certos serviços, mas iremos avisá-lo quando isso ocorrer
          </td>
        </tr>
        <tr>
          <td>Oposição</td>
          <td>
            A lei autoriza o tratamento de dados pessoais mesmo sem o seu
            consentimento. Nessas situações, somente trataremos seus dados
            pessoais se estiver presente uma das bases legais de tratamento que
            não seja o consentimento
          </td>
        </tr>
      </Table>

      <Paragraph>
        Caso você tenha alguma dúvida sobre essas questões ou sobre o exercício
        de seus direitos, fique à vontade para entrar em contato conosco por
        meio dos canais informados ao final desta Política.
      </Paragraph>

      <Title>9. TRANSFERÊNCIA INTERNACIONAL DE DADOS</Title>

      <Paragraph>
        Alguns dados poderão ser armazenados em outros países, já que o 
        {productName} utiliza ou poderá utilizar o sistema baseado em servidores
        de terceiros (Cloud). Nestes casos, assegura-se que os seus dados
        pessoais serão enviados para países que proporcionem grau de proteção de
        dados pessoais adequados.
      </Paragraph>

      <Title>
        10. COLETA E USO DE INFORMAÇÕES PESSOAIS DE CRIANÇAS E ADOLESCENTES
      </Title>

      <Paragraph>
        O uso da plataforma do {productName} não é destinado a usuário com idade
        inferior a 18 anos.
      </Paragraph>

      <Title>11. DADOS DE NAVEGAÇÃO (COOKIES)</Title>

      <Paragraph>
        Cookies são pequenos arquivos de texto enviados pelo aplicativo ao
        computador do usuário e que nele ficam armazenados, com informações
        relacionadas à navegação do site.
      </Paragraph>
      <Paragraph>
        Por meio dos cookies, pequenas quantidades de informação são armazenadas
        pelo navegador do usuário. Podem ser armazenados, por exemplo, dados
        sobre o dispositivo utilizado pelo usuário, bem como seu local e horário
        de acesso ao site.
      </Paragraph>
      <Paragraph>
        Os cookies não permitem que qualquer arquivo ou informação sejam
        extraídos do disco rígido do usuário, não sendo possível, ainda, que,
        por meio deles, se tenha acesso a informações pessoais que não tenham
        partido do usuário ou da forma como utiliza os recursos do aplicativo.
      </Paragraph>
      <Paragraph>
        É importante ressaltar que nem todo cookie contém informações que
        permitem a identificação do usuário, sendo que determinados tipos de
        cookies podem ser empregados simplesmente para que o site seja carregado
        corretamente ou para que suas funcionalidades funcionem do modo
        esperado.
      </Paragraph>
      <Paragraph>
        As informações eventualmente armazenadas em cookies que permitam
        identificar um usuário são consideradas dados pessoais. Dessa forma,
        todas as regras previstas nesta Política de Privacidade também lhes são
        aplicáveis.
      </Paragraph>

      <Title>11.1. COOKIES DO SITE</Title>

      <Paragraph>
        Os cookies do site são aqueles enviados ao computador ou dispositivo do
        usuário e administrado exclusivamente pelo site.
      </Paragraph>
      <Paragraph>
        As informações coletadas por meio destes cookies são utilizadas para
        melhorar e personalizar a experiência do usuário, sendo que alguns
        cookies podem, por exemplo, ser utilizados para lembrar as preferências
        e escolhas do usuário, bem como para o oferecimento de conteúdo
        personalizado.
      </Paragraph>

      <Title>11.2. COOKIES DE TERCEIROS</Title>

      <Paragraph>
        Alguns de nossos parceiros podem configurar cookies nos dispositivos do
        usuário que acessam nosso aplicativo.
      </Paragraph>
      <Paragraph>
        Estes cookies, em geral, visam possibilitar que nossos parceiros possam
        oferecer seu conteúdo e seus serviços ao usuário que acessa nosso
        aplicativo de forma personalizada, por meio da obtenção de dados de
        navegação extraídos a partir de sua interação com o site. O usuário
        poderá obter mais informações sobre os cookies de terceiro de entidades
        encarregadas da coleta dos cookies, sobre a forma como os dados obtidos
        a partir deles são tratados, além de ter acesso à descrição dos cookies
        utilizados e de suas características.
      </Paragraph>

      <Title>11.3. COOKIES DE REDES SOCIAIS</Title>

      <Paragraph>
        O aplicativo utiliza plugins de redes sociais, que permitem acessá-las a
        partir do aplicativo. Assim, ao fazê-lo, os cookies utilizados por elas
        poderão ser armazenados no navegador do usuário.
      </Paragraph>
      <Paragraph>
        Cada rede social possui sua própria política de privacidade e de
        proteção de dados pessoais, sendo as pessoas físicas ou jurídicas que as
        mantêm responsáveis pelos dados coletados e pelas práticas de
        privacidade adotadas.
      </Paragraph>
      <Paragraph>
        O usuário pode pesquisar, junto às redes sociais, informações sobre como
        seus dados pessoais são tratados. A título informativo, disponibilizamos
        os seguintes links, a partir dos quais poderão ser consultadas as
        políticas de privacidade e de cookies adotadas por algumas das
        principais redes sociais:
      </Paragraph>
      <Paragraph>
        Facebook:{' '}
        <a
          href='https://www.facebook.com/policies/cookies/'
          target='_blank'
          rel='noreferrer'
        >
          https://www.facebook.com/policies/cookies/
        </a>
      </Paragraph>
      <Paragraph>
        Twitter:{' '}
        <a
          href='https://twitter.com/pt/privacy'
          target='_blank'
          rel='noreferrer'
        >
          https://twitter.com/pt/privacy
        </a>
      </Paragraph>
      <Paragraph>
        Instagram:{' '}
        <a
          href='https://help.instagram.com/1896641480634370?ref=ig'
          target='_blank'
          rel='noreferrer'
        >
          https://help.instagram.com/1896641480634370?ref=ig
        </a>
      </Paragraph>
      <Paragraph>
        YouTube:{' '}
        <a
          href='https://policies.google.com/privacy?hl=pt-BR&gl=pt'
          target='_blank'
          rel='noreferrer'
        >
          https://policies.google.com/privacy?hl=pt-BR&gl=pt
        </a>
      </Paragraph>
      <Paragraph>
        LinkedIn:{' '}
        <a
          href='https://www.linkedin.com/legal/cookie-policy?trk=hp-cookies'
          target='_blank'
          rel='noreferrer'
        >
          https://www.linkedin.com/legal/cookie-policy?trk=hp-cookies
        </a>
      </Paragraph>

      <Title>11.4. GESTÃO DOS COOKIES E CONFIGURAÇÕES DO NAVEGADOR</Title>

      <Paragraph>
        O usuário poderá se opor ao registro de cookies pelo site, bastando que
        desative esta opção no seu próprio navegador ou aparelho.
      </Paragraph>
      <Paragraph>
        A desativação dos cookies, no entanto, pode afetar a disponibilidade de
        algumas ferramentas e funcionalidades do site, comprometendo seu correto
        e esperado funcionamento. Outra consequência possível é remoção das
        preferências do usuário que eventualmente tiverem sido salvas,
        prejudicando sua experiência.
      </Paragraph>
      <Paragraph>
        A seguir, são disponibilizados alguns links para as páginas de ajuda e
        suporte dos navegadores mais utilizados, que poderão ser acessadas pelo
        usuário interessado em obter mais informações sobre a gestão de cookies
        em seu navegador:
      </Paragraph>

      <Paragraph>
        Internet Explorer:{' '}
        <a
          href='https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-deletemanage-
        cookies'
          target='_blank'
          rel='noreferrer'
        >
          https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-deletemanage-
          cookies
        </a>
      </Paragraph>
      <Paragraph>
        Safari:{' '}
        <a
          href='
          https://support.apple.com/pt-br/guide/safari/sfri11471/'
          target='_blank'
          rel='noreferrer'
        >
          https://support.apple.com/pt-br/guide/safari/sfri11471/
        </a>
      </Paragraph>
      <Paragraph>
        Google Chrome:{' '}
        <a
          href='
          https://support.google.com/chrome/answer/95647?hl=pt-BR&hlrm=pt'
          target='_blank'
          rel='noreferrer'
        >
          https://support.google.com/chrome/answer/95647?hl=pt-BR&hlrm=pt
        </a>
      </Paragraph>
      <Paragraph>
        Mozilla Firefox:{' '}
        <a
          href='https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam'
          target='_blank'
          rel='noreferrer'
        >
          https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam
        </a>
      </Paragraph>
      <Paragraph>
        Opera:{' '}
        <a
          href='https://www.opera.com/help/tutorials/security/privacy/'
          target='_blank'
          rel='noreferrer'
        >
          https://www.opera.com/help/tutorials/security/privacy/
        </a>
      </Paragraph>

      <Title>12. ATUALIZAÇÃO DO AVISO DE PRIVACIDADE</Title>

      <Paragraph>
        Considerando que estamos sempre buscando melhorar os nossos serviços e a
        forma de como operamos, o presente Aviso de Privacidade poderá ser
        atualizado a qualquer tempo pelo {productName} para refletir as
        melhorias realizadas. Desta forma, recomendamos a visita periódica desta
        página para que você tenha conhecimento sobre as modificações efetivadas
        e acompanhar o histórico de atualizações constante ao final deste
        documento.
      </Paragraph>
    </Box>
  );
};
