import { Box, Button, type ButtonProps, DialogActions } from '@mui/material';
import { ModalContext } from '../../context/Modal.context';

type ActionSizes = ButtonProps['size'];

export interface IFooterProps {
  hideCancel?: boolean;
  hideDivider?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  cancelText?: string;
  confirmText?: string;
  confirmDisabled?: boolean;
  fullWidth?: boolean;
  actionsSize?: ActionSizes;
  endIcon?: React.ReactNode;
}

export const Footer: React.FC<IFooterProps> = ({
  onConfirm,
  onCancel,
  hideCancel,
  hideDivider,
  confirmDisabled,
  cancelText = 'Fechar',
  confirmText = 'Confirmar',
  actionsSize = 'medium',
  fullWidth,
  endIcon,
  ...props
}) => {
  const { onClose, loading, variant } = ModalContext.useContainer();
  const isDialog = variant === 'dialog';

  return (
    <DialogActions
      sx={{
        gap: 2,
        alignItems: 'stretch',
        flexDirection: 'column',
        ...(!isDialog &&
          !hideDivider && {
            '.MuiDialogContent-root + &': {
              borderTop: 1,
              borderColor: 'divider',
            },
          }),
      }}
      {...props}
    >
      <Box display='flex' gap={1.5} justifyContent='flex-end'>
        {!hideCancel && (
          <Button
            variant='text'
            onClick={onCancel || onClose}
            fullWidth={fullWidth}
            size={actionsSize}
            disableRipple
            disabled={loading}
            data-cy='modal-cancel-button'
            sx={{
              color: 'grey.500',
              border: 1,
              borderColor: 'divider',
              px: 2,
            }}
          >
            {cancelText}
          </Button>
        )}

        {onConfirm && (
          <Button
            variant='contained'
            onClick={onConfirm}
            fullWidth={fullWidth}
            size={actionsSize}
            disabled={confirmDisabled || loading}
            endIcon={endIcon}
            data-cy='modal-confirm-button'
          >
            {loading ? 'Aguarde...' : confirmText}
          </Button>
        )}
      </Box>
    </DialogActions>
  );
};
