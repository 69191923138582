import IconButton, { type IconButtonProps } from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { Iconify } from '../../../components/Iconify';
import { bgBlur } from '../../../helpers/bgBlur';
import { useResponsive } from '../../../hooks/useResponsive';
import { NAV } from '../constants/config-layout';
import { NavBarContext } from '../contexts/CollapseDrawerContext';

export const NavToggleButton: React.FC<IconButtonProps> = ({
  sx,
  ...other
}) => {
  const theme = useTheme();
  const { isOpen, toggle } = NavBarContext.useContainer();
  const lgUp = useResponsive('up', 'md');

  if (!lgUp) {
    return null;
  }

  return (
    <IconButton
      size='small'
      onClick={toggle}
      sx={{
        p: 0.5,
        top: 32,
        position: 'fixed',
        left: NAV.W_VERTICAL - 12,
        zIndex: theme.zIndex.appBar + 1,
        border: `dashed 1px ${theme.palette.divider}`,
        ...bgBlur({ opacity: 0.48, color: theme.palette.background.default }),
        '&:hover': {
          bgcolor: 'background.default',
        },
        ...sx,
      }}
      {...other}
    >
      <Iconify
        width={16}
        icon={isOpen ? 'eva:arrow-ios-back-fill' : 'eva:arrow-ios-forward-fill'}
      />
    </IconButton>
  );
};
