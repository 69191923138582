import { Skeleton, type TextFieldProps } from '@mui/material';
import MUITextField from '@mui/material/TextField';

export type ITextFieldProps = TextFieldProps & {
  loading?: boolean;
};

export const TextField: React.FC<ITextFieldProps> = ({
  loading,
  sx,
  ...props
}) => {
  if (loading) {
    return (
      <Skeleton
        variant='rectangular'
        width='100%'
        sx={{ ...sx, borderRadius: 1 }}
      >
        <MUITextField />
      </Skeleton>
    );
  }
  return <MUITextField variant='outlined' fullWidth sx={sx} {...props} />;
};
