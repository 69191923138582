import { useResponsive } from '@invest-ai/animatrix/src/hooks/useResponsive';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Image from 'mui-image';
import { Outlet } from 'react-router-dom';
import { useAppContext } from '../../..';

interface ILoginLayoutV2Props {
  section: React.ReactNode;
}

export const LoginLayoutV2: React.FC<ILoginLayoutV2Props> = ({ section }) => {
  const showSection = useResponsive('up', 'md');
  const {
    product: { logo },
  } = useAppContext();
  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 520,
        px: { xs: 2, md: 8 },
        pb: 5,
      }}
    >
      <Box
        sx={{
          alignSelf: 'left',
          mt: { xs: 5, md: 8 },
          mb: { xs: 5, md: 8 },
        }}
      >
        <Image
          width={logo.dimensions.width}
          height={logo.dimensions.height}
          src={logo.src}
          duration={0}
        />
      </Box>
      <Card
        sx={{
          py: { xs: 5, md: 0 },
          px: { xs: 3, md: 0 },
          boxShadow: { md: 'none' },
          overflow: { md: 'unset' },
          bgcolor: { md: 'background.default' },
          flexGrow: 1,
        }}
      >
        <Outlet />
      </Card>
    </Stack>
  );

  const theme = useTheme();
  const backgroundColor = theme.palette.background.neutral;

  const renderSection = (
    <Box
      flexGrow={1}
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{ position: 'relative', backgroundColor }}
      p={4}
    >
      {section}
    </Box>
  );

  return (
    <Stack
      component='main'
      direction='row'
      sx={{
        minHeight: '100vh',
        position: 'relative',
        '&:before': {
          width: 1,
          height: 1,
          zIndex: -1,
          content: "''",
          position: 'absolute',
          backgroundSize: 'cover',
          opacity: { xs: 0.24, md: 0 },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage: 'url(/assets/background/overlay_4.jpg)',
        },
      }}
    >
      {renderContent}

      {showSection && renderSection}
    </Stack>
  );
};
