import { SignInMethod, type SignInMutation } from '../../__generated__/types';
import { useSignIn } from './useSignIn';

export const useEmailSignIn = () => {
  const { signIn, data, loading, error } = useSignIn();

  const emailSignIn = (
    email: string,
    password: string,
  ): Promise<SignInMutation['signIn']> => {
    return signIn({
      method: SignInMethod.EMAIL,
      email,
      password,
    }).then((response) => {
      if (!response.data) {
        throw new Error();
      }
      return response.data.signIn;
    });
  };

  return {
    emailSignIn,
    data,
    loading,
    error,
  };
};
