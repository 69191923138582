import { createContainer } from 'unstated-next';
import type { IErrorNotifier } from '../app/context/IAppContext';

export const ErrorTracker = createContainer<
  { client?: IErrorNotifier },
  { client: IErrorNotifier }
>((initialState) => {
  return {
    client: initialState?.client,
  };
});
