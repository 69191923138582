import { gql, useMutation } from '@apollo/client';

export const DELETE_ACCOUNT_MUTATION = gql`
  mutation DeleteUserAccount {
    deleteUserAccount {
      id
    }
  }
`;

export const useDeleteUserAccount = () => {
  const [_deleteAccount, { data, loading }] = useMutation(
    DELETE_ACCOUNT_MUTATION,
  );

  const deleteUserAccount = () => _deleteAccount();

  return {
    deleteUserAccount,
    data,
    loading,
  };
};
