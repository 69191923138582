export const Color = {
  BLACK: '#000000',
  MENU_BACKGROUND: '#212B36',
  DEFAULT_BACKGROUND: '#161C24',
  LIGHT_BACKGROUND: '#333C48',
  PRIMARY: '#2ecc71',
  WARNING: '#f1c40f',
  ERROR: '#e74c3c',
  EXTREME_ERROR: '#c0392b',
  SECONDARY_TEXT: 'rgba(255, 255, 255, 0.7)',
  MENU_ITEM_HOVER: '#383F49',
  MENU_SELECTED_BACKGROUND: '#16362D',
  MENU_SELECTED_HOVER: '#212730',
  POSITIVE: '#00AB55',
  NEGATIVE: '#FF4842',
  WHITE: '#FFF',
};
