import { Box, type BoxProps } from '@mui/material';
import Image from 'mui-image';
import { type ILogoProps } from './interface';

export const Logo: React.FC<ILogoProps & BoxProps> = ({
  sx,
  className,
  width = 80,
  height = 'auto',
}) => {
  return (
    <Box sx={sx} className={className}>
      <Image
        width={width}
        height={height}
        src='/static/brand/logo.png'
        duration={0}
      />
    </Box>
  );
};
