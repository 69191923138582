import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

export const USER_QUERY = gql`
  query User {
    creditsBalance
    me {
      user {
        __typename
        id
        email
        email
        name
        phone
        cpf
        botAuthEmailReminder
        botAuthWhatsAppReminder
        botAuthPushNotificationReminder
      }
    }
  }
`;

// TODO: codegen and type
export const useUser = () => {
  const { loading, error, data, refetch } = useQuery<any>(USER_QUERY);

  return {
    refetch,
    user: data?.me?.user,
    creditsBalance: data?.creditsBalance,
    loading,
    error,
  };
};
