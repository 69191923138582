import { useTokens } from '@invest-ai/animatrix';

export const useJWT = (): null | Record<string, any> => {
  const { getToken } = useTokens();
  const token = getToken();
  if (!token) {
    return null;
  }
  try {
    const [_, payloadString] = token?.split('.') ?? '';
    const payload = JSON.parse(atob(payloadString));

    return payload;
  } catch (err) {
    return null;
  }
};
