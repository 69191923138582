import { useApolloClient } from '@apollo/client';
import { useConfirm } from 'material-ui-confirm';
import { useTracker } from '../../../analytics/hooks/useTracker';
import { useRouter } from '../../../hooks/useRouter';
import { NavBarContext } from '../contexts/CollapseDrawerContext';
import { useCurrentUser } from './useCurrentUser';
import { useScreenContext } from './useScreenContext';

export const useGlobalDeps = () => {
  const navbarContext = NavBarContext.useContainer();
  const confirm = useConfirm();
  const router = useRouter();
  const screenContext = useScreenContext();
  const tracker = useTracker();
  const currentUser = useCurrentUser();
  const apollo = useApolloClient();

  return {
    navbarContext,
    confirm,
    router,
    screenContext,
    tracker,
    currentUser,
    apollo,
  };
};
