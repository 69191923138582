import { useAppContext } from '@invest-ai/animatrix';
import { validateCpf } from '@invest-ai/common';
import { type FormEventHandler } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  SignInMethod,
  type SignUpMutation,
} from '../../../__generated__/types';
import { useSignUp } from '../../hooks/useSignUp';
import { useTokens } from '../../hooks/useTokens';
import { isValidEmail } from '../../logic/isValidEmail';
import { setCookieToken } from '../../logic/setCookieToken';
import { AppEvents } from '../SignIn/AppEvents';
import { handleKnownErrors } from '../SignIn/handleKnownErrors';
import { type ISignupFormFields } from './ISignupFormFields';
import { gtmTrackSignUpConversion } from './gtmTrackSignUpConversion';

export const useSignUpEmailForm = ({
  name,
  email,
  phone,
  cpf,
  password,
  isCouponActive,
}: ISignupFormFields) => {
  const { defaultRouteNames: routes, tracker, product } = useAppContext();
  const { signUp, loading: loadingSignUp, error: errorSignUp } = useSignUp();
  const { setToken } = useTokens();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const couponCode = searchParams.get('r');
  const salesChannel = searchParams.get('sales_channel');
  const utmSource = searchParams.get('utm_source');
  const utmMedium = searchParams.get('utm_medium');
  const utmCampaign = searchParams.get('utm_campaign');

  const emailSignUpTrack = (data: SignUpMutation['signUpEmail']) => {
    const { id, email } = data.account;
    gtmTrackSignUpConversion();
    tracker?.setUser(id, email);
    tracker?.trackEvent(AppEvents.SignUpComplete, {
      method: SignInMethod.EMAIL,
      bu: product.name,
    });
  };

  const handleSignUpEmailResponse = async (
    data: SignUpMutation['signUpEmail'],
  ) => {
    const token = data.tokens.accessToken;
    emailSignUpTrack(data);
    setToken(token);
    setCookieToken(token);
    navigate(routes.main);
  };

  const handleSubmitEmailSignUpForm: FormEventHandler = async (e) => {
    e.preventDefault();
    try {
      const data = await signUp({
        email,
        password,
        name,
        phone,
        cpf,
        prefilledCoupon: isCouponActive ? couponCode : null,
        webAttribution: {
          channel: salesChannel,
          source: utmSource,
          medium: utmMedium,
          campaign: utmCampaign,
        },
      });
      await handleSignUpEmailResponse(data);
    } catch (error) {
      if (error instanceof Error) {
        handleKnownErrors(error);
      }
    }
  };

  const isEmailValid = isValidEmail(email);
  const isCPFValid = validateCpf(cpf);
  const isValid =
    isEmailValid && isCPFValid && !!password && !!name && phone.length >= 14;

  return {
    handleSubmitEmailSignUpForm,
    loadingSignUp,
    errorSignUp,
    isValid,
    isEmailValid,
    isCPFValid,
  };
};
