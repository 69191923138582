import { AppEvents } from '@global/analytics/enum/AppEvents';
import { useTracker } from '@invest-ai/animatrix';
import { useEffect } from 'react';

export const TrackPixelPageView: React.FC = () => {
  const tracker = useTracker();

  useEffect(() => {
    tracker.trackEvent(AppEvents.PlatformPageView);
  }, []);

  return null;
};
