import { Grid, type GridProps, Typography } from '@mui/material';
import Image from 'mui-image';
import React from 'react';
import { useAppContext } from '../../..';
import tcInvestAiLogo from './assets/tc_investai.png';

export const PartnerSection: React.FC<GridProps> = (props) => {
  const { legalInfo } = useAppContext();
  return (
    <Grid
      container
      borderRadius='10px'
      py={2}
      px={3}
      bgcolor='rgba(145, 158, 171, 0.08)'
      {...props}
    >
      <Grid
        container
        item
        xs={6}
        pr={2}
        justifyContent='center'
        alignItems='center'
      >
        <Image
          src={tcInvestAiLogo}
          duration={0}
          width='145px'
          height='30px'
          alt={`Logo da ${legalInfo?.companyName}`}
        />
      </Grid>
      <Grid container item xs={6} display='flex' alignItems='center'>
        <Typography>Parceiro oficial de renda fixa do TC.</Typography>
      </Grid>
    </Grid>
  );
};
