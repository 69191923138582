import { type BoxProps } from '@mui/material';
import Image from 'mui-image';
import { type ILogoProps } from '../Logo/interface';

export const LogoMini: React.FC<ILogoProps & BoxProps> = ({
  width = 40,
  height = 40,
}) => {
  return (
    <Image
      style={{ borderRadius: 8 }}
      width={width}
      height={height}
      src='/static/brand/logo_mini.png'
      duration={0}
    />
  );
};
