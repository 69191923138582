import type { IEventTracker } from './interfaces/IEventTracker';

export class DebuggerTracker implements IEventTracker {
  constructor(
    private readonly tracker: IEventTracker,
    private readonly isEnabled: boolean,
  ) {}

  pageView(pageId: string): void {
    if (this.isEnabled) {
      console.log(`${this.tracker.constructor.name}: pageView ${pageId} `);
    }
    this.tracker.trackEvent(pageId);
  }
  trackEvent(eventName: string, data?: Record<string, any>): void {
    if (this.isEnabled) {
      console.log(`${this.tracker.constructor.name}: trackEvent ${eventName} `);
      if (data) {
        console.log(data);
      }
    }

    this.tracker.trackEvent(eventName, data);
  }

  setUser(id: string, email: string): void {
    if (this.isEnabled) {
      console.log(`${this.tracker.constructor.name}: setUser ${id} ${email}`);
    }
    this.tracker.setUser(id, email);
  }

  setExperiment(experimentName: string): void {
    if (this.isEnabled) {
      console.log(
        `${this.tracker.constructor.name}: setExperiment ${experimentName}`,
      );
    }
    this.tracker.setExperiment(experimentName);
  }
}
