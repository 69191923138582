import { FacebookPixelEventTracker } from '@global/analytics/FacebookPixelEventTracker';
import { AppEvents } from '@global/analytics/enum/AppEvents';
import type { IEventTracker } from '@invest-ai/animatrix';
import {
  type AmplitudeEventTracker,
  BroadcasterEventTracker,
  DebuggerTracker,
  FacebookStandardEvents,
} from '@invest-ai/animatrix';
import { LegacyEvents } from '../global/analytics/enum/LegacyEvents';

const isDebuggerTrackerEnabled = Boolean(
  process.env.VITE_DEBUGGER_TRACKER_ENABLED,
);

export class NeoTracker
  extends BroadcasterEventTracker
  implements IEventTracker
{
  constructor(amplitudeEventTracker: AmplitudeEventTracker) {
    super([
      new DebuggerTracker(amplitudeEventTracker, isDebuggerTrackerEnabled),
      new DebuggerTracker(
        new FacebookPixelEventTracker(
          import.meta.env.VITE_FACEBOOK_PIXEL_ID as string,
          {
            filterEvents: [
              AppEvents.CreateFirstBrokerAccountSuccess,
              AppEvents.BrokerAccountAuthenticated,
              AppEvents.BrokerAccountPinSetupSuccess,
              AppEvents.SignUpComplete,
              AppEvents.PlatformPageView,
            ],
            eventMapper: {
              [AppEvents.SignUpComplete]:
                FacebookStandardEvents.CompleteRegistration,
              [AppEvents.BrokerAccountAuthenticated]:
                LegacyEvents.AccountAuthenticated,
              [AppEvents.BrokerAccountPinSetupSuccess]:
                LegacyEvents.PinSetupSuccess,
              [AppEvents.PlatformPageView]: LegacyEvents.PlatformPageView,
            },
          },
        ),
        isDebuggerTrackerEnabled,
      ),
    ]);
  }
}
