import {
  useAccountRoutes,
  useAppContext,
  useAuthRoutes,
  useCommonRoutes,
} from '@invest-ai/animatrix';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { PrivatePage } from './PrivatePage';

export const AnimatrixRouter: React.FC = () => {
  const { privateRoutes, publicRoutes } = useAppContext();

  const authRoutes = useAuthRoutes();
  const commonRoutes = useCommonRoutes();
  const accountRoutes = useAccountRoutes();

  return useRoutes([
    {
      element: <PrivatePage />,
      children: [...privateRoutes, ...accountRoutes],
    },
    ...(publicRoutes ?? []),
    ...authRoutes,
    ...commonRoutes,
  ]);
};
