function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/';
const LANDING_ROOT = 'https://invest-ai.com.br/';

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: {
    main: path(ROOTS_DASHBOARD, ''),
    purchases: path(ROOTS_DASHBOARD, 'investimentos'),
    fgc: path(ROOTS_DASHBOARD, 'fgc'),
    couponAndMgmRefer: path(ROOTS_DASHBOARD, 'indicar'),
    simulator: path(ROOTS_DASHBOARD, 'simulator'),
    invoices: path(ROOTS_DASHBOARD, 'faturas'),
    settings: path(ROOTS_DASHBOARD, 'configuracoes'),
    login: path(ROOTS_DASHBOARD, 'login'),
    signup: path(ROOTS_DASHBOARD, 'signup'),
    forgotPassword: path(ROOTS_DASHBOARD, 'recuperar-senha'),
    forgotPasswordApp: path(ROOTS_DASHBOARD, 'forgot-password'),
    resetPassword: path(ROOTS_DASHBOARD, 'nova-senha'),
  },
  landingPage: {
    main: path(LANDING_ROOT, ''),
    faq: path(LANDING_ROOT, 'duvidas'),
  },
};
