import { ApolloError } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import { ErrorMessages } from './ErrorMessages';

export const handleKnownErrors = (error: Error) => {
  if (error instanceof ApolloError) {
    const knownErrors = [
      ErrorMessages.UserNotFound,
      ErrorMessages.IncorrectPassword,
      ErrorMessages.EmailAlreadyExists,
    ];

    if (!knownErrors.includes(error.message as ErrorMessages)) {
      Bugsnag.notify(error);
    }
  }
};
