import { IconButton, InputAdornment, TextField } from '@mui/material';
import { type MouseEvent, useState } from 'react';
import { Iconify } from '../../../components/Iconify';
import { type IPasswordInputProps } from './interface';

export const PasswordInput: React.FC<IPasswordInputProps> = ({
  onChange,
  showPinHelp = false,
  error,
  inputMode,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword((s) => !s);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
  };

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      onChange={(e) => onChange(e.target.value)}
      error={!!error}
      helperText={error?.message}
      InputProps={{
        inputMode,
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='mudar visibilidade da senha'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              size='large'
            >
              <Iconify
                icon={showPassword ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
