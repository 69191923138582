import { formatReais } from '@invest-ai/common';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { Iconify } from '../../components/Iconify';
import { useBoolean } from '../../hooks/useBoolean';
type Props = {
  invoices: any[];
};

export default function AccountBillingHistory({ invoices }: Props) {
  const showMore = useBoolean();

  return (
    <Card>
      <CardHeader title='Histórico de faturas' />

      <Stack spacing={1.5} sx={{ px: 3, pt: 3 }}>
        {(showMore.value ? invoices : invoices.slice(0, 8)).map((invoice) => (
          <Stack key={invoice.id} direction='row' alignItems='center'>
            <ListItemText
              primary={invoice.invoiceNumber}
              secondary={format(invoice.createdAt, 'DD/MM/YYYY')}
              primaryTypographyProps={{
                typography: 'body2',
              }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
                color: 'text.disabled',
              }}
            />

            <Typography variant='body2' sx={{ textAlign: 'right', mr: 5 }}>
              {formatReais(invoice.price)}
            </Typography>

            <Link color='inherit' underline='always' variant='body2' href='#'>
              PDF
            </Link>
          </Stack>
        ))}

        <Divider sx={{ borderStyle: 'dashed' }} />
      </Stack>

      <Stack alignItems='flex-start' sx={{ p: 2 }}>
        <Button
          size='small'
          color='inherit'
          startIcon={
            <Iconify
              icon={
                showMore.value
                  ? 'eva:arrow-ios-upward-fill'
                  : 'eva:arrow-ios-downward-fill'
              }
            />
          }
          onClick={showMore.onToggle}
        >
          {showMore.value ? 'Mostrar menos' : 'Mostrar mais'}
        </Button>
      </Stack>
    </Card>
  );
}
