import { EventTrackerProvider } from '@invest-ai/animatrix';
import { type PropsWithChildren, useRef } from 'react';
import type { IEventTracker } from '../../analytics/interfaces/IEventTracker';
import { FakeTracker } from './FakeTracker';

export const RenderTrackerOrFakeTracker: React.FC<
  PropsWithChildren<{ tracker?: IEventTracker }>
> = ({ children, tracker }) => {
  if (!tracker) {
    const fakeTracker = useRef(new FakeTracker());
    return (
      <EventTrackerProvider tracker={fakeTracker.current}>
        {children}
      </EventTrackerProvider>
    );
  }
  return (
    <EventTrackerProvider tracker={tracker}>{children}</EventTrackerProvider>
  );
};
