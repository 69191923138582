import {
  DashboardLayout,
  Logo,
  LogoMini,
  useAppContext,
  useTokens,
} from '@invest-ai/animatrix';
import React from 'react';
import { UnauthenticatedNavigate } from './UnauthenticatedNavigate';

export const PrivatePage: React.FC = () => {
  const { getToken } = useTokens();
  const { logo, logoMini } = useAppContext();
  const token = getToken();

  if (token) {
    return (
      <DashboardLayout
        logo={logo ?? <Logo />}
        logoMini={logoMini ?? <LogoMini />}
      />
    );
  }
  return <UnauthenticatedNavigate />;
};
