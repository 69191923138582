import { useAppContext } from '@invest-ai/animatrix';
import React from 'react';
import { Navigate } from 'react-router-dom';

export const UnauthenticatedNavigate: React.FC = () => {
  const {
    defaultRouteNames: { auth },
  } = useAppContext();

  return <Navigate replace to={auth.signIn} />;
};
